import React, {
  useEffect,
  useRef,
  useMemo,
  useState,
  useCallback,
} from "react";
import * as echarts from 'echarts';
import { bigNumberTransform } from '@/utils/utils';

const initXAxisData = () => [];
const initOptions = () => ({
  title: {
    text: ''
  },
  tooltip: {
    trigger: 'axis'
  },
  legend: {
    data: ['企业数量', '岗位数量'],
    width: "12px",
    right: 0,
    textStyle: {
      color: "#fff",
    },
    itemWidth: 12,
    itemHeight: 12
  },
  grid: {
    top: 60,
    left: 40,
    right: 20,
    bottom: 30
  },
  yAxis: {
    data: ['2017', '2018', '2019', '2020', '2021'],
    axisLine: {
      lineStyle: {
        color: "#2193F8",
      }
    }
  },
  xAxis: {
    type: 'value',
    axisLine: {
      lineStyle: {
        color: "#2193F8",
      }
    },
    splitLine: {
      show: false,
    }
  },
  series: [
    {
      name: '企业数量',
      type: 'bar',
      barWidth: '4px',
      data: [800, 1300, 2000, 2300, 1800, 1100, 500],
      color: "#F9B03D",
      itemStyle: {
        normal: {
          barBorderRadius: [0, 4, 4, 0]
        }
      },
    },
    {
      name: '岗位数量',
      type: 'bar',
      barWidth: '4px',
      data: [1000, 1800, 2200, 3100, 2200, 1500, 1000],
      color: "#0C6DD7",
      itemStyle: {
        normal: {
          barBorderRadius: [0, 4, 4, 0]
        }
      },
    },
  ]
});

export default ({ echartsInfo, heights = 220 }) => {
  let isheights = (Number(document.body.clientHeight) >= 1080) ? true : false
  let container = useRef(null);
  let echart = useRef(null);
  let colors = useMemo(() => ["#0C52F2", "#0FBB48", "#EC6C49", "#04D2F7", "#E5C222", "#28BBAC", "#ED7E7A"], []);
  let [xAxisData, setXAxisData] = useState(initXAxisData);
  let [options, setOptions] = useState(initOptions);
  let [echartsheights, setEchartsheights] = useState(heights);

  const handleReset = useCallback(() => {
    setOptions(initOptions());
    setXAxisData(initXAxisData());
  }, []);

  useEffect(() => {
    setEchartsheights(isheights ? heights * 0.52 : heights * 0.45)
    if (echartsInfo) {
      // console.log(echartsInfo)
      let jobsCount = [], yearStr = [], enterprisesCount = [], heights = document.body.clientHeight > 700, widths = document.body.clientWidth > 1366
      echartsInfo.map(item => {
        jobsCount.push(item.jobsCount)
        yearStr.push(item.yearStr)
        enterprisesCount.push(item.enterprisesCount)
      })
      setOptions((options) => {
        return {
          title: {
            text: ''
          },
          tooltip: {
            trigger: 'item',
          },
          legend: {
            show: false,
            data: ['企业数量', '岗位数量'],
            width: "12px",
            right: 0,
            textStyle: {
              color: "#fff",
            },
            itemWidth: 12,
            itemHeight: 12,
          },
          grid: {
            top: document.body.clientWidth > 1526 ? 40 : 50,
            left: 40,
            right: 20,
            bottom: 30
          },
          yAxis: {
            data: yearStr,
            axisLine: {
              lineStyle: {
                color: "#FFFFFF",
                width: .3
              }
            },
            nameTextStyle: {// 名称样式
              fontSize: 12,
              color: '#2193F8',
              padding: [0, 30, -15, 0]
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              textStyle: {
                color: '#2193F8',
                fontSize: 12,
              },
            },
          },
          xAxis: {
            nameTextStyle: {// 名称样式
              fontSize: 12,
              color: '#2193F8',
              padding: [28, 0, 0, widths ? 3 : -0]
            },
            type: 'value',
            axisLine: {
              lineStyle: {
                color: "#FFFFFF",
                width: .3
              }
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              textStyle: {
                color: '#2193F8',
                fontSize: 12,
              },
              formatter: (params, index) => {
                // console.log(params, index);
                return bigNumberTransform(params)
              }
            },
            tooltip: {
              trigger: 'tiem',
            },
          },
          series: [
            {
              name: '岗位数量',
              type: 'bar',
              barWidth: heights ? '6px' : '3px',
              data: jobsCount,
              color: "#F9B03D",
              itemStyle: {
                normal: {
                  barBorderRadius: [0, 4, 4, 0]
                }
              },
              tooltip: {
                formatter: '{b}<br />岗位：{c} 家'
              }
            },
            {
              name: '企业数量',
              type: 'bar',
              barWidth: heights ? '6px' : '3px',
              data: enterprisesCount,
              color: "#0C6DD7",
              itemStyle: {
                normal: {
                  barBorderRadius: [0, 4, 4, 0]
                }
              },
              tooltip: {
                formatter: '{b}<br />企业：{c} 个'
              }
            },
          ]
        }
      });
    } else {
      handleReset();
    }
  }, [echartsInfo, heights, handleReset]);

  useEffect(() => {
    if (!echart.current) echart.current = echarts.init(container.current);
    // echart.current.resize({ height: Number(document.body.clientWidth) > 375 ? echartsheights : 150 })
    echart.current.setOption(options);

  }, [options, echartsheights]);

  return (
    <div className="screen-echarts">
      <div
        ref={container}
        className=""
        style={{ height: `100%` }}

      ></div>
    </div>
  );
};


