import React from "react";
import ShipBox from "../ShipBox";
import EChart from "../EChart";
import { bigNumberTransform, isMobile } from "@/utils/utils";

class ShopAll extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      option: null,
      isMobile: isMobile(),
    };
  }
  shouldComponentUpdate(next, newState) {
    if (next.data && !newState.option) {
      this.init();
      return false;
    }
    return true;
  }
  init = () => {
    let heights = document.body.clientHeight > 700,
      widths = document.body.clientWidth > 1366;
    let { data } = this.props;
    if (!data.length) return;
    let list = data;
    let yAxis = list.map((item) => item.storeName);
    let datas = list.map((item) => item.salesVolume);
    let option = {
      tooltip: {
        trigger: "item",
        formatter: (params, index) => {
          // console.log(params);
          return `${params.name}：${bigNumberTransform(params.value)}`;
        },
        position: function (point, params, dom, rect, size) {
          // 固定在顶部
          return [point[0], point[1] + 8];
        },
      },
      xAxis: {
        type: "value",
        axisLine: {
          lineStyle: {
            color: "#F2F2F2",
            width: 0.3,
            fontSize: "0.75rem",
          },
        },
        splitLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        nameTextStyle: {
          // 名称样式
          fontSize: 12,
          color: "#2193F8",
          padding: [28, 0, 0, 0],
        },
        axisLabel: {
          showMaxLabel: true,
          textStyle: {
            color: "#F2F2F2",
            fontSize:
              document.body.clientWidth > 1200
                ? 12
                : document.body.clientWidth > 768
                  ? 10
                  : 8,
          },
          interval: 3,
          formatter: (params, index) => {
            return bigNumberTransform(params);
          },
        },
      },
      yAxis: {
        type: "category",
        data: yAxis,
        inverse: true,
        axisLine: {
          lineStyle: {
            color: "#F2F2F2",
            width: 0.3,
            fontSize: "0.75rem",
          },
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          rotate: document.body.clientWidth > 768 ? 0 : 30,
          textStyle: {
            color: "#F2F2F2",
            fontSize:
              document.body.clientWidth > 1200
                ? 12
                : document.body.clientWidth > 700
                  ? 10
                  : 8,
          },
        },
      },
      grid: {
        left: 0,
        right: 30,
        top: 60,
        bottom: 0,
        containLabel: true,
      },
      series: [
        {
          barWidth: heights ? "6px" : "4px",
          data: datas,
          type: "bar",
          color: "#0DD2D2",
        },
      ],
    };
    if (this.state.isMobile) {
      option.series[0].barWidth = "8px";
      option.xAxis.axisLabel.textStyle.fontSize = 10;
      option.yAxis.axisLabel.textStyle.fontSize = 10;
    }
    this.setState({
      option,
    });
  };

  render() {
    const { option } = this.state;
    return (
      <ShipBox
        title="店铺销售总榜"
        subtitle="销量额（元）"
        className="ShopAll Entrepreneurship_box"
      >
        <div className="ShopAll_echart">
          <EChart
            option={option}
            styleS={{ width: "100%", height: "94%" }}
            m_id="ShopAll"
          />
        </div>
      </ShipBox>
    );
  }
}

export default ShopAll;
