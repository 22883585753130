import React from "react";
import "./index.css";
import "../borderCommon.css";
import { Progress } from "antd";
import AJAX from "@/api/api";
import EChartsData from './eCharts'
import EChartsCurve from './eCharts_curve'
export default class realTime extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      heights: this.props.heights,
      practiceList: [],
      echartsInfo: [],
      practiceId: null,
      loading: false
    };
  }
  componentWillMount() {
    this.getPracticeOption()
  }
  getPracticeOption = () => {
    AJAX.cloudChart.getPracticeOption().then(res => {
      if (res.code == 1) {
        this.getPracticeAnalysis(res.data[0].practiceId)
        this.setState({
          practiceList: res.data,
          practiceId: res.data[0].practiceId
        })
      }
    })
  }
  getPracticeAnalysis = (practiceId) => {
    if (this.state.loading) return
    this.state.loading = true
    AJAX.cloudChart.getPracticeAnalysis({ practiceId }).then(res => {
      setTimeout(() => {
        this.setState({
          loading: false
        })
      }, 3000)
      if (res.code == 1) {
        this.setState({
          loading: false,
          echartsInfo: res.data.practiceProcessAnalysisVoList,
          practiceId:practiceId
        })
      }
    }).catch(() => {
      setTimeout(() => {
        this.setState({
          loading: false
        })
      }, 3000)
    })
  }
  componentWillReceiveProps = (newProps) => {
    this.setState({
      heights: newProps.heights
    })
  }
  render() {
    let { heights, echartsInfo, practiceList, practiceId } = this.state;
    let timeSign = 0
    return (
      <div style={{ height: `${heights}px` }} className="analysisBox borderShare">
        <div className="pos-abs"></div>
        <div className="pos-abs"></div>
        <div className="pos-abs"></div>
        <div className="pos-abs"></div>
        <div className="borderTitle">实操分析</div>
        <div className="screenBox">
          <div className="leftScreen">
            {practiceList ? practiceList.map((item, index) => {
              if(index == 0){
                return <span key={index} onClick={() => { this.getPracticeAnalysis(item.practiceId) }} className={practiceId == item.practiceId ? 'screenPublic text-ellipsis-1 screenSelect' : 'screenPublic text-ellipsis-1'}>{item.practiceName}</span>
              }
            }) : null}
          </div>
        </div>
        <div className="analysisEcharts" style={{display:'flex',width:'100%'}}>
          <EChartsData heights={heights} echartsInfo={echartsInfo} />
          <EChartsCurve heights={heights} echartsInfo={echartsInfo} />
        </div>
      </div>
    );
  }
}
