import React, { useState, useCallback, useEffect, useRef } from "react";
import CompetitionBox from "../CompetitionBox";
import Slider from "react-slick";
import { Tooltip } from "antd";
import { bigNumberTransform, amountConversion } from "@/utils/utils";
import Empty from '../empty'


export default function SchooleList({ data }) {
    const [list, setList] = useState([])
    const [initKey, setinitKey] = useState(1)
    const [settings, setsettings] = useState({
        dots: false,
        infinite: false, //循环
        arrows: false,
        slidesToShow: 10,
        slidesToScroll: 1,
        vertical: true,
        verticalSwiping: true,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 2000,
    })
    const sliderRef = useRef(null)
    useEffect(() => {
        // console.log(data);
        setList(data)
    }, [data])
    const setKey = useCallback(() => {
        setinitKey(initKey + 1)
    }, [initKey])

    const afterChange = useCallback((e) => {
        let len = list.length
        if (e == len - 10) {
            setKey()
            // console.log(sliderRef, len, initKey);
        }
    }, [list, initKey])

    return (
        <CompetitionBox className="SchooleList" title="总销售额排行榜">
            <div className="title SchooleList_row">
                <div className="SchooleList_col p-0">排名</div>
                <div className="SchooleList_col">学校名称</div>
                <div className="SchooleList_col">团队名称</div>
                <div className="SchooleList_col">销售额($)</div>
            </div>
            {
                list.length ? (
                    <span key={initKey} >
                        <Slider {...settings} ref={sliderRef} afterChange={afterChange} >
                            {
                                list.map((item, index) => {
                                    return (
                                        <div key={index} className="SchooleList_item">
                                            <div className="SchooleList_row">
                                                <div className="SchooleList_col">{index + 1}</div>
                                                <div className="SchooleList_col text-ellipsis-1">
                                                    <Tooltip title={item.schoolName}>{item.schoolName}</Tooltip>
                                                </div>
                                                <div className="SchooleList_col text-ellipsis-1">
                                                    <Tooltip title={item.teamName}>{item.teamName}</Tooltip>
                                                </div>
                                                <div className="SchooleList_col">{amountConversion(item.salesVolume)}</div>
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </Slider>
                    </span>
                ) : (
                    <Empty />
                )
            }

        </CompetitionBox>

    )
}