import React, {
  useEffect,
  useRef,
  useMemo,
  useState,
  useCallback,
} from "react";
import "./index.css";
import * as echarts from "echarts";
// import DisableGraph from "./../DisableGraph";

const initXAxisData = () => ["迟到率 0%", "出勤率 0%"];
const initOptions = () => ({
  color: ["#00EAFF", "#0C52F2", "#EC6C49", "#0FBB48"],
  tooltip: {
    trigger: "item",
    formatter: "{b}",
  },
  series: [
    {
      name: "访问来源",
      type: "pie",
      width: 600,
      top: 12,
      left: "center",
      radius: ["30%", "54%"],
      center: ["50%", "30%"],
      minAngle: 5, //最小角度
      startAngle: 180, //起始角
      labelLine: {
        normal: {
          length: 1,
        },
      },
      label: {
        position: "outer",
        fontSize: 11,
      },
      data: [],
    },
  ],
});

//出勤视图   1
export default ({ echartsInfo, heights }) => {
  let isheights = Number(document.body.clientHeight) >= 1080 ? true : false;
  let container = useRef(null);
  let echart = useRef(null);
  let colors = useMemo(() => ["#00EAFF", "#0C52F2", "#EC6C49", "#0FBB48"], []);
  let [xAxisData, setXAxisData] = useState(initXAxisData);
  let [options, setOptions] = useState(initOptions);
  let labelObj = {
    fontSize: 12,
    color: "#fff",
  };

  const handleReset = useCallback(() => {
    setOptions(initOptions());
    setXAxisData(initXAxisData());
  }, []);

  useEffect(() => {
    if (echartsInfo) {
      let { coursePeopleNum, employedPeopleNum, trainPeopleNum, usePeopleNum } =
        echartsInfo;
      setXAxisData([`实训人次`, `课程人次`, `就业人数`, `使用人数`]);
      setOptions((options) => {
        return {
          ...options,

          series: [
            {
              ...options.series[0],
              height: isheights
                ? heights + 1
                : Number(document.body.clientHeight) <= 768
                ? heights + 10
                : heights + 30,
              top: isheights
                ? 45
                : Number(document.body.clientHeight) <= 768
                ? 15
                : 20,
              center: ["50%", "34%"],
              grid: {
                left: "0%",
                right: "0%",
                bottom: "3%",
                containLabel: true,
              },
              data: [
                {
                  value: trainPeopleNum,
                  name: `${trainPeopleNum}人`,
                  label: labelObj,
                },
                {
                  value: coursePeopleNum,
                  name: `${coursePeopleNum}人`,
                  label: labelObj,
                },
                {
                  value: employedPeopleNum,
                  name: `${employedPeopleNum}人`,
                  label: labelObj,
                },
                {
                  value: usePeopleNum,
                  name: `${usePeopleNum}人`,
                  label: labelObj,
                },
              ],
            },
            ...options.series.slice(1),
          ],
        };
      });
    } else {
      handleReset();
    }
  }, [echartsInfo, heights, isheights, handleReset]);

  useEffect(() => {
    if (!echart.current) echart.current = echarts.init(container.current);
    echart.current.resize({ height: 130 });
    echart.current.setOption(options);
  }, [options]);

  return (
    <div className="dynamicECharts">
      <div className="title">
        <span className="colorBlock"></span>
        <span>人数实时动态</span>
      </div>
      <div
        style={{ height: isheights ? `${heights}px` : `${heights}px` }}
        className="blueWhaleEChart"
      >
        <div
          ref={container}
          className="container"
          style={{
            display: echartsInfo ? "block" : "none",
            height: `${heights + 20}px`,
          }}
        ></div>
        <div className="list">
          {colors.map((item, index) => {
            return (
              <p key={index}>
                <span style={{ backgroundColor: colors[index] }}></span>
                {`${xAxisData[index]}`}
              </p>
            );
          })}
        </div>
      </div>
    </div>
  );
};
