import React from "react";
import CountUp from "react-countup";

class CompetitionInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
    };
  }
  shouldComponentUpdate(next, newState) {
    if (next.data && !newState.list.length) {
      this.init();
      return false;
    }
    return true;
  }
  init = () => {
    let { data } = this.props;
    if (!data) return;
    let list = [];
    let obj = {
      todaySalesVolume: data.todaySalesVolume,
      todayIssum: data.todayIssum,
      weekSalesVolume: data.weekSalesVolume,
      weekIssum: data.weekIssum,
      monthSalesVolume: data.monthSalesVolume,
      monthIssum: data.monthIssum,
    };
    Object.keys(obj).map((item) => {
      list.push({
        key: item,
        name: this.setName(item),
        value: String(data[item]),
      });
    });
    this.setState({
      list,
    });
  };
  setName = (key) => {
    let str = "";
    switch (key) {
      case "todayIssum":
        str = "昨日出单量";
        break;
      case "todaySalesVolume":
        str = "昨日销售额($)";
        break;
      case "weekSalesVolume":
        str = "本周销售额($)";
        break;
      case "weekIssum":
        str = "本周出单量";
        break;
      case "monthSalesVolume":
        str = "本月销售额($)";
        break;
      case "monthIssum":
        str = "本月出单量";
        break;
      default:
        break;
    }
    return str;
  };

  render() {
    let { list } = this.state;
    if (!list.length) return <div className="competitionInfo"></div>;

    return (
      <div className="competitionInfo">
        <ul>
          {list.map((item) => {
            return (
              <li key={item.key}>
                <div>
                  {item.value.split("").map((a, index) => {
                    if (Number(a)) {
                      return (
                        <CountUp
                          className="sums"
                          end={Number(a)}
                          key={index}
                        ></CountUp>
                      );
                    } else {
                      return (
                        <span key={index} className="sums">
                          {a}
                        </span>
                      );
                    }
                  })}
                  {/* {item.key.includes("Volume") ? "元" : "单"} */}
                </div>
                <p>{item.name}</p>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}

export default CompetitionInfo;
