import AJAX from "@/api/api";
import { historyState } from "@/utils/memory";
import Cookie from "@/utils/cookie";
import { storage } from "@/utils/dom";
import { buildEnvSelector } from "@/utils/utils";
function logout() {
  const { schoolId } = storage.parse().get("teacherUserInfo") || {};
  localStorage.clear();

  if (Cookie.getCookie('simulated_token')) {
    AJAX.login.exitSimulation().then((res) => {
      if (res.code == 1) {
        toLogin()
      }
    })
  } else {
    toLogin()
  }

}

function toLogin() {
  if (window.location.href.includes('localhost')) {
    window.location.href = "/#/login";
  } else {
    window.location.href = "/login";
  }
}


export default function (e) {
  if (e === 1) {
    return logout();
  }

  return AJAX.login.logOut().then((res) => {
    logout();
  });
}
