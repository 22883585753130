import AJAX from "@/api/api";
import { UPDATE_USER_INFO } from "./../actionTypes/teacherUserInfo";

export const updateTeacherUserInfo = (params) => {
  return {
    type: UPDATE_USER_INFO,
    payload: params,
  };
};

export const queryTeacherUserInfo = (key) => {
  return function (dispatch) {
    return AJAX.login.getUserInfo(key).then((res) => {
      if (res.code === 1) {
        dispatch(updateTeacherUserInfo(res.data));
      }

      return res;
    });
  };
};
