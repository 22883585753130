import React, { useEffect, useRef } from "react";
import List from "./ResponsiveList";
import "./style.css";

const ResponsiveList = ({
  itemConfig = {},
  minRowCount,
  horizontalSpace,
  verticalSpace,
  className = "",
  style,
  children,
}) => {
  const ins = useRef(null);
  const containerEl = useRef(null);
  const { minWidth, maxWidth } = itemConfig;

  useEffect(() => {
    const listIns = new List(containerEl.current, {
      minWidth,
      maxWidth,
      minRowCount,
      horizontalSpace,
      verticalSpace,
    });

    ins.current = listIns;

    return () => listIns.destroyObserve();
  }, [minWidth, maxWidth, minRowCount, horizontalSpace, verticalSpace]);

  useEffect(() => {
    ins.current && ins.current.initChildren();
  }, [children]);

  return (
    <div
      className={`responsive-list ${className}`}
      style={style}
      ref={containerEl}
    >
      {children}
    </div>
  );
};

export const ResponsiveItem = ({ className = "", ...otherProps }) => {
  return <div className={`responsive-item ${className}`} {...otherProps} />;
};

export default ResponsiveList;
