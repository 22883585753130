import React from "react";
import "./index.css";
import AJAX from "@/api/api";
import SalesList from "./modules/SalesList";
import Issue from "./modules/Issue";
import SalesVolume from "./modules/SalesVolume";
import DaySalesData from "./modules/DaySalesData";
import Category from "./modules/Category";
import Map from "./modules/Map";
import College from './modules/College'
import SchoolSwiper from "./modules/SchoolSwiper";
import { isMobile, refreshInterval } from "@/utils/utils";
import getUrlData from "@/utils/getUrlData";

class Competition extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: isMobile(),
      competitionId: getUrlData("id"),
      titleName: getUrlData("name"),
      platformList: [],
      categorySale: [],
      categorySaleTotal: 0,
      schoolList: [],
      countryList: [],
      studyTrends: null,
      useTrends: null,
      statisticsList: [],
      lssue: null,
      storeSale: [],
      salesList: [],
      allCityData: null,
      colors: [
        "#EEB20E",
        "#5B8FF9",
        "#02EEFF",
        "#42E736",
        "#E71EE8",
        "#EE1E1E",
        "#E86448",
        "#44B5D6",
        "#0A8D9D",
        "#3822FC",
        "#3BB176",
        "#B2BC1F",
        "#EB2359",
      ],
      time: new Date().toTimeString().slice(0, 8),
    };
    this.timer = null;
  }
  componentDidMount() {
    this.getAllData()
    refreshInterval(30)
  }
  getAllData() {
    this.getSchoolCheckList();
    this.getCategorySaleList()
    this.getPlatformList()
    this.getCountryList()
    this.getProvinceUseStatisticsList()
    this.getStorelssue()
    this.getStoreSale()
    this.getStoreSalesList()
    this.getCourseStudyTrends()
    this.getPracticeUseTrends()
  }
  getCategorySaleList() {
    AJAX.cloudBi.getCategorySaleList().then((res) => {
      if (res.code == 1) this.setState({ categorySale: res.data, categorySaleTotal: res.extend })
    });
  }
  getSchoolCheckList() {
    AJAX.cloudBi.getSchoolCheckList().then((res) => {
      if (res.code == 1) this.setState({ schoolList: res.data })
    });
  }
  getPlatformList() {
    AJAX.cloudBi.getPlatformList().then((res) => {
      if (res.code == 1) this.setState({ platformList: res.data })
    });
  }
  getCountryList() {
    AJAX.cloudBi.getCountryList().then((res) => {
      if (res.code == 1) this.setState({ countryList: res.data })
    });
  }
  getCourseStudyTrends() {
    AJAX.cloudBi.getCourseStudyTrends().then((res) => {
      if (res.code == 1) this.setState({ studyTrends: res.data })
    });
  }
  getPracticeUseTrends() {
    AJAX.cloudBi.getPracticeUseTrends().then((res) => {
      if (res.code == 1) this.setState({ useTrends: res.data })
    });
  }
  getProvinceUseStatisticsList() {
    AJAX.cloudBi.getProvinceUseStatisticsList().then((res) => {
      if (res.code == 1) this.setState({ allCityData: res })
    });
  }
  getStorelssue() {
    AJAX.cloudBi.getStorelssue().then((res) => {
      if (res.code == 1) this.setState({ lssue: res.data })
    });
  }
  getStoreSale() {
    AJAX.cloudBi.getStoreSale().then((res) => {
      if (res.code == 1) this.setState({ storeSale: res.data })
    });
  }
  getStoreSalesList() {
    AJAX.cloudBi.getStoreSalesList().then((res) => {
      if (res.code == 1) this.setState({ salesList: res.data })
    });
  }

  render() {
    let {
      titleName,
      categorySale,
      schoolList,
      platformList,
      lssue,
      salesList,
      storeSale,
      studyTrends,
      allCityData,
      colors,
      countryList,
      useTrends,
      categorySaleTotal
    } = this.state;
    return (
      <div className="bi_chart">
        <h1>
          <img className="logo" src={require('@/assets/svg/bi_logo.svg')}></img>
          <span>{titleName}</span>
        </h1>
        <SchoolSwiper data={platformList}></SchoolSwiper>
        <div className="bi_chart_body">
          <div className="bi_chart_body_left">
            <College data={schoolList} />
            <SalesVolume data={studyTrends} type="studyTrends" colors={colors} />
            <SalesVolume data={useTrends} type="useTrends" colors={colors} />
          </div>
          <div className="bi_chart_body_center">
            <Map allCityData={allCityData} />
            <DaySalesData list={countryList} data={salesList} colors={colors} />
          </div>
          <div className="bi_chart_body_right">
            <Issue data={lssue} />
            <SalesList data={storeSale} colors={colors} />
            <Category data={categorySale} categorySaleTotal={categorySaleTotal} />
          </div>
        </div>
      </div>
    );
  }
}

export default Competition;
