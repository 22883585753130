import React from "react";
import "./index.css";
import "../borderCommon.css";
import CountUp from "react-countup";
import AJAX from "@/api/api";
export default class dataScreening extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      timeSign: 0,
      dataInfo: {
        activeStudentNum: 0,
        activeTeacherNum: 0,
        deliverResumeNum: 0,
        employedNum: 0,
        positionNum: 0,
        totalClassNum: 0,
        totalGraduateNum: 0,
        totalStudentNum: 0,
        totalTeacherNum: 0,
        trainTeachingNum: 0,
        watchCourseNum: 0,
      },
      heights: this.props.heights,
    };
  }

  componentDidMount() {
    this.getDataScreening();
  }
  componentWillReceiveProps = (newProps) => {
    this.setState({
      heights: newProps.heights,
    });
  };
  getDataScreening = () => {
    AJAX.cloudChart
      .getDataScreening({ timeSign: this.state.timeSign })
      .then((res) => {
        if (res.code == 1) {
          this.setState({
            dataInfo: res.data,
          });
        }
      });
  };
  shareSwitch = (type) => {
    this.setState(
      {
        timeSign: type,
      },
      () => {
        this.getDataScreening();
      }
    );
  };
  render() {
    let { dataInfo, timeSign, heights } = this.state;
    let blockHeight = heights - 102;
    let dataH = blockHeight / 4 - (heights > 300 ? 12 : 6);
    return (
      <div
        style={{ height: `${heights}px` }}
        className="dataScreeningBox borderShare"
      >
        <div className="pos-abs"></div>
        <div className="pos-abs"></div>
        <div className="pos-abs"></div>
        <div className="pos-abs"></div>
        <div className="borderTitle">数据总览</div>
        <div className="screenBox">
          <div className="leftScreen">
            <span
              onClick={() => {
                this.shareSwitch(0);
              }}
              className={
                timeSign == 0 ? "screenPublic screenSelect" : "screenPublic"
              }
            >
              全部
            </span>
            <span
              onClick={() => {
                this.shareSwitch(1);
              }}
              className={
                timeSign == 1 ? "screenPublic screenSelect" : "screenPublic"
              }
            >
              近一周
            </span>
            <span
              onClick={() => {
                this.shareSwitch(2);
              }}
              className={
                timeSign == 2 ? "screenPublic screenSelect" : "screenPublic"
              }
            >
              近一个月
            </span>
          </div>
          {/* <div className="rightScreen">
            <span className="screenPublic">全部年级</span>
            <span className="screenPublic">全部学科</span>
          </div> */}
        </div>
        <div className="dataInfoBox">
          <div style={{ height: `${dataH}px` }} className="info">
            <div>
              <CountUp className="sums" end={dataInfo.totalClassNum}></CountUp>
            </div>
            <div className="textTitle">班级总数</div>
          </div>
          <div style={{ height: `${dataH}px` }} className="info">
            <div>
              <CountUp className="sums" end={dataInfo.watchCourseNum}></CountUp>
            </div>
            <div className="textTitle">观看课程数</div>
          </div>
          <div style={{ height: `${dataH}px` }} className="info">
            <div>
              <CountUp
                className="sums"
                end={dataInfo.activeTeacherNum}
              ></CountUp>
              <span className="slash">/</span>
              <CountUp
                className="sums"
                end={dataInfo.totalTeacherNum}
              ></CountUp>
            </div>
            <div className="textTitle">活跃教师数/新增教师</div>
          </div>
          <div style={{ height: `${dataH}px` }} className="info">
            <div>
              <CountUp
                className="sums"
                end={dataInfo.trainTeachingNum}
              ></CountUp>
            </div>
            <div className="textTitle">实训授课数</div>
          </div>
          <div style={{ height: `${dataH}px` }} className="info">
            <div>
              <CountUp
                className="sums"
                end={dataInfo.activeStudentNum}
              ></CountUp>
              <span className="slash">/</span>
              <CountUp
                className="sums"
                end={dataInfo.totalStudentNum}
              ></CountUp>
            </div>
            <div className="textTitle">活跃学生数/新增学生</div>
          </div>
          <div style={{ height: `${dataH}px` }} className="info">
            <div>
              <CountUp
                className="sums"
                end={dataInfo.deliverResumeNum}
              ></CountUp>
            </div>
            <div className="textTitle">投递简历数</div>
          </div>
          <div style={{ height: `${dataH}px` }} className="info">
            <div>
              <CountUp className="sums" end={dataInfo.employedNum}></CountUp>
              {/* <span className="slash">/</span> */}
              {/* <CountUp className="sums" end={dataInfo.totalGraduateNum} ></CountUp> */}
            </div>
            <div className="textTitle">已就业</div>
          </div>
          <div style={{ height: `${dataH}px` }} className="info">
            <div>
              <CountUp className="sums" end={dataInfo.positionNum}></CountUp>
            </div>
            <div className="textTitle">企业发布任务数</div>
          </div>
        </div>
      </div>
    );
  }
}
