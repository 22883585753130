import React, {
  useEffect,
  useRef,
  useMemo,
  useState,
  useCallback,
} from "react";
import "./index.css";
import * as echarts from 'echarts';
// import DisableGraph from "./../DisableGraph";

const initXAxisData = () => ["迟到率 0%", "出勤率 0%"];
const initOptions = () => ({
  color: ["#ED7E7A", "#28BBAC"],
  tooltip: {
    trigger: "value",
  },
  series: [
    {
      name: "访问来源",
      type: "pie",
      width: 280,
      height: 200,
      left: "center",
      top: -12,
      center: ["50%", "60%"],
      areaStyle: {
        normal: {
          color: new echarts.graphic.LinearGradient(
            0, 0, 0, 1,
            [
              { offset: 0, color: '#000' },
              { offset: 0.5, color: '#fff' },
              { offset: 1, color: '#ccc' }
            ]
          )
        }
      },
      data: [],
    },
  ],
});
export default ({ echartsInfo, heights = 300 }) => {
  let container = useRef(null);
  let echart = useRef(null);
  let isheights = (Number(document.body.clientHeight) >= 1080) ? true : false
  let colors = useMemo(() => ["#ED7E7A", "#28BBAC"], []);
  let [xAxisData, setXAxisData] = useState(initXAxisData);
  let [options, setOptions] = useState(initOptions);
  let [echartsheights, setEchartsheights] = useState(300);
  const handleReset = useCallback(() => {
    setOptions(initOptions());
    setXAxisData(initXAxisData());
  }, []);

  useEffect(() => {
    setEchartsheights(isheights ? heights * 0.75 : heights * 0.7)
    if (echartsInfo) {
      let colors = ["#0C52F2", "#0FBB48", "#EC6C49", "#04D2F7", "#E5C222", "#28BBAC", "#ED7E7A"]
      let colors1 = ["#0C52F230", "#0FBB4830", "#EC6C4930", "#04D2F730", "#E5C22230", "#28BBAC30", "#ED7E7A30"]
      let colors2 = ["#0C52F200", "#0FBB4800", "#EC6C4900", "#04D2F700", "#E5C22200", "#28BBAC00", "#ED7E7A00"]

      let data = [], XAxisDataArr = []
      for (let index in echartsInfo) {
        data.push({
          name: echartsInfo[index].processName,
          data: echartsInfo[index].peoPleList,
          type: 'line',
          areaStyle: {
            normal: {
              color: new echarts.graphic.LinearGradient(
                0, 0, 0, 1,
                [
                  { offset: 0, color: colors[index] },
                  { offset: 0.3, color: colors1[index] },
                  { offset: 1, color: colors2[index] }

                ]
              )
            }
          },
          smooth: true
        })
        XAxisDataArr.push(echartsInfo[index].processName)
      }
      setOptions((options) => {
        return {
          color: colors,
          center: ["50%", isheights ? "60%" : "40%"],
          tooltip: {
            trigger: 'axis'
          },
          grid: {
            top:"15%",
            left: document.body.clientWidth > 375 ? '2.2%' : '2.9%',
            right: '1.5%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: {
            type: 'category',
            name: '（月）',// 横轴名称
            nameTextStyle: {// 名称样式
              fontSize: 14,
              color: '#fff',
              padding: [28, 0, 0, -45]
            },
            data: echartsInfo[0]?.monthList,
            axisLabel: {
              show: true,
              textStyle: {
                color: '#fff'
              }
            },
          },
          yAxis: {
            type: 'value',
            scale: true,
            name: '（人）',// 竖轴名称
            min: 0,
            minInterval: 1,
            nameTextStyle: {// 名称样式
              fontSize: 14,
              color: '#fff',
              padding: [25, 0, 0, -23]
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: '#fff'
              }
            },
          },
          series: data
        };
      });
    } else {
      handleReset();
    }
  }, [echartsInfo,isheights, handleReset]);

  useEffect(() => {
    if (!echart.current) echart.current = echarts.init(container.current);
    echart.current.resize({ height: echartsheights })
    echart.current.setOption(options);
  }, [options]);

  return (
    <div className="analysisCurveECharts">
      <div className='blueWhaleEChart' style={{ height: `${echartsheights}px` }}>
        <div
          ref={container}
          className="container"
          style={{ display: echartsInfo ? "block" : "none" }}
        ></div>
      </div>
    </div>
  );
};
