import React, {
  useEffect,
  useRef,
  useMemo,
  useState,
  useCallback,
} from "react";
import * as echarts from 'echarts';
import { bigNumberTransform } from '@/utils/utils';

const initXAxisData = () => [];
const initOptions = () => ({
  grid: {
    top: 40,
    left: 60,
    right: 50,
    bottom: 30
  },
  xAxis: {
    type: 'category',
    // name: "(日期)",
    boundaryGap: false,
    data: ['10/17', '10/18', '10/19', '10/20', '10/21'],
    scale: true,
    axisLine: {
      lineStyle: {
        color: "#2193F8",
      }
    },
    nameTextStyle: {// 名称样式
      fontSize: 12,
      color: '#2193F8',
      padding: [28, 0, 0, document.body.clientWidth > 1366 ? 3 : -35.5]
    },
  },
  yAxis: {
    // name: "(全额)",
    type: 'value',
    scale: true,
    axisLine: {
      lineStyle: {
        color: "#2193F8",
      }
    },
    splitLine: {
      show: false
    },
    nameTextStyle: {// 名称样式
      fontSize: 12,
      color: '#2193F8',
      padding: [28, 30, 0, document.body.clientWidth > 1366 ? 0 : -35.5]
    },
  },
  series: [
    {
      data: [0, 0, 0, 0, 0],
      type: 'line',
      areaStyle: {},
      color: "#0C6DD7",
    }
  ]
});

export default ({ echartsInfo, heights = 220 }) => {
  let isheights = (Number(document.body.clientHeight) >= 1080) ? true : false
  let container = useRef(null);
  let echart = useRef(null);
  let colors = useMemo(() => ["#0C52F2", "#0FBB48", "#EC6C49", "#04D2F7", "#E5C222", "#28BBAC", "#ED7E7A"], []);
  let [xAxisData, setXAxisData] = useState(initXAxisData);
  let [options, setOptions] = useState(initOptions);
  let [echartsheights, setEchartsheights] = useState(heights);

  const handleReset = useCallback(() => {
    setOptions(initOptions());
    setXAxisData(initXAxisData());
  }, []);

  useEffect(() => {
    setEchartsheights(isheights ? heights * 0.52 : heights * 0.45)
    if (echartsInfo) {
      let time = [], data = []
      echartsInfo.forEach(item => {
        time.push(item.date)
        data.push(item.storeMoney)
      });
      let colors = ["#01F1F8", "rgba(255,255,255,0)", "rgba(255,255,255,0)"]
      setOptions((options) => {
        return {
          tooltip: {
            trigger: 'axis'
          },
          grid: {
            top: 20,
            left: 40,
            right: 20,
            bottom: document.body.clientWidth > 1366 ? 30 : 20
          },
          xAxis: {
            type: 'category',
            // name: "(日期)",
            boundaryGap: false,
            data: time,
            // scale: true,
            axisLine: {
              lineStyle: {
                color: "#FFFFFF",
                width: .3
              }
            },
            splitLine: {
              show: true,
              lineStyle: {
                width: .2
              }
            },
            nameTextStyle: {// 名称样式
              fontSize: 12,
              color: '#2193F8',
              padding: [28, 0, 0, document.body.clientWidth > 1366 ? 3 : -0]
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              textStyle: {
                color: '#2193F8',
                fontSize: document.body.clientWidth > 1366 ? 12 : 10,
              },
            },
          },
          yAxis: {
            // name: "(金额)",
            type: 'value',
            scale: false,
            axisLine: {
              lineStyle: {
                color: "#FFFFFF",
                width: .3
              }
            },
            splitLine: {
              show: true,
              lineStyle: {
                width: .2
              }
            },
            nameTextStyle: {// 名称样式
              fontSize: 12,
              color: '#2193F8',
              padding: [28, 45, 0, 0]
            },
            axisLabel: {
              textStyle: {
                color: '#2193F8',
                fontSize: document.body.clientWidth > 1366 ? 12 : 10,
              },
              formatter: (params, index) => {
                // console.log(params, index);
                return bigNumberTransform(params)
              }
            },
            axisTick: {
              show: false
            },
          },
          series: [
            {
              data: data,
              type: 'line',
              areaStyle: {},
              color: colors[0],
              symbol: "circle", //折点设定为实心点
            symbolSize: 6, //设定实心点的大小
              areaStyle: {
                normal: {
                  color: new echarts.graphic.LinearGradient(
                    0, 0, 0, 3,
                    [
                      { offset: 0, color: colors[0] },
                      { offset: 0.5, color: colors[1] },
                      { offset: 1, color: colors[1] }
                    ]
                  )
                }
              },
            }
          ]
        }
      });
    } else {
      handleReset();
    }
  }, [echartsInfo, heights, handleReset]);

  useEffect(() => {
    if (!echart.current) echart.current = echarts.init(container.current);
    // echart.current.resize({ height: Number(document.body.clientWidth) > 375 ? echartsheights : 150 })
    echart.current.setOption(options);

  }, [options, echartsheights]);

  return (
    <div className="user-echarts">
      <div
        ref={container}
        className=""
        style={{ height: `100%` }}
      ></div>
    </div>
  );
};
