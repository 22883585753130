import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
//其他模块引入
import { Autoplay, Navigation, Pagination, Virtual, A11y } from "swiper";
// 引入Swiper样式
import "swiper/swiper.min.css";

class SwiperConent extends React.Component {
  constructor(props) {
    super(props);
    //全部数据
    this.state = {
      // 初始化数据
      currentIndex: 0,
    };
  }
  changeSwiper(value) {
    this.setState({
      currentIndex: value,
    });
    //点击改变swiper
    this.swiper.slideTo(value);
  }
  onSwiper(swiper) {
    // console.log(swiper);
  }
  slideChange(e) {
    // console.log(e.activeIndex);
    this.setState({
      currentIndex: e.activeIndex,
    });
  }

  render() {
    const { data, spaceBetween } = this.props;
    if (!data.length) return <div></div>;
    return (
      <div className="SchoolSwiper">
        <Swiper
          // install Swiper modules
          modules={[Autoplay, Navigation, Pagination, Virtual, A11y]}
          loop={data.length > 5 ? true : false}
          spaceBetween={spaceBetween ? spaceBetween : 20}
          slidesPerView={"auto"}
          speed={2000}
          autoplay={{
            delay: 1000, //1秒切换一次
          }}
          onSwiper={(swiper) => {
            //重点是将swiper挂载到this上
            this.swiper = swiper;
          }}
          onSlideChange={(e) => {
            this.slideChange(e);
          }}
        >
          {data.map((item) => {
            if (item) {
              return (
                <SwiperSlide key={item.logo}>
                  <img className="SchoolSwiper_logo" src={item.logo}></img>
                </SwiperSlide>
              );
            }
          })}
        </Swiper>
      </div>
    );
  }
}

export default SwiperConent;
