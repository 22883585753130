import React from "react";
import ShipBox from "../ShipBox";

class Group extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      table: [
        {
          name: "热瑞金",
          value: 1234,
        },
        {
          name: "热瑞金",
          value: 1234,
        },
        {
          name: "热瑞金",
          value: 1234,
        },
        {
          name: "热瑞金",
          value: 1234,
        },
        {
          name: "热瑞金",
          value: 1234,
        },
        {
          name: "热瑞金",
          value: 1234,
        },
        {
          name: "热瑞金",
          value: 1234,
        },
      ],
    };
  }

  render() {
    const { data } = this.props;
    return (
      <ShipBox
        title="店铺出单榜"
        className="Entrepreneurship_Group Entrepreneurship_box"
      >
        <div className="Entrepreneurship_Group_list">
          {data.map((item, index) => {
            return (
              <div key={index} className="Entrepreneurship_Group_item">
                <div className="name">
                  <img
                    className="logo"
                    src={
                      item.headPortrait ||
                      require(`@/assets/images/teaching/defaultImg.png`)
                    }
                  ></img>
                  {item.storeName}
                </div>
                <div className="value">
                  {item.issueNum} 单
                  <div className="jz">
                    {index < 3 ? (
                      <img
                        src={require(`@/assets/images/cloudChart/Entrepreneurship_a${
                          index + 1
                        }.png`)}
                      ></img>
                    ) : (
                      index + 1
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </ShipBox>
    );
  }
}

export default Group;
