import React from "react";
import CompetitionBox from "../CompetitionBox";
import {
  bigNumberTransform,
  isMobile,
  toDec,
  entrepreneurshipRadius,
} from "@/utils/utils";
import EChart from "../EChart";

class SalesList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      option: null,
      isMobile: isMobile(),
      updateFrequency: 1000,
    };
  }
  shouldComponentUpdate(next, newState) {
    if (next.data.length && !newState.option) {
      this.runOption();
      return false;
    }
    return true;
  }
  init = ({ yAxis, times, data, text }) => {
    let heights = document.body.clientHeight > 700,
      widths = document.body.clientWidth > 1366;
    let option = {
      tooltip: {
        trigger: "item",
        borderWidth: 0,
        formatter: (params, index) => {
          // console.log(params);
          return `${params.name}：${toDec(params.value.toFixed(2))}元`;
        },
        position: function (point, params, dom, rect, size) {
          // 固定在顶部
          return [point[0], point[1] + 8];
        },
      },
      xAxis: {
        // max: "dataMax",
        name: "(元)",
        nameTextStyle: {
          // 名称样式
          fontSize: 10,
          color: "#fff",
          padding: [-6, 0, 0, -10],
        },
        axisLine: {
          lineStyle: {
            color: "#E6E6E6",
            width: 0.3,
            fontSize: "0.75rem",
          },
        },
        splitLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: "#E6E6E6",
            width: 0.3,
            fontSize: "0.75rem",
          },
        },
        axisLabel: {
          textStyle: {
            color: "#E6E6E6",
            fontSize: entrepreneurshipRadius(),
          },
          formatter: (params, index) => {
            return params > 999 ? bigNumberTransform(params) : parseInt(params);
          },
        },
      },
      yAxis: {
        type: "category",
        data: yAxis.map((item) => item.schoolName),
        inverse: true,
        max: 5,
        animationDuration: 300,
        animationDurationUpdate: 300,
        silent: true,
        axisLine: {
          lineStyle: {
            color: "#E6E6E6",
            width: 0.3,
            fontSize: "0.75rem",
          },
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          rotate: document.body.clientWidth > 768 ? 0 : 30,
          textStyle: {
            color: "#E6E6E6",
            fontSize: entrepreneurshipRadius(),
          },
          formatter: function (value, index) {
            let txt = value.length > 7 ? `${value.substring(0, 8)}...` : value;
            return txt;
          },
        },
      },
      grid: {
        left: 0,
        right: 25,
        top: 60,
        bottom: 0,
        containLabel: true,
      },
      // graphic: {
      //   elements: [
      //     {
      //       type: "text",
      //       right: 'center',
      //       top: 'middle',
      //       style: {
      //         text: text,
      //         font: "bolder 80px monospace",
      //         fill: "rgba(100, 100, 100, 0.25)",
      //       },
      //       z: 100,
      //     },
      //   ],
      // },
      animationDuration: 0,
      animationDurationUpdate: this.state.updateFrequency,
      animationEasing: "linear",
      animationEasingUpdate: "linear",
      series: [
        {
          barWidth: heights ? "12px" : "4px",
          data: data,
          type: "bar",
          realtimeSort: true,
          seriesLayoutBy: "column",
          itemStyle: {
            color: function (param) {
              let ele = yAxis.find((item) => item.schoolName == param.name);
              return ele.color || "#008BB4";
            },
          },
          labelLayout: {
            moveOverlap: "shiftY",
          },
          emphasis: {
            focus: "series",
          },
          label: {
            show: false,
            position: "inside",
            fontSize: 12,
            formatter: "{@score}",
            // formatter: (params) => {
            //   return params.value > 0 ? params.value : "";
            // },
            fontStyle: "normal",
            color: "#E6E6E6",
          },
        },
      ],
    };
    if (this.state.isMobile) {
      option.series[0].barWidth = "8px";
      option.xAxis.axisLabel.textStyle.fontSize = 10;
      option.yAxis.axisLabel.textStyle.fontSize = 10;
    }
    this.setState({
      option,
    });
  };
  runOption = () => {
    let { data, colors } = this.props;
    let { updateFrequency } = this.state;
    if (!data.length) return;
    let list = data;
    let yAxis = [];
    let times = [];
    let datas = [];
    list.map((item, index) => {
      yAxis.push({
        schoolName: item.schoolName,
        color: colors[index],
      });
      if (index == 0) {
        item.recordList.map((v) => {
          times.push(v.salesTime);
        });
      }
    });
    times.map((item, index) => {
      let arr = [];
      list.map((v, ind) => {
        if (index != 0) {
          // console.log(datas[index-1][ind]);
          arr.push(
            datas[index - 1][ind] + list[ind].recordList[index].salesVolume
          );
        } else {
          arr.push(list[ind].recordList[index].salesVolume);
        }
      });
      datas.push(arr);
    });
    // console.log(datas);
    for (let i = 0; i < datas.length - 1; ++i) {
      setTimeout(() => {
        this.init({ yAxis, times, data: datas[i], text: times[i] });
      }, i * updateFrequency);
    }
  };

  render() {
    let { option } = this.state;
    return (
      <CompetitionBox className="salesList" title="学校销售榜">
        <div className="Competition_echart">
          <EChart
            m_id="salesList"
            styleS={{ width: "100%", height: "94%" }}
            option={option}
          />
        </div>
      </CompetitionBox>
    );
  }
}

export default SalesList;
