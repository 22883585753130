import React, { Component } from "react";
import * as echarts from "echarts";
import world from "@/utils/world.json";
import { nameMap } from "./name";
const dataSource = [
  //散点数据  value:[纬度，经度，数据]
  { name: "美国", value: [-93.310319, 36.908779, 0.015] },
  // { name: "丹麦", value: [9.1577, 56.1388, 0.275] },
  // { name: "瑞士", value: [8.6649, 47.5276, 0.0354] },
];
const geoCoordMap = {
  美国: [-93.310319, 36.908779],
  丹麦: [9.1577, 56.1388, 0.275],
  张家界: [110.491722, 29.112001],
  常德: [111.701486, 29.076683],
  瑞士: [8.6649, 47.5276, 0.0354],
  岳阳: [113.126486, 29.382401],
  长沙: [113.019455, 28.200103],
  株洲: [113.163141, 27.8418],
  湘潭: [112.91977, 27.882141],
  邵阳: [111.467859, 27.21915],
  娄底: [112.012438, 27.745506],
  衡阳: [112.63809, 26.895225],
  永州: [111.577632, 26.460144],
  郴州: [113.039396, 25.81497],
};

class EChart extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidUpdate(nextporps) {
    if (nextporps.allData) {
      // this.initalECharts();
      this.initChart();
    }
  }
  componentDidMount() {
    this.initChart();
  }
  convertData = function (data) {
    var res = [];
    for (var i = 0; i < data.length; i++) {
      var dataItem = data[i];
      var fromCoord = geoCoordMap[dataItem[0].name];
      var toCoord = geoCoordMap[dataItem[1].name];
      if (fromCoord && toCoord) {
        res.push({
          fromName: dataItem[0].name,
          toName: dataItem[1].name,
          coords: [fromCoord, toCoord],
        });
      }
    }
    return res;
  };
  // <!--因为写了多个地图，所以我抽取了一个地图生成方法-->
  initChart = (data = {}) => {
    // console.log(world);
    this.createChart({
      title: "",
      rootDom: document.getElementById("world"),
      data: dataSource,
      geoMap: "world",
      // geoCenter: [100.4, 35.9],
      // zoom: 6,
      visualMapRange: [
        //自定义左下角的可视化范围栏（默认的是平分比例，根据业务需求。因为我们这数据集中在前百分之几，所以需要自定义）
        { min: 0.2, max: 100, color: "#abd3ec", label: "0.2%-100%" },
        { min: 0.07, max: 0.2, color: "#9cc8e5", label: "0.07%-0.2%" },
        { min: 0.04, max: 0.07, color: "#78a8cf", label: "0.04%-0.07%" },
        { min: 0.02, max: 0.04, color: "#4f7fad", label: "0.02%-0.04%" },
        { min: 0, max: 0.02, color: "#2a5782", label: "0%-0.02%" },
      ],
    });
  };

  createChart = ({
    rootDom,
    data,
    title,
    visualMapRange,
    geoMap,
    geoCenter,
    zoom,
  }) => {
    let mapChart = echarts.init(rootDom);
    // echarts.registerMap("chinaAndWorld", worldJson); /* 注册world地图 */
    echarts.registerMap("world", world); /* 注册world地图 */
    // echarts.registerMap("china", china); /* 注册china地图 */
    let GZData = [
      [
        {
          name: "长沙",
        },
        {
          name: "常德",
          value: 195,
        },
      ],
      [
        {
          name: "长沙",
        },
        {
          name: "丹麦",
          value: 180,
        },
      ],
      [
        {
          name: "长沙",
        },
        {
          name: "瑞士",
          value: 180,
        },
      ],
      [
        {
          name: "长沙",
        },
        {
          name: "美国",
          value: 80,
        },
      ],
    ];
    mapChart.setOption({
      backgroundColor: "",
      title: {
        text: title,
        left: "left",
      },
      grid: {
        left: -30,
        right: -30,
        top: 20,
        bottom: "10%",
        containLabel: true,
      },
      //地理坐标系组件用于地图的绘制，支持在地理坐标系上绘制散点图，线集。
      //要显示散点图，必须填写这个配置
      geo: {
        show: true, //是否显示地理坐标系组件
        roam: false, //是否允许鼠标滚动放大，缩小
        map: geoMap, //这就是注册的地图文件的那个mapName
        emphasis: {
          //高亮状态下的多边形和标签样式。
          label: {
            //文本
            color: "#E1593D",
            show: true,
          },
          itemStyle: {
            //区域
            areaColor: "#ccc",
          },
        },
        itemStyle: {
          normal: {
            borderWidth: 0.5, //区域边框宽度
            borderColor: "#fff", //区域边框颜色
            areaColor: "#0DD2D2", //区域颜色
            label: { show: false },
            shadowColor: "#0C6DD7",
            // shadowOffsetY: 12,
            // shadowOffsetX: -1,
          },
          emphasis: {
            areaColor: "#457CFC", //区域颜色
          },
        },
        center: geoCenter, //当前视角的中心点，用经纬度表示
        zoom: zoom, //起始缩放比例
        nameMap: nameMap, //世界各国名中英文对应
      },
      tooltip: {
        show: false,
        // formatter: function (params) {
        //   console.log(params);
        //   return params
        //     ? `${params.name}  ${params ? params.value[2] : ""}%`
        //     : "";
        // },
      },
      //是视觉映射组件，用于进行『视觉编码』，也就是将数据映射到视觉元素（视觉通道）。
      // visualMap: {
      //   type: "piecewise", // 定义为连续型 visualMap
      //   min: 0, //最小值
      //   max: 10, //最大值
      //   calculable: false, //是否显示拖拽用的手柄（手柄能拖拽调整选中范围）。
      //   inRange: {
      //     // 从左到右颜色越来越轻
      //     color: visualMapRange.map((item) => item.color), //颜色
      //   },
      //   textStyle: {
      //     color: "#fff",
      //   },
      //   pieces: visualMapRange.map((item) => ({
      //     min: item.min,
      //     max: item.max,
      //     label: item.label,
      //   })),
      //   textStyle: {
      //     color: "#000",
      //   },
      // },
      series: [
        // {
        //   name: "石家庄",
        //   type: "lines",
        //   zlevel: 2,
        //   symbol: ["none", "arrow"],
        //   symbolSize: 10,
        //   effect: {
        //     show: true,
        //     period: 6,
        //     trailLength: 0,
        //     symbol: "arrow",
        //     symbolSize: 5,
        //   },
        //   lineStyle: {
        //     normal: {
        //       color: "#28BBAC",
        //       width: 1,
        //       opacity: 0.6,
        //       curveness: 0.2,
        //     },
        //   },
        //   data: this.convertData(GZData),
        // },
        {
          name: "石家庄",
          type: "effectScatter", //散点地图的type
          coordinateSystem: "geo", //该系列使用的坐标系
          mapType: "world",
          roam: false,
          data: data,
          zlevel: 2,
          rippleEffect: {
            scale: 4,
            brushType: "stroke",
          },
          label: {
            normal: {
              // formatter: '{b}',
              formatter: "",
              position: "right",
              show: true,
            },
            emphasis: {
              show: false,
            },
          },
          // label: {
          //   formatter: (params) => {
          //     const { data = {} } = params;
          //     return `${data.name} \n ${data.value[2]}%`;
          //   },
          //   position: "insideLeft", //bottom
          //   show: true,
          //   color: "#000",
          // },
          itemStyle: {
            normal: {
              width: "20px",
              color: "#FF7700",
              shadowBlur: 20,
              shadowColor: "#FF7700",
            },
            emphasis: {
              show: false,
            },
          },
          // 选中
          select: {
            itemStyle: {
              areaColor: "#7337FF", //区域颜色
              color: null, //区域颜色
            },
          },
          //标记的大小,可以设置数组或者函数返回值的形式，也可以用数组分开表示宽和高，例如 [20, 10] 表示标记宽为20，高为10。
          symbolSize: 20,
          rippleEffect: {
            //涟漪特效相关配置。
            brushType: "stroke", //波纹的绘制方式
          },
          hoverAnimation: true, //鼠标移入放大圆
        },
      ],
    });
  };

  render() {
    return <div id="world" style={{ width: "120%", height: "100%" }}></div>;
  }
}

export default EChart;
