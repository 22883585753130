import React, { Component } from 'react'
import { Route, Redirect } from 'react-router-dom'
import AJAX from "@/api/api";
import redux from '@/redux/store';
import { updateTeacherUserInfo } from '@/redux/actions/teacherUserInfo';
import getUrlData from '@/utils/getUrlData';
import loginOut from "@/utils/loginOut";

const PrivateRoute = ({ component: Component, ...rest }) => {
  let teacherUserInfo = localStorage.getItem('teacherUserInfo');
  // if (!teacherUserInfo) {
  //   if (process.env.NODE_ENV == 'development') {
  //     return <Route {...rest} component={Component} />
  //   } else {
  //     loginOut(1)
  //     return <div></div>
  //   }
  // }
  return <Route {...rest} render={(props) => {
    const { pathname, search } = props.location
    // console.log(process.env);
    //学生端跳转登录
    if (pathname === "/teachingCenter") {
      let key = getUrlData('token') ? getUrlData('token') : '';
      let type = getUrlData('type')
      if (!type) {
        AJAX.login.getUserInfo(key)
          .then(async (res) => {
            redux.dispatch(updateTeacherUserInfo(res.data));
            localStorage.setItem('teacherUserInfo', JSON.stringify(res.data));
            props.history.push('/teachingCenter?type=1');
          })
          .catch(() => {
            loginOut(1)
          })
        return <div></div>;
      }
    }
    // 正常登录
    let names = [
      '/login',
      '/login/getBackPassword'
    ];

    if (teacherUserInfo) {
      if (pathname === '/login') return <Redirect to={{ pathname: '/teachingCenter' }} />
      // return <Component {...props} />
    } else {
      if (!names.includes(pathname)) {
        if (process.env.NODE_ENV == 'development') {
          return <Redirect to={`/login`} />
        } else {
          loginOut(1)
          return <div></div>
        }
      }
    }
    return <Component {...props} />



    // if(pathname !== '/login') {
    //   historyState.get().replace('/login')
    // }


  }} />
}

export default PrivateRoute;