import React, { Component } from "react";
import * as echarts from "echarts";
import world from "@/utils/world.json";
import { nameMap } from "./name";
const dataSource = [
  //散点数据  value:[纬度，经度，数据]
  { name: "美国", value: [-93.310319, 36.908779, 0.015] },
  // { name: "丹麦", value: [9.1577, 56.1388, 0.275] },
  // { name: "瑞士", value: [8.6649, 47.5276, 0.0354] },
];
const geoCoordMap = {
  美国: [-93.310319, 36.908779],
  丹麦: [9.1577, 56.1388, 0.275],
  张家界: [110.491722, 29.112001],
  常德: [111.701486, 29.076683],
  瑞士: [8.6649, 47.5276, 0.0354],
  岳阳: [113.126486, 29.382401],
  长沙: [113.019455, 28.200103],
  株洲: [113.163141, 27.8418],
  湘潭: [112.91977, 27.882141],
  邵阳: [111.467859, 27.21915],
  娄底: [112.012438, 27.745506],
  衡阳: [112.63809, 26.895225],
  永州: [111.577632, 26.460144],
  郴州: [113.039396, 25.81497],
};

class EChart extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidUpdate(nextporps) {
    if (nextporps.allData) {
      // this.initalECharts();
      this.initChart();
    }
  }
  componentDidMount() {
    this.initChart();
  }
  convertData = function (data) {
    var res = [];
    for (var i = 0; i < data.length; i++) {
      var dataItem = data[i];
      var fromCoord = geoCoordMap[dataItem[0].name];
      var toCoord = geoCoordMap[dataItem[1].name];
      if (fromCoord && toCoord) {
        res.push({
          fromName: dataItem[0].name,
          toName: dataItem[1].name,
          coords: [fromCoord, toCoord],
        });
      }
    }
    return res;
  };
  // <!--因为写了多个地图，所以我抽取了一个地图生成方法-->
  initChart = (data = {}) => {
    // console.log(world);
    let h800 = Number(document.body.clientHeight) > 800;
    this.createChart({
      title: "",
      rootDom: document.getElementById("world"),
      data: dataSource,
      geoMap: "world",
      // geoCenter: [0, 0.1],
      zoom: h800 ? 1.1 : 1,
    });
  };

  createChart = ({ rootDom, data, title, geoMap, geoCenter, zoom }) => {
    let mapChart = echarts.init(rootDom);
    echarts.registerMap("world", world); /* 注册world地图 */
    let h800 = Number(document.body.clientHeight) > 800;
    mapChart.setOption({
      backgroundColor: "",
      title: {
        text: title,
        left: "left",
      },
      grid: {
        left: -30,
        right: -20,
        top: 0,
        bottom: h800 ? 20 : 40,
        containLabel: true,
      },
      //地理坐标系组件用于地图的绘制，支持在地理坐标系上绘制散点图，线集。
      //要显示散点图，必须填写这个配置
      geo: {
        map: "world",
        show: true, //是否显示地理坐标系组件
        roam: false, //是否允许鼠标滚动放大，缩小
        map: geoMap, //这就是注册的地图文件的那个mapName
        emphasis: {
          //高亮状态下的多边形和标签样式。
          label: {
            //文本
            color: "#fff",
            show: true,
          },
          itemStyle: {
            //区域
            areaColor: "#ccc",
          },
        },
        itemStyle: {
          normal: {
            borderWidth: 0, //区域边框宽度
            borderColor: "#7337FF", //区域边框颜色
            areaColor: "#7337FF", //区域颜色
            label: { show: false },
            shadowColor: "#720431", // 地图阴影
            shadowOffsetY: 5,
            shadowOffsetX: 3,
          },
          emphasis: {
            areaColor: "#A683F3", //区域颜色
          },
        },
        center: geoCenter, //当前视角的中心点，用经纬度表示
        zoom: zoom, //起始缩放比例
        nameMap: nameMap, //世界各国名中英文对应
      },
      tooltip: {
        show: false,
      },
      series: [
        {
          type: "map",
          // mapType: 'china',
          map: "world",
          // roam: true, //地图可以移动
          zoom: zoom,
          tooltip: {
            show: false, //不显示提示标签
          },
          label: {
            normal: {
              show: false, //显示省份标签
              color: "#fff",
              fontSize: document.body.clientWidth > 1366 ? 12 : 12,
            },
            emphasis: {
              show: true,
              color: "#fff",
            },
          },
          itemStyle: {
            normal: {
              show: false,
              borderWidth: 0.2, //区域边框宽度
              borderColor: "#fff", //区域边框颜色
              label: { show: false },
              areaColor: "#7337FF", //区域颜色
              shadowOffsetY: 0,
              shadowOffsetX: 0,
            },
            emphasis: {
              show: true,
              areaColor: "#A683F3", //区域颜色
            },
          },
          // 选中
          select: {
            itemStyle: {
              areaColor: "#7337FF", //区域颜色
              color: null, //区域颜色
            },
          },
          nameMap: nameMap, //世界各国名中英文对应
          // geoIndex: 0,
          // tooltip: {show: false},
          data: [],
        },
        {
          name: "石家庄",
          type: "effectScatter", //散点地图的type
          coordinateSystem: "geo", //该系列使用的坐标系
          roam: false,
          zoom: zoom,
          data: data,
          zlevel: 2,
          rippleEffect: {
            scale: 4,
            brushType: "stroke",
          },
          label: {
            normal: {
              // formatter: '{b}',
              formatter: "",
              position: "right",
              show: true,
            },
            emphasis: {
              show: false,
            },
          },
          itemStyle: {
            normal: {
              width: "20px",
              color: "#F84474",
              shadowBlur: 20,
              shadowColor: "#720431",
            },
            emphasis: {
              show: false,
            },
          },
          //标记的大小,可以设置数组或者函数返回值的形式，也可以用数组分开表示宽和高，例如 [20, 10] 表示标记宽为20，高为10。
          symbolSize: 20,
          rippleEffect: {
            //涟漪特效相关配置。
            brushType: "stroke", //波纹的绘制方式
          },
          hoverAnimation: true, //鼠标移入放大圆
        },
      ],
    });
  };

  render() {
    return <div id="world" style={{ width: "120%", height: "100%" }}></div>;
  }
}

export default EChart;
