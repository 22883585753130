import React from "react"
import './loginModulePublic.css'
import { Link } from 'react-router-dom'
import { Form, Input, Button } from 'antd';
import AJAX from "@/api/api";
import redux from '@/redux/store'
import { updateTeacherUserInfo } from '@/redux/actions/teacherUserInfo'
import { message } from "antd";
import { encryptBy } from "@/utils/encryption"
import { queryURLParams } from '@/utils/utils';
//登录
export default class SignIn extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            userPhoneFocus: 0,
            passwordFocus: 0,
            // numberCodeFocus: 0,
            // codeImg: null
        }
    }

    formIns = null;

    handleValuesChange = (changedValues, allValues) => {
        const changeKey = Object.keys(changedValues)[0];
        const changeValue = changedValues[changeKey];
        if (changeKey === 'phone') {
            this.formIns.setFieldsValue({ phone: changeValue.trim() })
        }
    }

    onFinish(values) {
        values = {
            ...values,
            // verifyCodeId: this.state.codeImg.verifyCodeId, 
            roleName: 'teacher',
            isRemeber: true
        }
        values.password = encryptBy(values.password);
        const url = window.location.href
        AJAX.login.signIn(values).then(res => {
            redux.dispatch(updateTeacherUserInfo(res.data))
            localStorage.setItem('teacherUserInfo', JSON.stringify(res.data))
            // console.log(queryURLParams());
            if (window.location.href.includes('url')) {
                this.props.history.push(url.split('url=')[1])
            } else {
                this.props.history.push('/teachingCenter')
            }
        })
    };
    userPhoneFocusCu(e) {
        this.setState({
            userPhoneFocus: e
        })
    };
    passwordFocusCu(e) {
        this.setState({
            passwordFocus: e
        })
    };

    render() {
        return (
            <div className="SignInMain">
                <div className="SignInType">
                    <p>教师登录</p>
                </div>
                <div className="loginFrom">
                    <p className="modeTitle">手机</p>
                    <Form ref={(ins) => this.formIns = ins} name="basic" initialValues={{ remember: true }} onFinish={(e) => this.onFinish(e)} size="large" onValuesChange={this.handleValuesChange}>
                        <Form.Item label="" name="phone" rules={[{ required: true, message: '请输入正确的手机号码', pattern: /^1[3456789]\d{9}$/ }]}>
                            <Input onFocus={() => this.userPhoneFocusCu(1)} onBlur={() => this.userPhoneFocusCu(0)} placeholder="请输入手机号码"
                                prefix={(<img src={require('@/assets/images/login/phone_default.png')}></img>)} />
                        </Form.Item>

                        <Form.Item label="" name="password" rules={[{ required: true, message: '密码必须由8-20个字母和数字组成', pattern: /^(\w){8,20}$/ }]}>
                            <Input.Password onFocus={() => this.passwordFocusCu(1)} onBlur={() => this.passwordFocusCu(0)} placeholder="请输入密码"
                                iconRender={visible => (visible ?
                                    <img src={require("@/assets/images/public/eye-open.png")}></img>
                                    :
                                    <img src={require("@/assets/images/public/eye-close.png")}></img>
                                )}
                                prefix={(<img src={require('@/assets/images/login/password_show.png')}></img>)} />
                        </Form.Item>

                        {/* <Form.Item label="" name="verifyCode" rules={[{ required: true, message: '请输入正确的图片验证码', pattern: /^[A-Za-z0-9]{4,6}$/ }]}>
                            <Input onFocus={() => this.numberCodeFocusCu(1)} onBlur={() => this.numberCodeFocusCu(0)} placeholder="请输入图片验证码"
                                prefix={(<img src={require('@/assets/images/login/securityCode_default.png')}></img>)}
                                suffix={(<div className="numberCodeImg">
                                    <img onClick={() => this.getCodeImg()} src={this.state.codeImg && `data:image/png;base64,${this.state.codeImg.base64Str}`}></img>
                                </div>)} />
                        </Form.Item> */}

                        <Form.Item>
                            <Button block type="primary" htmlType="submit">登录</Button>
                        </Form.Item>
                    </Form>
                    <Link className="controlPassword" to="/login/getBackPassword">忘记密码</Link>
                    {/* <div className="otherWaysTitle">
                        <p>其他登录方式</p>
                    </div>
                    <ul className="otherWays">
                        <li><Link >企业登录</Link></li>
                        <li><Link >学生登录</Link></li>
                    </ul> */}
                </div>
            </div>
        )
    }
}