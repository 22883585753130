import React, {
  useEffect,
  useRef,
  useMemo,
  useState,
  useCallback,
} from "react";
import "./index.css";
import * as echarts from 'echarts';
import { set } from "nprogress";
// import DisableGraph from "./../DisableGraph";

const initXAxisData = () => [];
const initOptions = () => ({
  color: ["#F9B03D", "#01F1F8", "#0C6DD7", "#FE5C64", "#3E9DF9"],
  tooltip: {
    trigger: "item",
    formatter: "{b}",
  },
});
let echartsInfo = [
  { "processName": "3-5K", "peopleNum": 12 },
  { "processName": "5-8K", "peopleNum": 28 },
  { "processName": "8-10K", "peopleNum": 32 },
  { "processName": "10-15K", "peopleNum": 18 },
  { "processName": "15K以上", "peopleNum": 10 },
]
export default ({ heights = 200 }) => {
  let isheights = (Number(document.body.clientHeight) >= 1080) ? true : false
  let container = useRef(null);
  let echart = useRef(null);
  let colors = useMemo(() => ["#F9B03D", "#01F1F8", "#0C6DD7", "#FE5C64", "#3E9DF9"], []);
  let [xAxisData, setXAxisData] = useState(initXAxisData);
  let [options, setOptions] = useState(initOptions);
  let [echartsheights, setEchartsheights] = useState(heights);

  const handleReset = useCallback(() => {
    setOptions(initOptions());
    setXAxisData(initXAxisData());
  }, []);

  useEffect(() => {
    // setEchartsheights(isheights ? heights * 0.52 : heights * 0.45)
    if (echartsInfo) {
      let data = [], XAxisDataArr = []
      for (let item of echartsInfo) {
        data.push({ value: item.peopleNum == 0 ? 1 : item.peopleNum, name: `${item.processName}：${item.peopleNum}%` })
        XAxisDataArr.push({ processName: item.processName, peopleNum: item.peopleNum })
      }
      setXAxisData(XAxisDataArr);
      setOptions((options) => {
        return {
          series: [
            {
              name: '面积模式',
              type: 'pie',
              // height: heights * 0.5,
              radius: [15, isheights ? 70 : Number(document.body.clientWidth) > 1366 || Number(document.body.clientHeight) > 700 ? 60 : Number(document.body.clientWidth) < 400 ? 60 : 40],
              center: [Number(document.body.clientWidth) > 1366 ? '50%' : '40%', isheights ? '45%' : '51%'],
              roseType: 'radius',
              label: {
                show: false
              },
              top: isheights ? 50 : -25,
              data: data
            }
          ]
        };
      });
    } else {
      handleReset();
    }
  }, [echartsInfo, heights, handleReset]);

  useEffect(() => {
    if (!echart.current) echart.current = echarts.init(container.current);
    console.log(echartsheights, "echartsheights")
    console.log(Number(document.body.clientWidth),"Number(document.body.clientWidth)")
    if(Number(document.body.clientWidth) > 400) echart.current.resize({ height: Number(document.body.clientWidth) > 1366 ? echartsheights : 100 })
    echart.current.setOption(options);
  }, [options, echartsheights]);

  return (
    <div className="CareerGrowthECharts analysisECharts">
      <div className='blueWhaleEChart blueWhaleEChart1'>
        <div
          ref={container}
          className="container container1"
          style={{ display: echartsInfo ? "block" : "none" }}
          // style={{ height: `${echartsheights}px` }}

        ></div>
        <div className="list">
          {colors.map((item, index) => {
            if (index < xAxisData.length) {
              return (
                <p key={index}>
                  <span
                    style={{ backgroundColor: colors[index] }}
                  ></span>
                  <p className="title-text">
                    <span className="text-ellipsis-1">{`${xAxisData[index].processName}`}</span>
                    <span className="text-ellipsis-1">{`${xAxisData[index].peopleNum}%`}</span>

                  </p>
                </p>
              );
            }
          })}
        </div>
      </div>
    </div>
  );
};
