import { storage } from "@/utils/dom";




/**
 * 工具函数
 */

/**
 * 数据混合函数
 * @param {*} template 传入的模板
 * @param {Object} source 传入的数据源
 * @example:
 * const template = [{ key: 'name', label: '名字' }, {key: 'age', label: '年龄'}]
 * const source = { name: '张三', age: 12 }
 *
 * const data = dataMixin(template, source);
 * data: [ {label: '名字', value: '张三', key: 'name'} , ... ]
 */
export function dataMixin(template, source) {
  return template.map((item) => {
    const { defaultValue, format, key, ...otherParams } = item;
    const value = source[key];

    const currentValue =
      value === null || value === undefined ? defaultValue : value;
    return {
      ...otherParams,
      key,
      value: format ? format(currentValue) : currentValue,
    };
  });
}

const studentSideUrl = (env) => {
  // 判断是在生产环境还是测试环境，
  const envStudentSideMapping = {
    // 测试
    test: "www.513online.top",
    // 生产
    production: "ke.513.com",
  };
  return envStudentSideMapping[env] || envStudentSideMapping.test;
};

/**
 * 获取学生端地址
 */
export function getStudentSideUrl() {
  return studentSideUrl(process.env.REACT_APP_API_ENV);
}

export function debounce(fn, wait) {
  let timer = null;

  return function (...args) {
    const context = this;

    clearTimeout(timer);
    timer = setTimeout(() => {
      fn.apply(context, args);
    }, wait);
  };
}

export function throttle(fn, wait) {
  let lastTime = 0;
  return function (...args) {
    const context = this;
    const curTime = Date.now();
    if (curTime - lastTime < wait) return;
    lastTime = curTime;
    fn.apply(context, args);
  };
}

/**
 * 构建环境选择器（注意区别api环境）
 * @param {Record<'development' | 'production', unknown | () => unknown>} config
 * @returns {unknown}
 */
export function buildEnvSelector(config = {}) {
  const env = process.env.NODE_ENV;
  const v = config[env];

  if (typeof v === "function") {
    return v();
  }
  return v;
}

/**
 * 检测数据类型
 * @param {any} value
 * @returns {String} String | Number | Object | Array
 */
export function typeOf(value) {
  return Object.prototype.toString.call(value).slice(8, -1);
}

/**
 * 单位换算为万，千万
 * @param {any} value
 * @returns {String} String | Number | Object | Array
 */
export function bigNumberTransform(value) {
  // console.log(value);
  const newValue = ["", "", ""];
  let fr = 1000;
  let num = 3;
  let text1 = "";
  let fm = 1;
  while (value / fr >= 1) {
    fr *= 10;
    num += 1;
    // console.log('数字', value / fr, 'num:', num)
  }
  if (num <= 4) {
    // 千
    newValue[0] = toDec(value.toFixed(2));
    // newValue[1] = '千'
  } else if (num <= 8) {
    // 万
    text1 = parseInt(num - 4) / 3 > 1 ? "千万" : "万";
    // tslint:disable-next-line:no-shadowed-variable
    fm = text1 === "万" ? 10000 : 10000000;
    if (value % fm === 0) {
      newValue[0] = parseFloat(parseInt(value / fm).toFixed(2));
    } else {
      newValue[0] = parseFloat(parseFloat(value / fm).toFixed(2));
    }
    newValue[1] = text1;
  } else if (num >= 9) {
    newValue[0] = parseFloat(parseInt(value / 100000000).toFixed(2))
    newValue[1] = '亿'
  }
  if (value < 1000) {
    newValue[0] = value;
    newValue[1] = "";
  }
  return newValue.join("");
}
/**
 * 单位换算为w
 * @param {any} value
 * @returns {String} String | Number | Object | Array
 */

export function amountConversion(value) {
  if (value) {
    let w = value > 10000
    // console.log(value, w);
    if (w) {
      let n = (value / 10000).toFixed(2)
      // console.log(n);
      return parseFloat(n) + 'w'
    } else {
      return value
    }
  }
  return value
}

export function toDec(value) {
  const f = Math.round(value * 100) / 100;
  const s = f.toString();
  return s;
}

/**
 * 获取url参数
 */

export function queryURLParams(key = "?") {
  const url = window.location.href; //获取url中"?"符后的字串
  let theRequest = new Object();
  if (url.indexOf("?") != -1) {
    let str = url.split(key)[1];
    let strs = str.split("&");
    for (let i = 0; i < strs.length; i++) {
      theRequest[strs[i].split("=")[0]] = unescape(strs[i].split("=")[1]);
    }
  }
  return theRequest;
}

export const entrepreneurshipRadius = () => {
  let w = Number(document.body.clientWidth);
  let h = Number(document.body.clientHeight);
  let num = 12;
  if (w >= 1440 && h >= 900) {
    num = 12;
  }
  if (w <= 1440 || h <= 900) {
    num = 10;
  }
  if (h <= 700) {
    num = 8;
  }
  return num;
};

export const isMobile = () => {
  let flag =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  return flag;
};

export function resizeFun() {
  var w = document.body.clientWidth;
  var h = document.body.clientHeight;
  var pw = w / 1920; // 根据页面设计，动态修改宽高
  var ph = h / 1080;
  document.body.style = "transform:" + "scale(" + pw + "," + ph + ")";
  // document.body.css("transform", "scale(" + pw + "," + ph + ")"); // 设置等比例缩放
}

export const getImgSize = (url) => {
  if (!url) return;
  return new Promise((resolve, reject) => {
    let imgObj = new Image();
    imgObj.src = url;
    imgObj.onload = () => {
      resolve({
        w: imgObj.width,
        h: imgObj.height,
      });
    };
  });
};

export const refreshInterval = (time = 30) => {
  let t = 1000 * 60 * time
  var refreshWeb = setInterval(() => {
    window.location.reload();
    // console.log('refreshInterval');
  }, t)
}

export function getJurisdiction(value = 6) {
  let teacherUserInfo = storage.parse().get('teacherUserInfo')
  let jurisdiction = false
  if (teacherUserInfo?.teacherPermission) {
    jurisdiction = Object.keys(teacherUserInfo.teacherPermission).includes(String(value))
    // console.log(jurisdiction, value, Object.keys(teacherUserInfo.teacherPermission));
    // for (let key in teacherUserInfo.teacherPermission) {
    //   if (key == value) {
    //     jurisdiction = true
    //   }
    // }
  }
  return jurisdiction
}
