import React from "react";
import "./index.css";
import "../borderCommon.css";
import { Progress } from "antd";
import AJAX from "@/api/api";

export default class classSituation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      heights: this.props.heights,
      data: {},
      studentList: [],
      teacherList: [],
      studentSwitch: 0,
      teacherSwitch: 0,
      equipmentWdith: 0, //设备宽度
    };
  }

  componentDidMount() {
    this.getClassActivity();
    this.equipmentWdith = document.body.clientWidth
  }
  getClassActivity = () => {
    AJAX.cloudChart.getClassActivity().then(res => {
      if (res.code == 1) {
        this.setState({
          data: res.data,
          studentList: res.data.newestExamineChartsList,
          teacherList: res.data.loginRateChartsList
        })
      }
    })
  }
  componentWillReceiveProps = (newProps) => {
    this.setState({
      heights: newProps.heights
    })
  }
  studentSwitchClick = (type) => {
    if (this.state.data.newestExamineChartsList) {
      this.setState({
        studentSwitch: type,
        studentList: type == 0 ? this.state.data.newestExamineChartsList : this.state.data.watchVideoChartsList
      })
    }
  }
  teacherSwitchClick = (type) => {
    if (this.state.data.loginRateChartsList) {
      this.setState({
        teacherSwitch: type,
        teacherList: type == 0 ? this.state.data.loginRateChartsList : this.state.data.correctWorkChartsList
      })
    }
  }
  render() {
    let { heights, data, studentList, teacherList, studentSwitch, teacherSwitch, equipmentWdith } = this.state
    return (
      <div style={{ height: `${heights}px` }} className="classSituationBox borderShare">
        <div className="pos-abs"></div>
        <div className="pos-abs"></div>
        <div className="pos-abs"></div>
        <div className="pos-abs"></div>
        <div className="borderTitle">班级活跃情况</div>
        {/* <div className="screenBox">
          <div className="leftScreen">
            <span className="screenPublic screenSelect">全部</span>
            <span className="screenPublic">近一周</span>
            <span className="screenPublic">近一个月</span>
          </div>
          <div className="rightScreen">
            查看所有班级》》
          </div>
        </div> */}
        <div className="situationContent">
          <div className="leftSituation leftSituation1">
            <div className="leftScreen">
              <div onClick={() => { this.studentSwitchClick(0) }} className={studentSwitch == 0 ? 'detaultClass leftScreenSelect' : 'detaultClass'}>最新考核排名</div>
              <div onClick={() => { this.studentSwitchClick(1) }} className={studentSwitch == 1 ? 'detaultClass leftScreenSelect' : 'detaultClass'}>观看课程进度排名</div>
            </div>
            <div className="studentRankings" style={{ margin: '25px 0 8% 0' }}>
              <img src={require("@/assets/images/cloudChart/decorativeLeft.png")} />
              <span>学生前五名</span>
              <img src={require("@/assets/images/cloudChart/decorativeRight.png")} />
            </div>
            <div className="df" style={{ height: `${heights * 0.6}px`, width: '90%' }}>
              <div className="ankingsBox">
                {studentList.length > 0 ? studentList.map((item, index) => {
                  return <div key={index} className="ankingsItem">
                    <img width="24" height="24" className="portrait" src={item.headPic} />
                    <div className="studentName">
                      {item.userName.length >= 7 ? <span className=" text-ellipsis-1">{item.userName}</span> : <><span>{item.userName}</span><span className="h-x">-----------------------</span></>}
                    </div>
                    {index < 3 ? <img src={require(`@/assets/images/cloudChart/ranking${index + 1}.png`)} /> : <span className="serialNum">{index + 1}</span>}
                  </div>
                }) : null}
              </div>
              <div className="progressBox">
                <Progress
                  showInfo={null}
                  strokeWidth={12}
                  gapDegree={150}
                  type="dashboard"
                  strokeColor={{
                    '0%': '#00EAFF',
                    '100%': '#0C52F2',
                  }}
                  trailColor="#1f326d"
                  percent={data.classAverageScore || 0}
                  width={130}
                >
                </Progress>
                <div className="average">
                  <div>{data.classAverageScore || 0}</div>
                  <div>班级平均分</div>
                </div>
              </div>
            </div>
          </div>
          <div className="leftSituation rightSituation">
            <div className="leftScreen">
              <div onClick={() => { this.teacherSwitchClick(0) }} className={teacherSwitch == 0 ? 'detaultClass leftScreenSelect' : 'detaultClass'}>
                <div></div>
                <div></div>

                登录频率排名</div>
              <div onClick={() => { this.teacherSwitchClick(1) }} className={teacherSwitch == 1 ? 'detaultClass leftScreenSelect' : 'detaultClass'}>批改作业排名</div>
            </div>
            <div className="studentRankings" style={{ margin: '25px 0 8% 0' }}>
              <img src={require("@/assets/images/cloudChart/decorativeLeft.png")} />
              <span>教师前五名</span>
              <img src={require("@/assets/images/cloudChart/decorativeRight.png")} />
            </div>
            <div className="df" style={{ height: `${heights * 0.6}px` }}>
              <div className="ankingsBox">
                {teacherList.length > 0 ? teacherList.map((item, index) => {
                  return <div key={index} className="ankingsItem">
                    <img width="24" height="24" className="portrait" src={item.headPic} />
                    <div className="studentName">
                      {item.userName.length >= 7 ? <span className=" text-ellipsis-1">{item.userName}</span> : <><span>{item.userName}</span><span className="h-x">-----------------------</span></>}
                    </div>
                    {index < 3 ? <img src={require(`@/assets/images/cloudChart/ranking${index + 1}.png`)} /> : <span className="serialNum">{index + 1}</span>}
                  </div>
                }) : null}
              </div>
              <div className="progressBox">
                <div className="progressItem">
                  <Progress
                    showInfo={null}
                    strokeWidth={12}
                    gapDegree={150}
                    type="dashboard"
                    strokeColor={{
                      '0%': '#00EAFF',
                      '100%': '#0C52F2',
                    }}
                    trailColor="#1f326d"
                    percent={data.courseProgress || 0}
                    width={equipmentWdith > 375 ? 90 : 70}
                  >
                  </Progress>
                  <div className="average">
                    <div>{data.courseProgress || 0}%</div>
                    <div className="fs-12">课程进度</div>
                  </div>
                </div>
                <div className="progressItem">
                  <Progress
                    showInfo={null}
                    strokeWidth={12}
                    gapDegree={150}
                    type="dashboard"
                    strokeColor={{
                      '0%': '#E5C222',
                      '100%': '#0C52F2',
                    }}
                    trailColor="#1f326d"
                    percent={data.trainProgress || 0}
                    width={equipmentWdith > 375 ? 90 : 70}
                  >
                  </Progress>
                  <div className="average">
                    <div>{data.trainProgress || 0}%</div>
                    <div className="fs-12">实训进度</div>
                  </div>
                </div>
                <div className="progressItem">
                  <Progress
                    showInfo={null}
                    strokeWidth={12}
                    gapDegree={150}
                    type="dashboard"
                    strokeColor={{
                      '10%': '#EC6C49',
                      '100%': '#0C52F2',
                    }}
                    trailColor="#1f326d"
                    percent={data.submitWorkRate || 0}
                    width={equipmentWdith > 375 ? 90 : 70}
                  >
                  </Progress>
                  <div className="average">
                    <div>{data.submitWorkRate || 0}%</div>
                    <div className="fs-12">提交作业率</div>
                  </div>
                </div>
                <div className="progressItem">
                  <Progress
                    showInfo={null}
                    strokeWidth={12}
                    gapDegree={150}
                    type="dashboard"
                    strokeColor={{
                      '0%': '#0FBB48',
                      '100%': '#0C52F2',
                    }}
                    trailColor="#1f326d"
                    percent={data.attendanceRate || 0}
                    width={equipmentWdith > 375 ? 90 : 70}
                  >
                  </Progress>
                  <div className="average">
                    <div>{data.attendanceRate}%</div>
                    <div className="fs-12">出勤率</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    );
  }
}
