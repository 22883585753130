import React, { Component } from "react";
import * as echarts from "echarts";
import geoJson from "@/utils/china.json";
import { geoCoordMap, provienceData } from "./geo";

class EChart extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidUpdate(nextporps) {
    if (nextporps.allCityData) {
      this.initalECharts();
    }
  }
  initalECharts() {
    const { allCityData } = this.props;
    const data = [];
    if (allCityData) {
      allCityData.list.map((item) => {
        data.push({
          name: item.province,
        });
      });
    }
    echarts.registerMap("china", geoJson);
    for (let item of provienceData) {
      if (item.area === "南海诸岛") {
        item.itemStyle = {
          normal: {
            borderWidth: 0.5, //区域边框宽度
            borderColor: "#fff", //区域边框颜色
            areaColor: "#0DD2D2", //区域颜色
            shadowColor: "#0C6DD7",
            shadowOffsetY: 0,
            shadowOffsetX: 0,
          },
          emphasis: {
            areaColor: "#0DD2D2",
          },
        };
      } else {
        item.itemStyle = {
          normal: {
            borderWidth: 0.5, //区域边框宽度
            borderColor: "#fff", //区域边框颜色
            areaColor: "#0DD2D2", //区域颜色
            shadowColor: "#0C6DD7",
            shadowOffsetY: 0,
            shadowOffsetX: 0,
          },
          emphasis: {
            areaColor: "#0DD2D2",
          },
        };
      }
    }
    const myChart = echarts.init(document.getElementById("mainMap"));
    myChart.setOption({
      tooltip: {
        show: true, //不显示提示标签
        formatter: "{b}", //提示标签格式
        backgroundColor: "#0DD2D2", //提示标签背景颜色
        textStyle: { color: "#fff" }, //提示标签字体颜色
      },
      grid: {
        left: -30,
        right: 0,
        top: 50,
        bottom: "10%",
        containLabel: true,
      },
      geo: {
        map: "china",
        roam: false,
        zoom: 1.2,
        tooltip: {
          show: false, //不显示提示标签
        },
        label: {
          normal: {
            show: true, //显示省份标签
            textStyle: { color: "#fff" }, //省份标签字体颜色
          },
          emphasis: {
            //对应的鼠标悬浮效果
            areaColor: "#0dd2d2", //区域颜色
            show: false,
            textStyle: { color: "#fff" },
          },
        },
        itemStyle: {
          normal: {
            borderWidth: 0.5, //区域边框宽度
            borderColor: "#0dd2d2", //区域边框颜色
            areaColor: "#0dd2d2", //区域颜色
            label: { show: false },
            shadowColor: "#0dd2d2",
            // shadowOffsetY: 12,
            // shadowOffsetX: -1,
          },
          emphasis: {
            show: false,
            areaColor: "#0dd2d2", //区域颜色
          },
        },
      },
      series: [
        {
          type: "map",
          // mapType: 'china',
          map: "china",
          // roam: true, //地图可以移动
          zoom: 1.2,
          tooltip: {
            show: false, //不显示提示标签
          },
          label: {
            normal: {
              show: true, //显示省份标签
              color: "#fff",
              fontSize: document.body.clientWidth > 1366 ? 12 : 12,
            },
            emphasis: {
              show: true,
              color: "#fff",
            },
          },
          itemStyle: {
            normal: {
              borderWidth: 1, //区域边框宽度
              borderColor: "#0dd2d2", //区域边框颜色
              label: { show: false },
              // shadowColor:'#00D2FF',
              // shadowOffsetY: 10,
              // shadowOffsetX: 10,
            },
            emphasis: {
              show: false,
              areaColor: "#0dd2d2", //区域颜色
            },
          },
          // 选中
          select: {
            itemStyle: {
              areaColor: "#7337FF", //区域颜色
              color: null, //区域颜色
            },
          },
          // geoIndex: 0,
          // tooltip: {show: false},
          data: provienceData,
        },
        {
          name: "Top 5",
          type: "effectScatter",
          coordinateSystem: "geo",
          data: this.convertData(data),
          symbolSize: function (val) {
            return val[2] / 9;
          },
          label: {
            normal: {
              // formatter: '{b}',
              formatter: "",
              position: "right",
              show: true,
            },
            emphasis: {
              show: false,
            },
          },
          rippleEffect: {
            scale: 4,
          },
          itemStyle: {
            normal: {
              width: "20px",
              color: "#FF7700",
              shadowBlur: 10,
              shadowColor: "#FF7700",
            },
            emphasis: {
              show: false,
              areaColor: "#FF7700", //区域颜色
            },
          },
          zlevel: 1,
        },
      ],
    });
  }
  convertData(data) {
    var res = [];
    for (var i = 0; i < data.length; i++) {
      var geoCoord = geoCoordMap[data[i].name];
      if (geoCoord) {
        res.push({
          name: data[i].name,
          value: geoCoord.concat(data[i].value),
        });
      }
    }
    return res;
  }
  render() {
    let h800 = document.body.offsetHeight > 800
    return (
      <div className="ljScreenMap">
        <div id="mainMap" style={{ width: "100%", height: h800 ? "47vh" : "42vh" }}></div>
      </div>
    );
  }
}

export default EChart;
