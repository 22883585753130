import React from "react";
import { Select } from "antd";

class CompetitionBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      title,
      isSelect,
      className,
      value,
      shiponChange,
      options,
      subtitle,
      selectId,
    } = this.props;

    return (
      <div className={["CompetitionBox", className].join(" ")} id={selectId}>
        {title ? <h4>{title}</h4> : null}

        {this.props.children}
      </div>
    );
  }
}

export default CompetitionBox;
