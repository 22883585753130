import React, {
  useEffect,
  useRef,
  useMemo,
  useState,
  useCallback,
} from "react";
import "./index.css";
import * as echarts from "echarts";
// import DisableGraph from "./../DisableGraph";
import { bigNumberTransform } from "@/utils/utils";

const initXAxisData = () => ["迟到率 0%", "出勤率 0%"];
const initOptions = () => ({
  color: ["#ED7E7A", "#28BBAC"],
  tooltip: {
    trigger: "value",
  },
  series: [
    {
      name: "访问来源",
      type: "pie",
      width: 280,
      height: 200,
      left: "center",
      top: -12,
      center: ["50%", "60%"],
      areaStyle: {
        normal: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            { offset: 0, color: "#000" },
            { offset: 0.5, color: "#fff" },
            { offset: 1, color: "#ccc" },
          ]),
        },
      },
      data: [],
    },
  ],
});
export default ({
  list,
  heights = Number(document.body.clientWidth) > 1366 ? 300 : 220,
}) => {
  let container = useRef(null);
  let echart = useRef(null);
  let isheights = Number(document.body.clientHeight) >= 1080 ? true : false;
  let colors = useMemo(() => ["#ED7E7A", "#28BBAC"], []);
  let [xAxisData, setXAxisData] = useState(initXAxisData);
  let [options, setOptions] = useState(initOptions);
  let [echartsheights, setEchartsheights] = useState(300);
  const handleReset = useCallback(() => {
    setOptions(initOptions());
    setXAxisData(initXAxisData());
  }, []);

  useEffect(() => {
    setEchartsheights(isheights ? heights * 0.85 : heights * 0.7);
    if (list) {
      let colors = [
        "#55A6F5",
        "#0FBB48",
        "#EC6C49",
        "#04D2F7",
        "#E5C222",
        "#28BBAC",
        "#ED7E7A",
      ];
      let data = [],
        XAxisDataArr = [];
      for (let index in list) {
        data.push(list[index].number);
        XAxisDataArr.push(list[index].category);
      }
      setOptions((options) => {
        return {
          color: colors,
          // center: ["50%", isheights ? "60%" : "40%"],
          tooltip: {
            trigger: "axis",
          },
          grid: {
            top: 20,
            left: 40,
            right: 20,
            bottom: document.body.clientWidth > 1366 ? 30 : 20,
            // containLabel: true,
          },
          xAxis: {
            type: "category",
            // name: '（品类）',// 横轴名称
            nameTextStyle: {
              // 名称样式
              fontSize: document.body.clientWidth > 1450 ? 12 : 10,
              color: "#2193F8",
              padding: [
                28,
                0,
                0,
                document.body.clientWidth > 1366
                  ? document.body.clientWidth > 1400
                    ? -31.5
                    : -40
                  : -36,
              ],
            },
            min: 0,
            // scale: true,
            axisTick: {
              show: false,
            },
            data: XAxisDataArr,
            axisLabel: {
              interval: 0,
              // interval: document.body.clientWidth > 1440 ? 0 : document.body.clientWidth > 400 ? 1 : 0,
              // rotate: 40,
              margin: 8,
              textStyle: {
                color: "#2193F8",
                fontSize: document.body.clientWidth > 1450 ? 12 : 10,
              },
            },
          },
          yAxis: {
            type: "value",
            scale: true,
            // name: '（数量）',// 竖轴名称
            min: 0,
            minInterval: 1,
            nameTextStyle: {
              // 名称样式
              fontSize: document.body.clientWidth > 1366 ? 12 : 10,
              color: "#2193F8",
              padding: [25, 0, 0, -47],
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "#2193F8",
                fontSize: document.body.clientWidth > 1366 ? 12 : 10,
              },
              formatter: (params, index) => {
                // console.log(params, index);
                return bigNumberTransform(params);
              },
            },
            splitLine: {
              show: true,
              lineStyle: {
                width: 0.2,
              },
            },
          },
          series: {
            name: "",
            data: data,
            type: "line",
            symbol: "circle", //折点设定为实心点
            symbolSize: 6, //设定实心点的大小
            // itemStyle: {
            //   normal: {
            //     color: '#FE5C64', //改变折线点的颜色
            //   }
            // },
            areaStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 3, [
                  { offset: 0, color: "#55A6F5" },
                  { offset: 0.3, color: "#55A6F550" },
                  { offset: 1, color: "#55A6F500" },
                ]),
              },
            },

            smooth: true,
          },
        };
      });
    } else {
      handleReset();
    }
  }, [list, isheights, handleReset]);

  useEffect(() => {
    if (!echart.current) echart.current = echarts.init(container.current);
    // echart.current.resize({ height: echartsheights });
    echart.current.setOption(options);
  }, [options]);

  return (
    <div className="user-echarts">
        <div
          ref={container}
          className="container"
          style={{ width: "100%", height: "100%" }}
        ></div>
    </div>
  );
};
