import React from "react";
import CompetitionBox from "../CompetitionBox";
import {
  bigNumberTransform,
  isMobile,
  entrepreneurshipRadius,
} from "@/utils/utils";
import EChart from "../EChart";
import SchoolSwiper from "../SchoolSwiper";

class DaySalesData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      option: null,
      isMobile: isMobile(),
    };
  }
  shouldComponentUpdate(next, newState) {
    // console.log(next.data);
    if (next.data.length && !newState.option) {
      this.init(next.data);
      return false;
    }
    return true;
  }
  init = (data) => {
    let h700 = document.body.clientHeight > 700,
      w1366 = document.body.clientWidth > 1366,
      h800 = Number(document.body.clientHeight) > 800;
    let colors = ['#E21F27', '#DB1FE8', '#578AF7', '#07D9E8', '#3FDE3E', '#EEB20E']
    // console.log(data);
    if (!data.length) return;
    let list = data;
    let xAxis = list[0].salesRecordList.map((a) => {
      let txt = a.salesTime.split("-")[1] + "/" + a.salesTime.split("-")[2];
      return txt;
    });
    let yAxis = [];
    let seriesList = [];
    data.map((item, index) => {
      seriesList.push({
        name: item.storeName,
        type: "line",
        symbol: "none",
        data: item.salesRecordList.map((a) => {
          return a.salesVolume;
        }),
        color: colors[index],
      });
    });
    // console.log(seriesList, xAxis, 'seriesList');
    let option = {
      animationDuration: 8000,
      title: {
        show: false,
      },
      tooltip: {
        trigger: "axis",
        // formatter: "{b}<br />{a0}: {c0} 元<br />{a1}: {c1}",
        formatter: (params) => {
          let txt = `
          ${params[0].axisValue}<br />
          `;
          params.map((item) => {
            txt += `
            ${item.marker}${item.seriesName}：${item.value}<br />
            `;
          });
          // console.log(params, txt);
          return txt;
        },
      },
      legend: {
        top: 'center',
        right: '2%',
        type: "scroll",
        height: "100%",
        width: 20,
        orient: "vertical",
        icon: "circle",
        itemWidth: 8,
        itemHeight: 8,
        itemGap: 10,
        textStyle: {
          width: "130",
          color: "#F2F2F2",
          overflow: "truncate",
          fontSize: 12,
        },
        formatter: function (name) {
          return name.length > 5 ? name.substr(0, 5) + '...' : name
        },
        pageButtonItemGap: 5,
        pageIconSize: 10,
        pageIconColor: "#F2F2F2",
        pageIconInactiveColor: "#333",
        pageTextStyle: {
          color: "#F2F2F2",
        },
      },
      grid: {
        top: 30,
        left: 50,
        right: 110,
        bottom: 30,
        containLabel: false,
      },
      xAxis: {
        type: "category",
        boundaryGap: false,
        scale: true,
        data: xAxis,
        axisLine: {
          show: true,
          lineStyle: {
            width: 0.4,
            color: "#F2F2F2",
          },
        },
      },
      yAxis: {
        minInterval: 1,
        type: "value",
        scale: true,
        name: "($)",
        nameTextStyle: {
          // 名称样式
          fontSize: 10,
          color: "#F2F2F2",
          padding: [0, 30, h800 ? 0 : h700 ? -5 : -10, 0],
        },
        axisLine: {
          show: true,
          lineStyle: {
            width: 0,
            color: "#F2F2F2",
          },
        },
        splitLine: {
          lineStyle: {
            width: 0.3,
            color: "#F2F2F2",
          },
        },
        axisLabel: {
          interval: 3,
          textStyle: {
            color: "#E6E6E6",
            fontSize: w1366 ? 12 : document.body.clientWidth > 768 ? 10 : 8,
          },
          formatter: (params, index) => {
            return bigNumberTransform(params);
          },
        },
      },
      series: seriesList,
    };
    this.setState({
      option,
    });
  };
  render() {
    let { option } = this.state;
    const { list } = this.props
    return (
      <CompetitionBox
        className="daySalesData CompetitionBox_bj"
        title="创业日销售数据"
      >
        <SchoolSwiper data={list} spaceBetween={10}></SchoolSwiper>
        <div className="Competition_echart">
          <EChart
            option={option}
            styleS={{ width: "100%", height: "94%" }}
            m_id="bi_daySalesData"
          />
        </div>
      </CompetitionBox>
    );
  }
}

export default DaySalesData;
