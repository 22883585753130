import React from "react";
import ShipBox from "../ShipBox";
import EChart from "../EChart";
import { bigNumberTransform, isMobile } from "@/utils/utils";

class Order extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      option: null,
      nav: [],
      valueKey: "",
      isMobile: isMobile(),
    };
  }
  shouldComponentUpdate(next, newState) {
    // console.log(next);
    if (next.data && !newState.option) {
      // this.init();
      return false;
    }
    return true;
  }
  init = () => {
    let { data } = this.props;
    if (!data) return;
    let list = data;
    let xAxis = list.map((item) => item.salesTime);
    let datas = list.map((item) => item.orderIssueNum);
    let w1366 = Number(document.body.clientWidth) > 1366;
    let option = {
      tooltip: {
        trigger: "item",
        formatter: (params, index) => {
          return `${params.name}：${bigNumberTransform(params.value)} 单`;
        },
      },
      yAxis: {
        type: "value",
        name: "（单）",
        minInterval: 1,
        axisLine: {
          show: false,
          lineStyle: {
            color: "#F2F2F2",
            width: 0.3,
            fontSize: "0.75rem",
          },
        },
        splitLine: {
          lineStyle: {
            width: 0.2,
            color: "#fff",
          },
        },
        axisTick: {
          show: false,
        },
        nameTextStyle: {
          // 名称样式
          fontSize: 10,
          color: "#F2F2F2",
          padding: [0, 0, 0, -28],
        },
        axisLabel: {
          textStyle: {
            color: "#F2F2F2",
            fontSize: w1366 ? 12 : document.body.clientWidth > 768 ? 10 : 8,
          },
          formatter: (params, index) => {
            return bigNumberTransform(params);
          },
        },
      },
      xAxis: {
        type: "category",
        data: xAxis,
        axisLine: {
          lineStyle: {
            color: "#F2F2F2",
            width: 0.3,
            fontSize: "0.75rem",
          },
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          rotate: document.body.clientWidth > 768 ? 0 : 30,
          textStyle: {
            color: "#F2F2F2",
            fontSize: w1366 ? 10 : document.body.clientWidth > 768 ? 8 : 6,
          },
        },
      },
      grid: {
        left: 10,
        right: 15,
        top: 75,
        bottom: 0,
        containLabel: true,
      },
      series: [
        {
          barWidth: w1366 ? "16px" : "8px",
          data: datas,
          type: "bar",
          color: "#0DD2D2",
        },
      ],
    };
    if (this.state.isMobile) {
      option.series[0].barWidth = "16px";
      option.xAxis.axisLabel.textStyle.fontSize = 10;
      option.yAxis.axisLabel.textStyle.fontSize = 10;
    }
    this.setState({
      option,
    });
  };
  setName = (key) => {
    let name = "";
    switch (key) {
      case "deliveredNum":
        name = "已妥投";
        break;
      case "inConsolidatedNum":
        name = "待合并";
        break;
      case "inDistributionNum":
        name = "配货中";
        break;
      case "inReviewedNum":
        name = "待审核";
        break;
      case "pendingNum":
        name = "待处理";
        break;
      case "shippedNum":
        name = "已发货";
        break;
      case "totalNum":
        name = "总数";
        break;
      case "unpaidNum":
        name = "未付款";
        break;
      case "voidedNum":
        name = "已作废";
        break;

      default:
        break;
    }
    return name;
  };
  shiponChange = (key) => {
    this.setState({
      valueKey: key,
    });
    this.props.shiponChange(key).then((res) => {
      if (res.code == 1) {
        // this.init();
      }
    });
  };

  render() {
    const { option, valueKey, nav } = this.state;
    const { storeOption } = this.props;
    return (
      <ShipBox
        isSelect={true}
        title="店铺出单数据"
        className="Order Entrepreneurship_box"
        selectId="Order_id"
        shiponChange={this.shiponChange}
        value={valueKey}
        options={storeOption}
      >
        <div className="ShopAll_echart">
          <EChart
            option={option}
            styleS={{ width: "100%", height: "94%" }}
            m_id="Order"
          />
        </div>
      </ShipBox>
    );
  }
}

export default Order;
