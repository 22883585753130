import React, { useEffect } from "react";
import "./index.css";
import AJAX from "@/api/api";
import Map from "./modules/Map/index";
import MapCn from "./modules/Map_cn/index";
import ShopAll from "./modules/ShopAll/index";
import Group from "./modules/Group/index";
import Order from "./modules/Order/index";
import SalesData from "./modules/SalesData/index";
import ShopLog from "./modules/ShopLog/index";
import ShopSKU from "./modules/ShopSKU/index";
import ShopSKUData from "./modules/ShopSKUData/index";
import { Link } from "react-router-dom";
import { isMobile } from "@/utils/utils";
import getUrlData from "@/utils/getUrlData";

class Entrepreneurship extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      navList: [],
      navKey: '',
      allCityData: null,
      shopAllData: null,
      skuData: [],
      saleData: null,
      schoolTotal: null,
      storeIssueData: [],
      storeOption: [],
      statusNum: null,
      storeSaleData: [],
      storeRecord: [],
      allCityData: [],
      saleVlaue: null,
      isMobile: isMobile(),
      isCn: window.location.href.includes('/Entrepreneurship_cn'),
      title: getUrlData('name') || '创业数据大屏',
    };
    this.times = null;
    this.OrderRef = React.createRef();
  }
  componentDidMount() {
    this.getPlatformOption();
  }
  getAllData = () => {
    this.getCategorySaleList();
    this.getCategorySaleSector();
    this.getSchoolTotal();
    this.getStoreIssue();
    this.getStoreSale();

    this.getAllCityData();
    this.getStoreOption();
    this.getStoreOrderIssueList("");
    this.queryStoreRecord("");
    this.getStoreSalesData("");
  };
  getPlatformOption = () => {
    AJAX.entrepreneurship.getPlatformOption().then(res => {
      if (res.code == 1) {
        let list = res.data
        let key = 1
        if (list.length) {
          key = list[0].platformType
        }
        this.setState({
          navList: list,
          navKey: getUrlData('platformType') || key
        }, () => {
          this.getAllData();
        })
      }
    })
  }
  getAllCityData = () => {
    let params = {
      platformType: this.state.navKey,
    };
    AJAX.blueWahle.getStoreOrderProvinceRatioList(params).then(res => {
      // console.log(res)
      if (res.code == 1) {
        this.setState({
          allCityData: res.data
        })
      }
    })
  }
  getCategorySaleList = () => {
    let { navKey } = this.state;
    let params = {
      platformType: navKey,
    };
    AJAX.entrepreneurship.getCategorySaleList(params).then((res) => {
      if (res.code == 1) {
        this.setState({
          skuData: res.data,
        });
      }
    });
  };
  getCategorySaleSector = () => {
    let { navKey } = this.state;
    let params = {
      platformType: navKey,
    };
    AJAX.entrepreneurship.getCategorySaleSector(params).then((res) => {
      if (res.code == 1) {
        this.setState({
          saleData: res.data,
        });
      }
    });
  };
  getSchoolTotal = () => {
    let { navKey } = this.state;
    let params = {
      platformType: navKey,
    };
    AJAX.entrepreneurship.getSchoolTotal(params).then((res) => {
      if (res.code == 1) {
        this.setState({
          schoolTotal: res.data,
        });
      }
    });
  };
  getStoreIssue = () => {
    let { navKey } = this.state;
    let params = {
      platformType: navKey,
    };
    AJAX.entrepreneurship.getStoreIssue(params).then((res) => {
      if (res.code == 1) {
        this.setState({
          storeIssueData: res.data,
        });
      }
    });
  };
  getStoreOption = () => {
    let { navKey } = this.state;
    let params = {
      platformType: navKey,
    };
    AJAX.entrepreneurship.getStoreOption(params).then((res) => {
      if (res.code == 1) {
        let list = [];
        list.push({
          value: "",
          label: "全部店铺",
        });
        res.data.map((item) => {
          list.push({
            value: item.storeId,
            label: item.storeName,
          });
        });

        this.setState({
          storeOption: list,
        });
      }
    });
  };
  getStoreOrderStatusNum = async (id) => {
    let { navKey } = this.state;
    let params = {
      platformType: navKey,
      storeId: id,
    };
    let res = await AJAX.entrepreneurship.getStoreOrderStatusNum(params);
    if (res.code == 1) {
      this.setState({
        statusNum: res.data,
      });
    }
    return res;
  };
  getStoreSale = () => {
    let { navKey } = this.state;
    let params = {
      platformType: navKey,
    };
    AJAX.entrepreneurship.getStoreSale(params).then((res) => {
      if (res.code == 1) {
        this.setState({
          storeSaleData: res.data,
        });
      }
    });
  };
  getStoreSalesData = async (id) => {
    let { navKey } = this.state;
    let params = {
      platformType: navKey,
      storeId: id,
    };
    let res = await AJAX.entrepreneurship.getStoreSalesData(params);
    if (res.code == 1) {
      this.setState({
        saleVlaue: res.data,
      });
    }
    return res;
  };
  queryStoreRecord = (id) => {
    let { navKey } = this.state;
    let params = {
      platformType: navKey,
      storeId: id,
      // recordId: "1",
    };
    AJAX.entrepreneurship.queryStoreRecord(params).then((res) => {
      if (res.code == 1) {
        this.setState({
          storeRecord: res.data,
        });
      }
    });
  };
  getStoreOrderIssueList = async (id) => {
    let { navKey } = this.state;
    let params = {
      platformType: navKey,
      storeId: id,
    };
    let res = await AJAX.entrepreneurship.getStoreOrderIssueList(params);
    if (res.code == 1) {
      this.setState({
        statusNum: res.data,
      }, () => {
        // console.log(this.OrderRef);
        this.OrderRef.current.init()
      });
    }
    return res;
  };

  render() {
    let {
      navList,
      storeOption,
      saleData,
      saleVlaue,
      storeSaleData,
      storeIssueData,
      statusNum,
      storeRecord,
      schoolTotal,
      skuData,
      isMobile,
      navKey,
      isCn,
      allCityData,
      title
    } = this.state;
    let teacherUserInfo = JSON.parse(localStorage.getItem("teacherUserInfo"));
    let logo = teacherUserInfo?.transparentLogo;
    let allData = null
    // console.log(isCn);
    if (isCn && schoolTotal && allCityData) {
      allData = {
        list: allCityData,
        total: schoolTotal ? schoolTotal.totalSalesVolume : 0
      }
    }
    return (
      <div className={`Entrepreneurship ${isMobile ? "isMobile" : ""} ${isCn ? 'Entrepreneurship_cn' : ''}`}>
        <h1>
          {title}
          <span>伍壹叁数据安全中心</span>
        </h1>
        <div className="nav">
          {navList.map(item => {
            return (
              <Link key={item.platformType}
                to={`${item.platformPath}${item.platformPath && item.platformPath.includes('?') ? '&' : '?'}name=${title}` || ''}
                onClick={e => (item.clickMark && navKey != item.platformType) ? "" : e.preventDefault()} target="_blank">
                <div className={["item", navKey == item.platformType ? "action" : ""].join(" ")}>
                  {item.platformName}
                </div>
              </Link>
            );
          })}
          {
            isCn ? null : (
              <div className="logo">
                <img src={require(`@/assets/images/cloudChart/logo.png`)}></img>
                {logo ? <span></span> : null}
                {logo ? <img src={logo}></img> : null}
              </div>
            )
          }
        </div>
        {isMobile ? (
          <div className="Entrepreneurship_body">
            <div className="Entrepreneurship_center">
              {
                isCn ? <MapCn allCityData={allData}></MapCn> : <Map data={schoolTotal}></Map>
              }
              <ShopAll data={storeSaleData} />
              <Group data={storeIssueData} />
              <Order
                data={statusNum}
                storeOption={storeOption}
                shiponChange={this.getStoreOrderIssueList}
                ref={this.OrderRef}
              />
              <SalesData
                data={saleVlaue}
                storeOption={storeOption}
                shiponChange={this.getStoreSalesData}
              />
              <ShopLog
                data={storeRecord}
                storeOption={storeOption}
                shiponChange={this.queryStoreRecord}
              />
              <ShopSKU data={saleData} />
              <ShopSKUData data={skuData} />
            </div>
          </div>
        ) : (
          <div className="Entrepreneurship_body">
            <div className="Entrepreneurship_left">
              <ShopAll data={storeSaleData} />
              <Group data={storeIssueData} />
              <Order
                data={statusNum}
                storeOption={storeOption}
                shiponChange={this.getStoreOrderIssueList}
                ref={this.OrderRef}
              />
            </div>
            <div className="Entrepreneurship_center">
              {
                isCn ? <MapCn allCityData={allData}></MapCn> : <Map data={schoolTotal}></Map>
              }
              <SalesData
                data={saleVlaue}
                storeOption={storeOption}
                shiponChange={this.getStoreSalesData}
              />
            </div>
            <div className="Entrepreneurship_right">
              <ShopLog
                data={storeRecord}
                storeOption={storeOption}
                shiponChange={this.queryStoreRecord}
              />
              <ShopSKU data={saleData} />
              <ShopSKUData data={skuData} />
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Entrepreneurship;
