import { storage } from "@/utils/dom";
import { UPDATE_USER_INFO } from "./../actionTypes/teacherUserInfo";

const userInfo = storage.parse().get("teacherUserInfo");

const teacherUserInfo = (state = userInfo, action) => {
  switch (action.type) {
    case UPDATE_USER_INFO:
      storage.stringify().set("teacherUserInfo", action.payload);
      return action.payload;
    default:
      return state;
  }
};

export default teacherUserInfo;
