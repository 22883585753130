import React from "react";

class ScreenTitle extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        const {title} = this.props
        return (
            <div className="ScreenTitle">
                <div className="ScreenTitle-title">{title}</div>
                <div className="jiao-t"></div>
                <div className="jiao-b"></div>
            </div>
        )
    }
}

export default ScreenTitle