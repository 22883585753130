import React from "react";
import ScreenTitle from '../ScreenTitle'


class ObtainEmployment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <div className="ObtainEmployment ljScreen-item">
                <ScreenTitle title="实时就业统计" />
            </div>
        )
    }
}

export default ObtainEmployment