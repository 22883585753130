const detaultValue = {
    classId: '',
    categoryId: ''
}
const practiceInfo = (state = detaultValue, action) => {
    let practicalinfo = action.practicalinfo
    switch (action.type) {
        case 'PTACTICEINFO':
            console.log(action,"action")
            return {
                ...state,
                ...practicalinfo
            }
        default:
            return state
    }
}
export default practiceInfo;