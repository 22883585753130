import React from "react"
import './login.css'
import SignIn from './module/signIn'
import getBackPassword from './module/getBackPassword'
import { Route, Switch } from 'react-router-dom';
//登录
export default class Login extends React.Component {
    recordNumber = () => {
        let { host } = window.location;
        if (process.env.NODE_ENV == 'development') {
            return '粤ICP备19058570号-1'
        } else {
            return '粤ICP备19126219号'
        }
    }
    render() {
        return (
            <div className="loginMain">
                <div className="loginBanner">
                    <div className="content">
                        <div className="head">
                            <img src={"assets/images/logo.png"}></img>
                            {/* <ul>
                                <li>APP下载</li>
                                <li>会员中心</li>
                            </ul> */}
                        </div>
                        <div className="signModule">
                            <Switch>
                                <Route exact path="/login" component={SignIn}></Route>
                                <Route exact path="/login/getBackPassword" component={getBackPassword}></Route>
                            </Switch>
                        </div>
                    </div>
                </div>
                <div className="official">
                    <div className="content">
                        <div>
                            <p>
                                <img src={require('../../assets/images/login/footer_icon_phone.png')}></img>
                                0755-21002465
                            </p>

                            <p>地址：深圳市南山区西丽同乐路1002号留仙文化园三栋3层</p>
                            <p>Copyright ©深圳市伍壹叁数字科技有限公司 版权所有
                                <a href="https://beian.miit.gov.cn/" target="_blank">{this.recordNumber()}</a></p>
                        </div>
                        {/* <ul>
                            <li>
                                <img src={require('../../assets/images/login/qr_code_wechat.png')}></img>
                                <p>513官方微信</p>
                            </li>
                            <li>
                                <img src={require('../../assets/images/login/qr_code_app.png')}></img>
                                <p>513教育APP</p>
                            </li>
                        </ul> */}
                    </div>
                </div>
            </div>
        )
    }
}