import React from "react";
import CompetitionBox from "../CompetitionBox";
import {
  amountConversion,
  isMobile,
} from "@/utils/utils";
import EChart from "../EChart";
import data from "@/view/cloudAhart/Entrepreneurship/data";
import Empty from '../empty'

class DaySalesData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      option: null,
      isMobile: isMobile(),
    };
  }
  shouldComponentUpdate(next, newState) {
    if (next.data.length && !newState.option) {
      this.init();
      return false;
    }
    return true;
  }
  init = () => {
    let h700 = document.body.clientHeight > 700,
      w1366 = document.body.clientWidth > 1366,
      h800 = Number(document.body.clientHeight) > 800;
    let { data, colors } = this.props;
    if (!data.length) return;
    let list = data;
    let xAxis = list[0].recordList.map((a) => {
      let txt = a.salesTime.split("-")[1] + "/" + a.salesTime.split("-")[2];
      return txt;
    });
    let seriesList = [];
    data.map((item, index) => {
      seriesList.push({
        name: item.teamName,
        type: "line",
        symbol: "none",
        data: item.recordList.map((a) => {
          return a.salesVolume;
        }),
        color: colors[index],
      });
    });
    let option = {
      animationDuration: 8000,
      title: {
        show: false,
      },
      tooltip: {
        trigger: "axis",
        // formatter: "{b}<br />{a0}: {c0} 元<br />{a1}: {c1}",
        formatter: (params) => {
          let txt = `
          ${params[0].axisValue}<br />
          `;
          params.map((item) => {
            txt += `
            ${item.marker}${item.seriesName}：${amountConversion(item.value)}<br />
            `;
          });
          // console.log(params, txt);
          return txt;
        },
      },
      legend: {
        top: h800 ? "10" : "5",
        right: 0,
        type: "scroll",
        height: "90%",
        width: 170,
        orient: "vertical",
        icon: "circle",
        itemWidth: 8,
        itemHeight: 8,
        itemGap: 10,
        top: 'middle',
        textStyle: {
          width: "170",
          color: "#ccc",
          overflow: "truncate",
          fontSize: 12,
          fontFamily: "serif",
          verticalAlign: 'middle',
        },
        pageButtonItemGap: 5,
        pageIconSize: 10,
        pageIconColor: "#ccc",
        pageIconInactiveColor: "#333",
        pageTextStyle: {
          color: "#ccc",
        },
      },
      grid: {
        top: h800 ? 40 : 30,
        left: 50,
        right: 170,
        bottom: 20,
        containLabel: false,
      },
      xAxis: {
        type: "category",
        boundaryGap: false,
        data: xAxis,
        // axisLabel: {
        //   showMaxLabel: true,
        // },
        axisLine: {
          show: true,
          lineStyle: {
            width: 0.4,
            color: "#E6E6E6",
          },
        },
      },
      yAxis: {
        type: "value",
        name: "($)",
        nameTextStyle: {
          // 名称样式
          fontSize: 10,
          color: "#E6E6E6",
          padding: [0, 30, h800 ? 0 : h700 ? -5 : -10, 0],
        },
        axisLine: {
          show: true,
          lineStyle: {
            width: 0,
            color: "#E6E6E6",
          },
        },
        splitLine: {
          lineStyle: {
            width: 0.3,
            color: "#fff",
          },
        },
        axisLabel: {
          textStyle: {
            color: "#E6E6E6",
            fontSize: w1366 ? 12 : document.body.clientWidth > 768 ? 10 : 8,
          },
          formatter: (params, index) => {
            return amountConversion(params);
          },
        },
      },
      series: seriesList,
    };
    this.setState({
      option,
    });
  };
  render() {
    let { option } = this.state;
    let { data, info } = this.props;
    return (
      <CompetitionBox
        className="daySalesData CompetitionBox_bj"
        title="日销售数据"
      >
        <div className="Competition_echart">
          {
            data.length ? (
              <EChart
                option={option}
                styleS={{ width: "100%", height: "94%" }}
                m_id="daySalesData"
              />
            ) : <Empty info={info} text=" " />
          }
        </div>
      </CompetitionBox>
    );
  }
}

export default DaySalesData;
