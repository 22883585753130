import React from "react";
import CompetitionBox from "../CompetitionBox";
import { entrepreneurshipRadius, isMobile } from "@/utils/utils";
import EChart from "../EChart";

class SalesVolume extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      option: null,
      isMobile: isMobile(),
    };
  }
  shouldComponentUpdate(next, newState) {
    if (next.data && !newState.option) {
      this.init();
      return false;
    }
    return true;
  }
  init = () => {
    let { data, colors } = this.props;
    if (!data.length) return;
    let isheights = Number(document.body.clientHeight) >= 1024 ? true : false;
    let w1366 = Number(document.body.clientWidth) > 1366;
    let w1600 = Number(document.body.clientWidth) > 1600;
    let h700 = Number(document.body.clientHeight) > 700;
    let h800 = Number(document.body.clientHeight) > 800;
    let h900 = Number(document.body.clientHeight) > 900;
    let list = data.map((item) => {
      return {
        name: item.schoolName,
        value: item.ratio,
      };
    });
    let option = {
      tooltip: {
        trigger: "item",
        formatter: "{b0}: {c0}%",
        borderWidth: 0,
        confine: true
      },
      legend: {
        top: h800 ? "10" : "5",
        // bottom: 30,
        type: "scroll",
        right: h800 && w1600 ? "5%" : "0",
        width: 50,
        height: "90%",
        orient: "vertical",
        icon: "circle",
        itemWidth: 8,
        itemHeight: 14,
        itemGap: 10,
        textStyle: {
          color: "#E6E6E6",
          // fontSize: entrepreneurshipRadius(),
          fontSize: 12,
          width: w1366 ? "130" : 90,
          overflow: "truncate",
          fontFamily: "serif",
        },
        pageIconSize: 10,
        pageIconColor: "#E6E6E6",
        pageIconInactiveColor: "#333",
        pageTextStyle: {
          color: "#E6E6E6",
          overflow: "truncate",
        },
      },
      series: [
        {
          name: "",
          type: "pie",
          avoidLabelOverlap: false,
          center: [h900 ? (h800 ? "35%" : "30%") : "30%", "center"],
          label: {
            show: false,
            position: "center",
          },
          radius: [
            40,
            isheights && w1600
              ? 70
              : h700
              ? w1366
                ? 65
                : Number(document.body.clientWidth) < 400
                ? 50
                : 20
              : 20,
          ],
          data: list,
          color: colors,
        },
      ],
    };
    if (this.state.isMobile) {
      option.series[0].center = ["30%", "62%"];
      option.series[0].radius = [30, 60];
    }
    this.setState({
      option,
    });
  };
  render() {
    let { option } = this.state;
    return (
      <CompetitionBox className="salesVolume" title="销售额占比">
        <div className="Competition_echart">
          <EChart
            option={option}
            styleS={{ width: "100%", height: "94%" }}
            m_id="salesVolume"
          />
        </div>
      </CompetitionBox>
    );
  }
}

export default SalesVolume;
