import React from "react";
import "./index.css";
import AJAX from "@/api/api";
import { Pagination } from "antd";
import { encryptBy } from "@/utils/encryption";
import { getStudentSideUrl } from "@/utils/utils";
import ResponsiveList, { ResponsiveItem } from "@/components/ResponsiveList";
import CourseNews from './modules/courseNews'
import DataScreening from './modules/dataScreening'
import RealTime from './modules/realTime'
import ClassSituation from './modules/classSituation'
import Analysis from './modules/analysis'



import { BorderBox12, BorderBox7, FullScreenContainer } from '@jiaminghi/data-view-react'
export default class CourseManage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentDate: null,
      currentTime: null,
      boxHeightsT: 0,
      boxHeightsB: 0
    };
  }

  componentDidMount() {
    this.getCurrentDate();
    this.getHeights()
    document.addEventListener('keydown',this.handleKeyDown);
  }

  handleKeyDown = (e) => {
    // console.log("按下了F11键")
    if (e.keyCode === 122) {
    }
}

  getCurrentDate = () => {
    setInterval(() => {
      let newDate = new Date();
      let times = newDate.toLocaleString('chinese', { hour12: false })
      this.setState({
        currentDate: times.split(" ")[0].replace(/\//g, '-'),  //当前日期
        currentTime: "  " + times.split(" ")[1]    //获取当前时间
      })
    }, 1000)
  }

  getHeights = () => {
    let heights = document.body.clientHeight
    // let heights = 930 - 100 - 45;
    // if (Number(document.body.clientHeight) >= 900) heights = Number(document.body.clientHeight) - 90 - 30;
    // if (Number(document.body.clientHeight) >= 1080) heights = Number(document.body.clientHeight) - 90 - 30;
    // if (Number(document.body.clientHeight) <= 768) heights = Number(document.body.clientHeight);
    this.setState({
      boxHeightsT: (Number(document.body.clientHeight) <= 800) ? heights * 0.45 : heights * 0.45,
      boxHeightsB: Number(document.body.clientHeight) <= 800 ? heights * 0.43 : heights * 0.42
    })
  }


  render() {
    let { currentDate, currentTime, boxHeightsT,boxHeightsB } = this.state;
    return (
      <div className="blueWhaleBox">
        {/* <FullScreenContainer> */}
        <div className="titleBox">
        {/* {currentDate}  */}
          <span className="times">{currentDate}{currentTime}</span>
          <span className="pathContent">教务数据智能大屏</span>
          <span className="logOut"></span>
        </div>
        <div className="dynamicBox">
          <CourseNews heights={boxHeightsT} />      {/* 课堂动态 */}
          <DataScreening heights={boxHeightsT} />   {/* 数据总览 */}
          <RealTime heights={boxHeightsT} />        {/* 实时动态 */}
          {/* <BorderBox7 color={['#002986', '#20BCFC']} backgroundColor="#121866">BorderBox12</BorderBox7> */}
        </div>
        <div className="situationBox">
          <ClassSituation heights={boxHeightsB} />    {/* 班级活跃情况 */}
          <Analysis heights={boxHeightsB} />    {/* 实操分析 */}
        </div>

        {/* </FullScreenContainer> */}
      </div>
    );
  }
}
