import React from "react";
import ScreenTitle from '../ScreenTitle'
import { Tooltip, Tag, Space } from 'antd';
import Slider from "react-slick";

class College extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    columns = () => {
        if (this.props.type) {
            return [
                {
                    title: '毕业学校',
                    dataIndex: 'schoolName',
                    with: "48%",
                },
                {
                    title: '姓名',
                    dataIndex: 'name',
                    with: "18%",
                },
                {
                    title: '状态',
                    dataIndex: 'status',
                    with: "17%",
                    render: (text) => {
                        return <span style={{ color: "#01F1F8" }}> 已就业</span>
                    }
                },
                {
                    title: '薪资',
                    dataIndex: 'salary',
                    with: "12%"
                },
            ]
        }
        return [
            {
                title: '监测日期',
                dataIndex: 'checkDate',
                with: "40%"
            },
            {
                title: '学校',
                dataIndex: 'schoolName',
                with: "48%"
            },
            {
                title: '状态',
                dataIndex: 'status',
                with: "12%",
                render: (text) => {
                    return <span style={{ color: "#01F1F8" }}> 正常</span>
                }
            }
        ]
    }

    render() {
        const { type, table, studentExtend } = this.props
        const settings = {
            dots: false,
            infinite: true, //循环
            arrows: false,
            slidesToShow: 3,
            slidesToScroll: 1,
            vertical: true,
            verticalSwiping: false,
            autoplay: true,
            speed: 2000,
            autoplaySpeed: 1000,
            beforeChange: (currentSlide, nextSlide) => {
                // console.log("before change", currentSlide, nextSlide);
            },
            afterChange: function (currentSlide) {
                // console.log("after change", currentSlide);
            }
        }
        // console.log(this.columns().length)
        return (
            <div className="College ljScreen-item">
                <ScreenTitle title={type ? "实时就业统计" : "学校运行状态监测"} />
                {
                    type ? <p className='num'>平均就业率：<span>{studentExtend || 0}%</span></p> : null
                }
                {/* <Table columns={this.columns()} dataSource={table} pagination={false} rowKey="key" /> */}
                <div className="College-table">
                    <div className="College-table-th" style={{ marginBottom: 8 }}>
                        {
                            this.columns().map((item, index) => {
                                return <div className="th" style={{ width: item.with, textAlign: index >= this.columns().length - 1 ? "right" : 'left' }}>{item.title}</div>
                            })
                        }
                    </div>
                    {
                        type ? (
                            <Slider {...settings}>
                                {
                                    table.map((item, index) => {
                                        return <div className="College-table-th">
                                            <div className="College-table-td" style={{ width: "48%", paddingRight: 2 }}>
                                                <Tooltip title={item.schoolName}>{item.schoolName}</Tooltip>
                                            </div>
                                            <div className="College-table-td" style={{ width: "18%" }}>{item.name}</div>
                                            <div className="College-table-td" style={{ width: "17%", color: "#01F1F8" }}>已就业</div>
                                            <div className="College-table-td" style={{ width: "12%", textAlign: "right" }}>{item.salary}</div>
                                        </div>

                                    })
                                }
                            </Slider>
                        ) : (
                            <Slider {...settings}>
                                {
                                    table.map((item, index) => {

                                        return <div className="College-table-th">
                                            <div className="College-table-td" style={{ width: "40%" }}>{item.checkDate}</div>
                                            <div className="College-table-td" style={{ width: "48%", paddingRight: 3 }}>
                                                <Tooltip title={item.schoolName} >{item.schoolName}</Tooltip>
                                            </div>
                                            <div className="College-table-td" style={{ width: "12%", color: "#01F1F8", textAlign: "right" }}>正常</div>
                                        </div>

                                    })
                                }
                            </Slider>
                        )
                    }

                </div>
            </div>
        )
    }
}

export default College