import React, { useEffect } from "react";
import "../../index.css";
import EChart from "./EChart";
import CountUp from "react-countup";

export default class Map extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    let { data } = this.props;
    let num = String(data?.totalSalesVolume);
    let sku = String(data?.totalSkuNum);
    return (
      <div className="Entrepreneurship_Map">
        <h2>
          <span>总累计销售金额：</span>
          <span>
            {data
              ? num.split("").map((item, index) => {
                  if (Number(item)) {
                    return (
                      <CountUp
                        className="sums"
                        end={Number(item)}
                        key={index}
                      ></CountUp>
                    );
                  } else {
                    return (
                      <span key={index} className="sums">
                        {item}
                      </span>
                    );
                  }
                })
              : ""}
          </span>
        </h2>
        <div className="sku">
          总上架SKU：
          {data
            ? sku.split("").map((item, index) => {
                if (Number(item)) {
                  return (
                    <CountUp
                      className="sums"
                      end={Number(item)}
                      key={index}
                    ></CountUp>
                  );
                } else {
                  return (
                    <span key={index} className="sums">
                      {item}
                    </span>
                  );
                }
              })
            : ""}
        </div>
        <div className="Entrepreneurship_Map_body">
          <EChart allData={null} />
        </div>
      </div>
    );
  }
}
