import React from "react";
import ShipBox from "../ShipBox";
import EChart from "../EChart";
import * as echarts from 'echarts';
import { bigNumberTransform } from "@/utils/utils";

class SalesData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      option: null,
      nav: [],
      allData: {},
      valueKey: "",
    };
  }
  shouldComponentUpdate(next, newState) {
    if (next.storeOption.length != newState.nav.length && next.data) {
      this.setState(
        {
          nav: next.storeOption,
        },
        () => {
          this.init();
        }
      );
      return false;
    }
    return true;
  }

  init = () => {
    const { data } = this.props;
    if (!data) return;
    // console.log(data);
    let { monthList, salesVolumeList } = data;
    let xAxis = monthList;
    let datas = salesVolumeList;
    let w1366 = Number(document.body.clientWidth) > 1366;
    let option = {
      tooltip: {
        trigger: "axis",
        formatter: "{b0}月: {c0} 元",
        position: function (point, params, dom, rect, size) {
          // 固定在顶部
          // console.log(point, size);
          return [
            point[0] < size.viewSize[0] / 2
              ? point[0]
              : size.viewSize[0] -
                (size.viewSize[0] - point[0]) -
                size.contentSize[0] -
                10,
            point[1],
          ];
        },
      },
      xAxis: {
        type: "category",
        boundaryGap: true,
        data: xAxis,
        scale: true,
        axisTick: {
          show: false,
        },
        axisLine: {
          lineStyle: {
            color: "#fff",
            width: 0.2,
          },
        },
        axisLabel: {
          showMaxLabel: true,
          formatter: (params, index) => {
            let txt = params + "月";
            return txt;
          },
        },
      },
      yAxis: {
        name: "（元）", // 竖轴名称
        nameTextStyle: {
          // 名称样式
          fontSize: 10,
          color: "#fff",
          padding: [0, 0, 0, -23],
        },
        minInterval: 1,
        type: "value",
        scale: true,
        axisLine: {
          show: false,
          lineStyle: {
            color: "#fff",
            width: 0.2,
          },
        },
        axisLabel: {
          textStyle: {
            color: "#F2F2F2",
            fontSize: w1366 ? 10 : document.body.clientHeight > 700 ? 8 : 6,
          },
          formatter: (params, index) => {
            return bigNumberTransform(params);
          },
          interval: 3,
        },
        axisTick: {
          show: false,
        },
        splitLine: {
          show: true,
          lineStyle: {
            width: 0.2,
            color: "#fff",
          },
        },
      },
      grid: {
        left: 10,
        right: 15,
        top: 75,
        bottom: 0,
        containLabel: true,
      },
      series: [
        {
          data: datas,
          type: "line",
          color: "#2AFFFF",
          areaStyle: {
            normal: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#2AFFFF" },
                { offset: 0.1, color: "#2AFFFF" },
                { offset: 0.2, color: "#0DD2D2" },
                { offset: 1, color: "transparent" },
              ]),
            },
          },
        },
      ],
    };
    this.setState({
      option,
    });
  };
  shiponChange = (key) => {
    this.setState({
      valueKey: key,
    });
    this.props.shiponChange(key).then((res) => {
      if (res.code == 1) {
        this.init();
      }
    });
  };

  render() {
    const { option, nav, valueKey } = this.state;
    return (
      <ShipBox
        isSelect={true}
        title="店铺销售数据"
        className="SalesData Entrepreneurship_box"
        shiponChange={this.shiponChange}
        selectId="SalesData_id"
        options={nav}
        value={valueKey}
      >
        <div className="ShopAll_echart">
          <EChart
            option={option}
            styleS={{ width: "100%", height: "94%" }}
            m_id="SalesData"
          />
        </div>
      </ShipBox>
    );
  }
}

export default SalesData;
