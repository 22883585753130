import React, { useMemo } from "react";
import { Button as AntButton } from "antd";
import { throttle } from "@/utils/utils";
import useEventCallback from "@/hooks/useEventCallback";
import "./style.css";

const Button = ({ onClick, throttleTime = 0, className, ...otherProps }) => {
  const handleClick = useMemo(() => {
    return throttle(onClick || (() => {}), throttleTime);
  }, [onClick, throttleTime]);


  const innerClassName = useMemo(() => {
    return [className || '', 'a-btn'].join(' ')
  }, [className])

  const keepClick = useEventCallback(handleClick, [handleClick]);

  return <AntButton {...otherProps} className={innerClassName} onClick={keepClick} />;
};

export default Button;
