import {combineReducers} from "redux";
import teacherUserInfo from './teacherUserInfo'
import uploadTask from './uploadTaskReducer'
import practiceInfo from './practiceInfo'


const reducers = combineReducers({
    teacherUserInfo,
    uploadTask,
    practiceInfo,
});

export default reducers;
