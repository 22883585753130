import React, { useState, useCallback, useEffect } from "react";
import CountUp from "react-countup";
import CompetitionBox from "../CompetitionBox";
import { amountConversion, } from "@/utils/utils";
import { Tooltip } from "antd";
import { QuestionOutlined } from '@ant-design/icons';
import Empty from '../empty'

export default function Top10({ teamSaleRankList, issumList, competitionTotal, info }) {
    const [num, setNum] = useState('')
    const [list, setList] = useState([1, 2, 3])
    const [salelist, setSaleList] = useState([null, null, null])
    const [issuelist, setIssueList] = useState([null, null, null])
    useEffect(() => {
        let list = []
        let arr = []

        // setIssueList(arr)
        if (teamSaleRankList.length) {
            // arr = [teamSaleRankList[0], teamSaleRankList[1], teamSaleRankList[2]]
            setSaleList(teamSaleRankList)
        }
        if (issumList.length) {
            // arr = [teamSaleRankList[0], teamSaleRankList[1], teamSaleRankList[2]]
            setIssueList(issumList)
        }
        // setList(list)
        // console.log(issuelist);
    }, [teamSaleRankList, issumList])
    useEffect(() => {
        if (competitionTotal) {
            setNum(String(competitionTotal.totalSalesVolume))
        }
    }, [competitionTotal])

    return (
        <div className="top10">
            <div className="volume">
                <span className="txt">总销售额（美元）：</span>
                {num
                    ? num.split("").map((item, index) => {
                        if (Number(item)) {
                            return (
                                <CountUp
                                    className="sums"
                                    end={Number(item)}
                                    key={index}
                                ></CountUp>
                            );
                        } else {
                            return (
                                <span key={index} className="sums">
                                    {item}
                                </span>
                            );
                        }
                    })
                    : ""}
            </div>
            <div className="topInner">
                <div>
                    <CompetitionBox className="sale" title="销售排行榜">
                        <div className="trophy">
                            {
                                salelist.map((item, index) => {
                                    if (index < 3) {
                                        if (item) {
                                            return (
                                                <div className="trophy_item" key={item.teamId + '-' + index}>
                                                    <img src={require(`@/assets/images/cloudChart/trophy${index + 1}.png`)} alt="" />
                                                    <div className="text">
                                                        <Tooltip title={<><p>团队：{item.teamName}</p><p>学校：{item.schoolName}</p></>}>
                                                            <p className="text-ellipsis-1">{item.teamName}</p>
                                                            <p className="text-ellipsis-1">{item.schoolName}</p>
                                                        </Tooltip>
                                                        <div>{amountConversion(item.salesVolume)}</div>
                                                    </div>
                                                </div>
                                            )
                                        } else {
                                            return (
                                                <div className="trophy_item" key={index}>
                                                    <img src={require(`@/assets/images/cloudChart/trophy${index + 1}.png`)} alt="" />
                                                    <div className="text"><div>?</div></div>
                                                </div>
                                            )
                                        }
                                    }
                                })
                            }
                        </div>
                        <div className="title SchooleList_row">
                            <div className="SchooleList_col p-0">排名</div>
                            <div className="SchooleList_col">学校名称</div>
                            <div className="SchooleList_col">团队名称</div>
                            <div className="SchooleList_col">销售额($)</div>
                        </div>
                        <div className="table">
                            {
                                salelist.length > 3 ? salelist.map((item, index) => {
                                    if (index > 2 && index < 10) {
                                        return (
                                            <div key={index} className="SchooleList_item">
                                                <div className="SchooleList_row">
                                                    <div className="SchooleList_col colred">{index + 1}</div>
                                                    <div className="SchooleList_col text-ellipsis-1">
                                                        <Tooltip title={item.schoolName}>{item.schoolName}</Tooltip>
                                                    </div>
                                                    <div className="SchooleList_col text-ellipsis-1">
                                                        <Tooltip title={item.teamName}>{item.teamName}</Tooltip>
                                                    </div>
                                                    <div className="SchooleList_col">{amountConversion(item.salesVolume)}</div>
                                                </div>
                                            </div>
                                        );
                                    }
                                }) : (<Empty info={info} text=" " />)
                            }
                        </div>
                    </CompetitionBox>
                </div>
                <div>
                    <CompetitionBox className="issue" title="出单排行榜">
                        <div className="trophy">
                            {
                                issuelist.map((item, index) => {
                                    if (index < 3) {
                                        if (item) {
                                            return (
                                                <div className="trophy_item" key={item.schoolId + '_' + index}>
                                                    <img src={require(`@/assets/images/cloudChart/Entrepreneurship_a${index + 1}.png`)} alt="" />
                                                    <div className="text">
                                                        <Tooltip title={<><p>团队：{item.teamName}</p><p>学校：{item.schoolName}</p></>}>
                                                            <p className="text-ellipsis-1">{item.teamName}</p>
                                                            <p className="text-ellipsis-1">{item.schoolName}</p>
                                                        </Tooltip>
                                                        <div>{item.issueNum}单</div>
                                                    </div>
                                                </div>
                                            )
                                        } else {
                                            return (
                                                <div className="trophy_item" key={index}>
                                                    <img src={require(`@/assets/images/cloudChart/Entrepreneurship_a${index + 1}.png`)} alt="" />
                                                    <div className="text">?<p></p></div>
                                                </div>
                                            )
                                        }

                                    }
                                })
                            }
                        </div>
                        <div className="title SchooleList_row">
                            <div className="SchooleList_col p-0">排名</div>
                            <div className="SchooleList_col">学校名称</div>
                            <div className="SchooleList_col">团队名称</div>
                            <div className="SchooleList_col">出单量</div>
                        </div>
                        <div className="table">
                            {
                                issuelist.length > 3 ? issuelist.map((item, index) => {
                                    if (index > 2 && index < 10) {
                                        return (
                                            <div key={index} className="SchooleList_item">
                                                <div className="SchooleList_row">
                                                    <div className="SchooleList_col colred">{index + 1}</div>
                                                    <div className="SchooleList_col text-ellipsis-1">
                                                        <Tooltip title={item.schoolName}>{item.schoolName}</Tooltip>
                                                    </div>
                                                    <div className="SchooleList_col text-ellipsis-1">
                                                        <Tooltip title={item.teamName}>{item.teamName}</Tooltip>
                                                    </div>
                                                    <div className="SchooleList_col">{item.issueNum}单</div>
                                                </div>
                                            </div>
                                        );
                                    }
                                }) : issuelist.length ? null : (<Empty info={info} text=" " />)
                            }
                        </div>
                    </CompetitionBox>
                </div>
            </div>
        </div>
    )
}