import React from "react";
import "./index.css";
import College from './modules/College';
import Enterprise from './modules/Enterprise';
import UsersNum from './modules/UsersNum';
import Map from './modules/Map';
import Hatch from './modules/Hatch';
import EmploymentStatistics from './modules/EmploymentStatistics';
import ObtainEmployment from './modules/ObtainEmployment';
import CareerGrowth from './modules/CareerGrowth';
import AJAX from '@/api/api';
import getUrlData from "@/utils/getUrlData";

class LjScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            allCityData: null, //所有省份启动数据 
            enterpriseData: [], // 企业入驻数据
            schoolDealDate: [], //孵化基地交易数据
            schoolRunCheck: [], //学校运行状态监控
            skuCategoryDate: null, //sku品类数据
            storeSalesData: null, //店铺销售数据
            studentJobData: [], //学生就业数据 
            schoolName: [],
            title: getUrlData('name') || '蓝鲸系统实时数据大屏',
        };
        this.times = null
    }
    componentDidMount() {
        this.getData()
        this.times = setInterval(() => {
            this.getSchoolRunCheck()
            // this.getStudentJobData()
        }, 600000);
    }
    getData = () => {
        this.getAllCityData()
        this.getEnterpriseData()
        this.getSchoolDealDate()
        this.getSchoolRunCheck()
        this.getSkuCategoryDate()
        this.getSchoolName()
        this.getStudentJobData()
    }
    getAllCityData = () => {
        AJAX.blueWahle.getAllCityData({}).then(res => {
            // console.log(res)
            if (res.code == 1) {
                this.setState({
                    allCityData: res.data
                })
            }
        })
    }
    getEnterpriseData = () => {
        AJAX.blueWahle.getEnterpriseData({}).then(res => {
            if (res.code == 1) {
                this.setState({
                    enterpriseData: res.data
                })
            }
        })
    }
    getSchoolDealDate = () => {
        AJAX.blueWahle.getSchoolDealDate({}).then(res => {
            if (res.code == 1) {
                this.setState({
                    schoolDealDate: res.data
                })
            }
        })
    }
    getSchoolRunCheck = () => {
        AJAX.blueWahle.getSchoolRunCheck({}).then(res => {
            if (res.code == 1) {
                this.setState({
                    schoolRunCheck: res.data
                })

            }
        })
    }
    getSkuCategoryDate = () => {
        AJAX.blueWahle.getSkuCategoryDate({}).then(res => {
            if (res.code == 1) {
                this.setState({
                    skuCategoryDate: res.data
                })
            }
        })
    }
    getStoreSalesData = (schoolName) => {
        AJAX.blueWahle.getStoreSalesData({ schoolName }).then(res => {
            if (res.code == 1) {
                this.setState({
                    storeSalesData: res.data
                })
            }
        })
    }
    getStudentJobData = () => {
        AJAX.blueWahle.getStudentJobData({}).then(res => {
            if (res.code == 1) {
                this.setState({
                    studentJobData: res.data,
                    studentExtend: res.extend,
                })
            }
        })
    }
    getSchoolName = () => {
        AJAX.blueWahle.getSchoolName({}).then(res => {
            if (res.code == 1) {
                this.setState({
                    schoolName: res.data
                })
                this.getStoreSalesData(res.data[0])
            }
        })
    }

    render() {
        const {
            allCityData,
            enterpriseData,
            schoolDealDate,
            schoolRunCheck,
            skuCategoryDate,
            storeSalesData,
            studentJobData,
            schoolName,
            studentExtend,
            title
        } = this.state
        let heigths = document.body.clientWidth > 768
        let os = navigator.userAgent.includes("Android")
        return (
            <div className={heigths ? "ljScreen" : "ljScreen moveljScreen"}>
                <div className="ljScreen-header">
                    <div className="header-title">{title}</div>
                </div>
                <div className="ljScreen-body" >
                    {
                        heigths && <div className='ljScreen-right'>
                            <College type={0} table={schoolRunCheck} />
                            <Enterprise enterpriseData={enterpriseData} />
                            <UsersNum schoolName={schoolName} storeSalesData={storeSalesData} getStoreSalesData={this.getStoreSalesData} />
                        </div>
                    }

                    <div className='ljScreen-center'>
                        <Map allCityData={allCityData} />
                        <Hatch schoolDealDate={schoolDealDate} />
                    </div>
                    {
                        !heigths && <div className='ljScreen-right'>
                            <College type={0} table={schoolRunCheck} />
                            <Enterprise enterpriseData={enterpriseData} />
                            <UsersNum schoolName={schoolName} storeSalesData={storeSalesData} getStoreSalesData={this.getStoreSalesData} />
                        </div>
                    }
                    <div className='ljScreen-left' style={os ? { marginBottom: "100px" } : {}}>
                        <CareerGrowth studentJobData={studentJobData} />
                        <College type={1} studentExtend={studentExtend} table={studentJobData} />
                        <EmploymentStatistics skuCategoryDate={skuCategoryDate} />
                    </div>
                </div>

            </div>
        )
    }
}

export default LjScreen