import React from "react";
import "./index.css";
import AJAX from "@/api/api";
import SalesList from "./modules/SalesList";
import Issue from "./modules/Issue";
import SalesVolume from "./modules/SalesVolume";
import DaySalesData from "./modules/DaySalesData";
import Category from "./modules/Category";
import SalesLog from "./modules/SalesLog";
import Map from "./modules/Map";
import CompetitionInfo from "./modules/info";
import SchoolSwiper from "./modules/SchoolSwiper";
import { isMobile, resizeFun } from "@/utils/utils";
import getUrlData from "@/utils/getUrlData";

class Competition extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: isMobile(),
      competitionId: getUrlData("id"),
      titleName: getUrlData("name"),
      optionList: [],
      categorySale: [],
      schoolList: [],
      timeSalesData: null,
      info: null,
      competitionTotal: null,
      schoolIssue: null,
      schoolSaleList: [],
      schoolSaleSector: [],
      storeRecord: null,
      colors: [
        "#F134F2",
        "#A022FC",
        "#C679FE",
        "#4F31EB",
        "#147AFF",
        "#DCA23F",
        "#E86448",
        "#44B5D6",
        "#0A8D9D",
        "#3822FC",
        "#3BB176",
        "#B2BC1F",
        "#EB2359",
      ],
      time: new Date().toTimeString().slice(0, 8),
    };
    this.timer = null;
  }
  componentDidMount() {
    // this.getAllData(getUrlData("id"));
    this.getCategorySaleSector(getUrlData("id"));
    this.tick();
    let query = {}
    if (getUrlData("id")) {
      query = { competitionId: getUrlData("id") }
    } else if (getUrlData("cp_mask")) {
      query = { cp_mask: getUrlData("cp_mask") }
    }
    AJAX.competition.getCompetitionInfo(query).then(res => {
      if (res.code == 1) {
        this.setState({
          competitionId: res.data.competitionId,
          titleName: res.data.competitionName,
          info: res.data
        })
        this.getAllData(res.data.competitionId);
      }
    })
  }
  getAllData(id) {
    this.getCompetitionSchoolList(id);
    this.getCompetitionTimeSalesData(id);
    this.getCompetitionTotal(id);
    this.getSchoolIssue(id);
    this.getSchoolSaleList(id);
    this.getSchoolSaleSector(id);
    this.queryStoreRecord(id);
  }
  getCompetitonOptionList() {
    AJAX.competition.getCompetitonOptionList().then((res) => {
      if (res.code == 1) {
        this.setState({
          optionList: res.data,
          competitionId: res.data[0].competitionId,
        });
        this.getAllData(res.data[0].competitionId);
      }
    });
  }
  getCategorySaleSector(id) {
    AJAX.competition.getCategorySaleSector({ competitionId: id }).then((res) => {
      if (res.code == 1) {
        this.setState({
          categorySale: res.data,
        });
      }
    });
  }
  getCompetitionSchoolList(id) {
    AJAX.competition
      .getCompetitionSchoolList({ competitionId: id })
      .then((res) => {
        if (res.code == 1) {
          this.setState({
            schoolList: res.data,
          });
        }
      });
  }
  getCompetitionTimeSalesData(id) {
    AJAX.competition
      .getCompetitionTimeSalesData({ competitionId: id })
      .then((res) => {
        if (res.code == 1) {
          this.setState({
            timeSalesData: res.data,
          });
        }
      });
  }
  getCompetitionTotal(id) {
    AJAX.competition.getCompetitionTotal({ competitionId: id }).then((res) => {
      if (res.code == 1) {
        this.setState({
          competitionTotal: res.data,
        });
      }
    });
  }
  getSchoolIssue(id) {
    AJAX.competition.getSchoolIssue({ competitionId: id }).then((res) => {
      if (res.code == 1) {
        this.setState({
          schoolIssue: res.data,
        });
      }
    });
  }
  getSchoolSaleList(id) {
    AJAX.competition.getSchoolSaleList({ competitionId: id }).then((res) => {
      if (res.code == 1) {
        this.setState({
          schoolSaleList: res.data,
        });
      }
    });
  }
  getSchoolSaleSector(id) {
    AJAX.competition.getSchoolSaleSector({ competitionId: id }).then((res) => {
      if (res.code == 1) {
        this.setState({
          schoolSaleSector: res.data.ratioList,
        });
      }
    });
  }
  queryStoreRecord(id) {
    AJAX.competition.queryStoreRecord({ competitionId: id }).then((res) => {
      if (res.code == 1) {
        this.setState({
          storeRecord: res.data,
        });
      }
    });
  }
  componentWillUnmount() {
    // 第四步：组件将要被卸载时注意定时器必须清除
    clearTimeout(this.timer);
  }

  tick = () => {
    // 第二步：设置时间间隔，更新时间
    this.timer = setInterval(() => {
      // 但是这样设计后，页面上的时间并不会实时修改，只会在每次刷新后修改
      let times = new Date().toLocaleString('chinese', { hour12: false })
      this.setState({
        // 所以我们要借助生命周期函数：
        time: `${times.split(" ")[0].replace(/\//g, '-')} ${times.split(" ")[1]}`,
      });
    }, 1000);
  };
  render() {
    let {
      titleName,
      optionList,
      categorySale,
      schoolList,
      timeSalesData,
      competitionTotal,
      schoolIssue,
      schoolSaleList,
      schoolSaleSector,
      storeRecord,
      colors,
      time,
      info
    } = this.state;
    let teacherUserInfo = JSON.parse(localStorage.getItem("teacherUserInfo"));
    let logo = teacherUserInfo?.transparentLogo;
    return (
      <div className="competition">
        <h1>
          <img className="logo" src={info && info.competitionLogo}></img>
          <span>{titleName}</span>
          <span className="times">{time}</span>
        </h1>
        <SchoolSwiper data={schoolList}></SchoolSwiper>
        <div className="competition_body">
          <div className="competition_body_left">
            <SalesList data={schoolSaleList} colors={colors} />
            <Issue data={schoolIssue} />
            <SalesVolume data={schoolSaleSector} colors={colors} />
          </div>
          <div className="competition_body_center">
            <Map data={competitionTotal} />
            <DaySalesData data={schoolSaleList} colors={colors} />
          </div>
          <div className="competition_body_right">
            <CompetitionInfo data={timeSalesData} />
            <SalesLog data={storeRecord} />
            <Category data={categorySale} />
          </div>
        </div>
      </div>
    );
  }
}

export default Competition;
