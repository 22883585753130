import React from "react";
import CompetitionBox from "../CompetitionBox";
import {
  bigNumberTransform,
  isMobile,
  toDec,
  entrepreneurshipRadius,
} from "@/utils/utils";
import EChart from "../EChart";

class SalesList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      option: null,
      isMobile: isMobile(),
      updateFrequency: 1000,
    };
  }
  shouldComponentUpdate(next, newState) {
    if (next.data.length && !newState.option) {
      // console.log(next.data.length);
      this.init(next.data);
      return false;
    }
    return true;
  }
  init = (data) => {
    let heights = document.body.clientHeight > 800,
      widths = document.body.clientWidth > 1366;
    let colors = ['#EE1E1E', '#E71EE8', '#42E736', '#07D9E8', '#5B8FF9', '#EEB20E']
    // console.log(data);
    let list = [...data].reverse()
    if (!data.length) return
    let datas = list.map((item, index) => {
      return {
        value: item.salesVolume,
        itemStyle: {
          color: colors[index]
        },
        with: 10,
      }
    })

    let option = {
      tooltip: {
        trigger: 'axis'
      },
      grid: {
        top: 50,
        left: 80,
        right: 30,
        bottom: 30,
      },
      xAxis: {
        type: 'value',
        axisLine: {
          show: true,
          lineStyle: {
            color: "#FFFFFF",
            width: .3
          }
        },
        splitLine: {
          show: false
        },
        axisTick: {
          show: false
        },
        nameTextStyle: {// 名称样式
          fontSize: 10,
          color: '#fff',
          padding: [28, 0, 0, widths ? 0 : 0],

        },
        axisLabel: {
          textStyle: {
            color: '#fff',
            fontSize: document.body.clientWidth > 1200 ? 12 : document.body.clientWidth > 768 ? 12 : 10,
          },
          formatter: (params, index) => {
            return bigNumberTransform(params)
          },
          interval: 3,
        },
      },
      yAxis: {
        type: 'category',
        data: list.map(item => item.storeName),
        axisLine: {
          lineStyle: {
            color: "#FFFFFF",
            width: .3
          }
        },
        // offset: 5,
        axisTick: {
          show: false
        },
        axisLabel: {
          rotate: document.body.clientWidth > 768 ? 0 : 30,
          formatter: function (name) {
            return name.length > 5 ? name.substr(0, 5) + '...' : name
          },
          textStyle: {
            color: '#FFFFFF',
            fontSize: document.body.clientWidth > 1200 ? 12 : document.body.clientWidth > 768 ? 12 : 10,
          },
        },
      },
      series: [
        {
          barWidth: '8px',
          showBackground: true,
          backgroundStyle: {
            borderRadius: [0, 5, 5, 0]
          },
          itemStyle: {
            normal: {
              barBorderRadius: [0, 5, 5, 0],
            }
          },
          data: datas,
          type: 'bar',
        }
      ]
    }
    if (this.state.isMobile) {
      option.series[0].barWidth = "8px";
      option.xAxis.axisLabel.textStyle.fontSize = 10;
      option.yAxis.axisLabel.textStyle.fontSize = 10;
    }
    // console.log(option, 'option');
    this.setState({
      option,
    });
  };
  runOption = () => {
    let { data, colors } = this.props;
    let { updateFrequency } = this.state;
    if (!data.length) return;
    let list = data;
    let yAxis = [];
    let times = [];
    let datas = [];
    list.map((item, index) => {
      yAxis.push({
        schoolName: item.schoolName,
        color: colors[index],
      });
      if (index == 0) {
        item.recordList.map((v) => {
          times.push(v.salesTime);
        });
      }
    });
    times.map((item, index) => {
      let arr = [];
      list.map((v, ind) => {
        if (index != 0) {
          // console.log(datas[index-1][ind]);
          arr.push(
            datas[index - 1][ind] + list[ind].recordList[index].salesVolume
          );
        } else {
          arr.push(list[ind].recordList[index].salesVolume);
        }
      });
      datas.push(arr);
    });
    // console.log(datas);
    for (let i = 0; i < datas.length - 1; ++i) {
      setTimeout(() => {
        this.init({ yAxis, times, data: datas[i], text: times[i] });
      }, i * updateFrequency);
    }
  };

  render() {
    let { option } = this.state;
    return (
      <CompetitionBox className="salesList" title="创业销售榜" subtitle="销量额 ($）">
        <div className="Competition_echart">
          {
            option && <EChart
              m_id="salesList"
              styleS={{ width: "100%", height: "94%" }}
              option={option}
            />
          }
        </div>
      </CompetitionBox>
    );
  }
}

export default SalesList;
