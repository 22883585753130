import React from "react";
import ScreenTitle from '../ScreenTitle';
import Charts from './eCharts'

class Enterprise extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        const { enterpriseData } = this.props
        let jobsCount = 0, enterprisesCount = 0
        enterpriseData.map(item => {
            jobsCount += item.jobsCount
            enterprisesCount += item.enterprisesCount
        })
        return (
            <div className="Enterprise ljScreen-item">
                <ScreenTitle title="企业入驻" />
                <div className='Enterprise-title' >
                    <div style={{ marginRight: 10 }}>企业总数：<span>{enterprisesCount} <span style={{ fontWeight: 400 }}>家</span></span></div>
                    <div>岗位总数：<span>{jobsCount} <span style={{ fontWeight: 400 }}>个</span></span></div>
                </div>
                <div className='Enterprise-legend' >
                    <div><span style={{ background: "#0C6DD7" }}></span>企业数量</div>
                    <div><span style={{ background: "#F9B03D" }}></span>岗位数量</div>
                </div>
                <Charts echartsInfo={enterpriseData} />
            </div>
        )
    }
}

export default Enterprise