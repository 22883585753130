import React from "react";
import ShipBox from "../ShipBox";
import EChart from "../EChart";
import { bigNumberTransform, isMobile } from "@/utils/utils";

class ShopSKUData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      option: null,
      isMobile: isMobile(),
    };
  }
  shouldComponentUpdate(next, newState) {
    if (next.data.length && !newState.option) {
      this.init();
      return false;
    }
    return true;
  }
  init = () => {
    let heights = document.body.clientHeight > 700,
      widths = document.body.clientWidth > 1366;

    let { data } = this.props;
    if (!data.length) return;
    let list = data;
    let xAxis = list.map((item) => item.categoryName);
    let datas = list.map((item) => item.salesVolume);
    let w1366 = Number(document.body.clientWidth) > 1366;
    let option = {
      tooltip: {
        trigger: "item",
        formatter: (params, index) => {
          return `${params.name}：${bigNumberTransform(params.value)}`;
        },
      },
      yAxis: {
        type: "value",
        axisLine: {
          show: false,
          lineStyle: {
            color: "#F2F2F2",
            width: 0.3,
            fontSize: "0.75rem",
          },
        },
        splitLine: {
          lineStyle: {
            width: 0.2,
            color: "#fff",
          },
        },
        axisTick: {
          show: false,
        },
        nameTextStyle: {
          // 名称样式
          fontSize: 12,
          color: "#2193F8",
          padding: [28, 0, 0, 0],
        },
        axisLabel: {
          textStyle: {
            color: "#F2F2F2",
            fontSize: w1366 ? 12 : document.body.clientWidth > 768 ? 10 : 8,
          },
          formatter: (params, index) => {
            return bigNumberTransform(params);
          },
        },
      },
      xAxis: {
        type: "category",
        data: xAxis,
        axisLine: {
          lineStyle: {
            color: "#F2F2F2",
            width: 0.3,
            fontSize: "0.75rem",
          },
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          rotate: document.body.clientWidth > 768 ? 0 : 30,
          textStyle: {
            color: "#F2F2F2",
            fontSize: w1366 ? 10 : document.body.clientWidth > 768 ? 8 : 6,
          },
        },
      },
      grid: {
        left: 10,
        right: 15,
        top: 60,
        bottom: 0,
        containLabel: true,
      },
      series: [
        {
          barWidth: widths ? "16px" : "8px",
          data: datas,
          type: "bar",
          color: "#0DD2D2",
        },
      ],
    };
    if (this.state.isMobile) {
      option.series[0].barWidth = '16px';
      option.xAxis.axisLabel.textStyle.fontSize = 8;
      option.yAxis.axisLabel.textStyle.fontSize = 10;
    }
    this.setState({
      option,
    });
  };

  render() {
    const { option } = this.state;
    return (
      <ShipBox
        title="SKU品类销售数据"
        className="ShopSKUData Entrepreneurship_box"
      >
        <div className="ShopAll_echart">
          <EChart
            option={option}
            styleS={{ width: "100%", height: "94%" }}
            m_id="ShopSKUData"
          />
        </div>
      </ShipBox>
    );
  }
}

export default ShopSKUData;
