import React, { useState, useCallback, useEffect, useRef } from "react";
import CompetitionBox from "../CompetitionBox";
import { bigNumberTransform, } from "@/utils/utils";
import { Select, Tooltip } from "antd";
import Slider from "react-slick";
import moment from 'moment';
import getUrlData from "@/utils/getUrlData";
import Empty from '../empty'

function SalesVolume({ data, getTeamSalesSoarList, competitionId, info }) {
  const [list, setList] = useState([])
  const [options, setOptions] = useState([])
  const [optionVlaue, setOptionVlaue] = useState('')
  const [initKey, setinitKey] = useState(1)

  const settings = {
    dots: false,
    infinite: false, //循环
    arrows: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
  };
  const sliderRef = useRef(null)
  useEffect(() => {
    let time = []
    for (let i = 0; i < 9; i++) {
      if (i > 1) {
        time.push(moment().subtract(i, 'days').format('YYYY/MM/DD'))
      }
    }
    setOptionVlaue(time[0])
    setOptions(time)
  }, [])

  useEffect(() => {
    // console.log(data);
    if (data && data.length) {
      setList(data)
      setKey()
    }
  }, [data])
  const onChange = (e) => {
    setOptionVlaue(e)
    getTeamSalesSoarList(competitionId, e)
  }
  const setKey = useCallback(() => {
    setinitKey(initKey + 1)
  }, [initKey])
  const afterChange = useCallback((e) => {
    let len = list.length
    // console.log(sliderRef);
    if (e == len - 3) {
      // sliderRef.current.slickGoTo(1, true)
      setKey()
    }
  }, [list, initKey])
  return (
    <CompetitionBox className="soar" title="销售飙升榜">
      <Select className="timeSelect" dropdownClassName='timeSelect_popup' value={optionVlaue} onChange={onChange} >
        {
          options.map(item => {
            return <Select.Option value={item} key={item}>{item}</Select.Option>
          })
        }
      </Select>
      <div className="title SchooleList_row">
        <div className="SchooleList_col p-0">排名</div>
        <div className="SchooleList_col">学校名称</div>
        <div className="SchooleList_col">团队名称</div>
        <div className="SchooleList_col">销售环比</div>
      </div>
      {
        list.length ? (
          <div className="table" key={initKey}>
            <Slider {...settings} afterChange={afterChange} ref={sliderRef} >
              {
                list.map((item, index) => {
                  return (
                    <div key={index} className="SchooleList_item">
                      <div className="SchooleList_row">
                        <div className="SchooleList_col">{index + 1}</div>
                        <div className="SchooleList_col text-ellipsis-1">
                          <Tooltip title={item.schoolName}>{item.schoolName}</Tooltip>
                        </div>
                        <div className="SchooleList_col text-ellipsis-1">
                          <Tooltip title={item.teamName}>{item.teamName}</Tooltip>
                        </div>
                        {
                          item.ratio > 0 ? <div className="SchooleList_col up">{item.ratio}% <img src={require(`@/assets/images/cloudChart/up.png`)} /></div>
                            : <div className="SchooleList_col down">{item.ratio}% <img src={require(`@/assets/images/cloudChart/down.png`)} /></div>
                        }

                      </div>
                    </div>
                  );
                })
              }
            </Slider>
          </div>
        ) : (
          <Empty info={info} text=" " />
        )
      }

    </CompetitionBox>
  )
}

export default SalesVolume;
