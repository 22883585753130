import React from "react";
import EChart from './EChart';
import { Table } from 'antd';
import CountUp from 'react-countup'

class SMap extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }
    columns = () => {
        return [
            {
                title: '省份',
                dataIndex: 'provinces',
                render: (text, row, index) => {
                    return `${index + 1}.${text}`
                }
            },
            {
                title: '启用次数',
                dataIndex: 'openCount',
            },
            {
                title: '占比',
                dataIndex: 'ratio',
                width: "10%",
            },
        ]
    }

    render() {
        const { allCityData } = this.props
        let num = allCityData ? String(allCityData.total) : ''
        // console.log(num.split(''))
        return (
            <div className="SMap">
                <div className="SMap-title">
                    {/* <p>同比增长 <br /> {allCityData && allCityData.nowRatio}</p> */}
                    <div className="SMap-num">
                        {
                            num.split('').map((item, index) => {
                                return <CountUp className="sums" end={item} key={index} ></CountUp>
                            })
                        }
                    </div>
                </div>

                <EChart allCityData={allCityData} />
                <Table className="SMap-table" columns={this.columns()} rowKey="key" dataSource={allCityData && allCityData.list} pagination={false} />
            </div>
        )
    }
}

export default SMap