import React, {
  Component,
  useEffect,
  useRef,
  useMemo,
  useState,
  useCallback,
} from "react";
import * as echarts from 'echarts';

// class EChart extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {};
//   }
//   componentDidUpdate(nextporps) {
//     if (nextporps.option) {
//       console.log(nextporps.option);
//       this.createChart();
//     }
//   }
//   componentDidMount(){
//     console.log('123',this.props.m_id);
//   }

//   createChart = () => {
//     let { option, M_id } = this.props;
//     let mapChart = echarts.init(document.getElementById(m_id));
//     mapChart.setOption(option);
//   };

//   render() {
//     let { option, m_id } = this.props;
//     return <div id={m_id} style={{ width: "120%", height: "100%" }}></div>;
//   }
// }

// export default EChart;
export default ({ option, m_id, styleS }) => {
  useEffect(() => {
    if (!option) return;
    let mapChart = echarts.init(document.getElementById(m_id));
    mapChart.setOption(option);
  }, [option, m_id]);
  return <div id={m_id} style={styleS}></div>;
};
