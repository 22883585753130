import React from "react";
import ScreenTitle from '../ScreenTitle'
import Charts from './EChart'

class Hatch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <div className="Hatch ljScreen-item">
                <ScreenTitle title="孵化基地交易额" />
                <Charts echartsInfo={this.props.schoolDealDate} />
            </div>
        )
    }
}

export default Hatch