import React from "react";
import CompetitionBox from "../CompetitionBox";
import { bigNumberTransform, isMobile } from "@/utils/utils";
import EChart from "../EChart";
import { Progress } from 'antd';
import data from "@/view/cloudAhart/Entrepreneurship/data";

const types = {
  studyTrends: {
    title: '课程学习动态',
    title2: '在线学习课程Top5',
  },
  useTrends: {
    title: '实操使用动态',
    title2: '实操学习人数',
  },
}
class SalesVolume extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      option: null,
      optionLine: null,
      isMobile: isMobile(),
      totalPeopleNum: 0,
      list: []
    };
    this.times = null
  }
  shouldComponentUpdate(next, newState) {
    // console.log(next.data);
    if (next.data && !newState.option) {
      this.init(next.data);
      // console.log(next.data);
      this.totalPeopleNum = next.data.totalPeopleNum
      this.setState({
        list: next.data.studyChartsList.map(item => ({ ...item, studyPeopleNum: 0 }))
      }, () => {
        this.initLine(next.data)
      })
      return false;
    }
    return true;
  }
  init = (data) => {
    let { type } = this.props;
    // console.log(data, type);
    let colors = ["#EEB20E", "#07D9E8", "#5B8FF9", "#E71EE8"]
    if (!data) return;
    let isheights = Number(document.body.clientHeight) >= 1024 ? true : false;
    let w1366 = Number(document.body.clientWidth) > 1366;
    let w1600 = Number(document.body.clientWidth) > 1600;
    let h700 = Number(document.body.clientHeight) > 700;
    let h800 = Number(document.body.clientHeight) > 800;
    let h900 = Number(document.body.clientHeight) > 900;
    let arr = [
      { key: 'perfectRate', name: '100%' },
      { key: 'highRate', name: '80%-99%' },
      { key: 'middleRate', name: '60%-79%' },
      { key: 'lowRate', name: '0%-59%' },
    ]
    let list = arr.map((item, index) => {
      return {
        name: item.name,
        value: data[item.key],
        itemStyle: {
          color: colors[index]
        },
      };
    });
    let option = {
      tooltip: {
        trigger: "item",
        formatter: "{b0}: {c0}%",
        borderWidth: 0,
        confine: true
      },
      legend: {
        top: h800 ? "10" : "5",
        bottom: 0,
        type: "scroll",
        right: "5%",
        width: 50,
        height: "100%",
        orient: "vertical",
        icon: "circle",
        itemWidth: 8,
        itemHeight: 14,
        itemGap: 10,
        textStyle: {
          color: "#F2F2F2",
          fontSize: 12,
          width: w1366 ? "130" : 90,
          overflow: "truncate",
        },
        pageIconSize: 10,
        pageIconColor: "#F2F2F2",
        pageIconInactiveColor: "#333",
        pageTextStyle: {
          color: "#F2F2F2",
          overflow: "truncate",
        },
      },
      series: [
        {
          name: "",
          type: "pie",
          avoidLabelOverlap: false,
          center: [h900 ? (h800 ? "50%" : "30%") : "50%", "center"],
          label: {
            show: false,
            position: "center",
          },
          radius: type == 'studyTrends' ? [
            20,
            isheights && w1600
              ? 50
              : h700
                ? w1366
                  ? 40
                  : Number(document.body.clientWidth) < 200
                    ? 20
                    : 40
                : 40,
          ] : isheights ? 50 : 40,
          data: list,
          colors: colors
        },
      ],
    };
    if (this.state.isMobile) {
      option.series[0].center = ["30%", "62%"];
      option.series[0].radius = [30, 60];
    }
    this.setState({
      option,
    });
  };
  initLine = (data) => {
    let arr = data.studyChartsList
    this.times = setInterval(() => {
      const { list } = this.state
      let onoff = true
      let newList = list.map((item, index) => {
        let num = 0
        let max = arr[index].studyPeopleNum
        if (max - item.studyPeopleNum <= 10) {
          num = max
        } else {
          num = item.studyPeopleNum + Math.floor(Math.random() * 5) + 1;
          onoff = false
        }
        let obj = {
          studyName: item.studyName,
          studyPeopleNum: num
        }
        return obj
      })
      // console.log(newList, 'num', this.props.type);
      this.setState({ list: newList })
      if (onoff) clearInterval(this.times)
    }, 200)
  }
  render() {
    let { option, list } = this.state;
    let { type, data } = this.props
    return (
      <CompetitionBox className="salesVolume" title={types[type].title}>
        <div className="bi_echart box_1">
          <h5>完成度占比</h5>
          {
            option && <EChart
              option={option}
              styleS={{ width: "100%", height: "100%" }}
              m_id={"salesVolume" + type}
            />
          }
        </div>
        <div className="bi_echart box_2">
          <h5>{types[type].title2}</h5>
          {
            list.map(item => {
              return (
                <div className="progress" key={item.studyName}>
                  <div className="label">{item.studyName}</div>
                  <div className="line">
                    <div
                      style={{
                        width: `${(item.studyPeopleNum / data.totalPeopleNum) * 100}%`,
                        background: type == 'studyTrends' ? '#EEB20E' : '#07D9E8'
                      }}
                    ></div>
                  </div>
                  <div className="num">{bigNumberTransform(item.studyPeopleNum)}人</div>
                </div>
              )
            })
          }
        </div>
      </CompetitionBox>
    );
  }
}

export default SalesVolume;
