import React from "react";
import ShipBox from "../ShipBox";
import Slider from "react-slick";
import { Tooltip, Tag, Space } from "antd";

class ShopLog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      table: [
        {
          date: "2022-11-23  11:32",
          name: "人人内容",
        },
        {
          date: "2022-11-23  11:32",
          name: "人人内容",
        },
        {
          date: "2022-11-23  11:32",
          name: "人人内容",
        },
        {
          date: "2022-11-23  11:32",
          name: "人人内容",
        },
        {
          date: "2022-11-23  11:32",
          name: "人人内容",
        },
        {
          date: "2022-11-23  11:32",
          name: "人人内容",
        },

        {
          date: "2022-11-23  11:32",
          name: "人人内容",
        },
        {
          date: "2022-11-23  11:32",
          name: "人人内容",
        },
        {
          date: "2022-11-23  11:32",
          name: "人人内容",
        },
        {
          date: "2022-11-23  11:32",
          name: "人人内容",
        },
        {
          date: "2022-11-23  11:32",
          name: "人人内容",
        },
      ],
      valueKey: "",
      nav: [],
    };
  }
  // shouldComponentUpdate(next, newState) {
  //   if (next.data && !newState.option) {
  //     let valueKey = "";
  //     this.setState({
  //       valueKey: valueKey,
  //     });
  //     return false;
  //   }
  //   return true;
  // }
  shiponChange = (key) => {
    this.setState({
      valueKey: key,
    });
    this.props.shiponChange(key);
  };

  render() {
    const { valueKey, nav } = this.state;
    let { data, storeOption } = this.props;
    const settings = {
      dots: false,
      infinite: data.length > 3 ? true : false, //循环
      arrows: false,
      slidesToShow: 3,
      slidesToScroll: 1,
      vertical: true,
      verticalSwiping: false,
      autoplay: true,
      speed: 2000,
      autoplaySpeed: 1000,
      beforeChange: (currentSlide, nextSlide) => {
        // console.log("before change", currentSlide, nextSlide);
      },
      afterChange: function (currentSlide) {
        // console.log("after change", currentSlide);
      },
    };
    let w1366 = Number(document.body.clientWidth) > 1366;
    return (
      <ShipBox
        isSelect={true}
        title="店铺记录数据"
        selectId="ShopLog_id"
        className="ShopLog Entrepreneurship_box"
        value={valueKey}
        options={storeOption}
        shiponChange={this.shiponChange}
      >
        <Slider {...settings}>
          {data.map((item, index) => {
            return (
              <div key={index} className="College-table-th">
                <div className="College-table-td" style={{ width: "35%" }}>
                  {item.salesTime}
                </div>
                <div
                  className="College-table-td"
                  style={{ width: "25%", paddingRight: 3 }}
                >
                  <Tooltip
                    overlayStyle={{ width: "auto" }}
                    title={item.storeName}
                  >
                    {item.storeName}
                  </Tooltip>
                </div>
                <div
                  className="College-table-td"
                  style={{
                    width: "45%",
                    color: "#01F1F8",
                    textAlign: "left",
                    marginLeft: w1366 ? "5%" : "0px",
                  }}
                >
                  {item.content}
                </div>
              </div>
            );
          })}
        </Slider>
      </ShipBox>
    );
  }
}

export default ShopLog;
