import React, { useEffect } from "react";
import "../../index.css";
import { Table } from 'antd';
import EChart from "./EChart";
import CountUp from "react-countup";

export default class Map extends React.Component {
  constructor(props) {
    super(props);
  }
  columns = () => {
    return [
      {
        title: '省份',
        dataIndex: 'province',
        render: (text, row, index) => {
          return `${index + 1}.${text}`
        }
      },
      {
        title: '启用次数',
        dataIndex: 'openCount',
      },
      {
        title: '占比',
        dataIndex: 'ratio',
        width: "10%",
        render: (text, row, index) => {
          return `${text}%`
        }
      },
    ]
  }
  render() {
    let { allCityData } = this.props;
    // console.log(allCityData);
    let num = String(allCityData?.extend);
    return (
      <div className="Entrepreneurship_Map">
        <h2>
          <span className="title_map" style={{ marginRight: 10 }}>系统启用次数统计：</span>
          <span>
            {allCityData
              ? num.split("").map((item, index) => {
                if (Number(item)) {
                  return (
                    <CountUp
                      className="sums"
                      end={Number(item)}
                      key={index}
                    ></CountUp>
                  );
                } else {
                  return (
                    <span key={index} className="sums">
                      {item}
                    </span>
                  );
                }
              })
              : ""}
          </span>
        </h2>
        <div className="Entrepreneurship_Map_body">
          <EChart allData={allCityData} />
        </div>
        {
          allCityData && allCityData.data && <Table className="SMap-table" columns={this.columns()} rowKey="province" dataSource={allCityData && allCityData.data} pagination={false} />
        }
      </div>
    );
  }
}
