import React from "react";
import EChart from './EChart';
import { Table } from 'antd';
import ShipBox from "../ShipBox";
import CountUp from 'react-countup'
import {
    bigNumberTransform,
    isMobile
} from "@/utils/utils";

class SMap extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile: isMobile()
        };
    }
    columns = () => {
        return [
            {
                title: '省份',
                dataIndex: 'province',
                render: (text, row, index) => {
                    return `${index + 1}.${text}`
                }
            },
            {
                title: '金额',
                dataIndex: 'salesVolume',
                render: (text) => {
                    return bigNumberTransform(text)
                }
            },
            {
                title: '占比',
                dataIndex: 'ratio',
                width: "10%",
                render: (text, row, index) => {
                    return `${text}%`
                }
            },
        ]
    }

    render() {
        const { allCityData } = this.props
        let num = allCityData ? String(allCityData.total) : ''
        const { isMobile } = this.state
        // console.log(num.split(''))
        return (
            <div className="SMap Entrepreneurship_cnMap">
                <div className="SMap-title">
                    {/* <p>同比增长 <br /> {allCityData && allCityData.nowRatio}</p> */}
                    <div className="SMap-num">
                        {
                            num.split('').map((item, index) => {
                                if (Number(item)) {
                                    return (
                                        <CountUp
                                            className="sums"
                                            end={Number(item)}
                                            key={index}
                                        ></CountUp>
                                    );
                                } else {
                                    return (
                                        <span key={index} className="sums">
                                            {item}
                                        </span>
                                    );
                                }
                            })
                        }
                    </div>
                </div>

                <EChart allCityData={allCityData} />
                {
                    isMobile ? <ShipBox className="Entrepreneurship_box SMap-table">
                        <Table className="SMap-table" locale={{ emptyText: " " }} columns={this.columns()} rowKey="province" dataSource={allCityData && allCityData.list} pagination={false} />
                    </ShipBox> : (
                        <Table className="SMap-table" locale={{ emptyText: " " }} columns={this.columns()} rowKey="province" dataSource={allCityData && allCityData.list} pagination={false} />
                    )
                }
            </div>
        )
    }
}

export default SMap