import { storage } from "@/utils/dom";
function menufunction() {
  const teacherUserInfo = storage.parse().get("teacherUserInfo");
  let menuList = [
    {
      notRenderChild: true,
      icon: "iconteaching-center",
      text: "控制台",
      index: 0,
      path: "/teachingCenter",
    },
  ];

  if (teacherUserInfo) {
    // console.log(teacherUserInfo.teacherPermission);
    for (let key in teacherUserInfo.teacherPermission) {
      // 课程管理
      if (key == 1) {
        menuList.push({
          icon: "icon-kechengguanli",
          text: "课程管理",
          index: 4,
          path: "/courseManage",
          children: [
            {
              text: "课程列表",
              path: "/courseList",
            },
          ],
        });
      }
      // 实训教学
      if (key == 3) {
        menuList.push({
          icon: "icontraining-tracking",
          text: "实训管理",
          index: 2,
          path: "/teaching",
          children: [
            {
              text: "申请实训",
              path: "/trainApplication",
              itemIndex: 0,
              children: [
                {
                  text: "立即申请",
                  path: "/applyNow",
                },
                {
                  text: "查看详情",
                  path: "/trainDetail",
                },
              ],
            },
            {
              text: "我的申请",
              itemIndex: 1,
              path: "/myTrainingApplication",
            },
            {
              text: "实训教学",
              itemIndex: 4,
              path: "/trainTeaching",
              // authentication: true,
              children: [
                {
                  text: "实训统计",
                  path: "/overallProgress",
                  children: [
                    {
                      text: "实训详情",
                      path: "/studentTrainingDetail",
                    },
                  ],
                },
                {
                  text: "作业列表",
                  path: "/passignment",
                  children: [
                    {
                      text: "批改作业",
                      path: "/passignmentDetail",
                    },
                  ],
                },
                {
                  text: "考核列表",
                  path: "/assess",
                  children: [
                    {
                      text: "批改考核",
                      path: "/assessDetail",
                    },
                  ],
                },
                {
                  text: "实操任务",
                  path: "/practice",
                  children: [
                    {
                      text: "实操任务详细",
                      path: "/practiceDetails",
                    },
                    {
                      text: "实训详情",
                      path: "/studentTrainingDetail",
                    },
                  ],
                },
                {
                  text: "编辑排课",
                  path: "/schedule",
                },
                {
                  text: "编辑视频",
                  path: "/editVideo",
                },
                {
                  text: "上传任务",
                  path: "/uploadTask",
                },
                {
                  text: "扫码签到",
                  path: "/sign",
                },
              ],
            },
          ],
        });
      }
      // 实训管理
      if (key == 4) {
        menuList.map((item, index) => {
          if (item.text == "实训管理") {
            menuList[index].children.push({
              text: "实训审核",
              itemIndex: 3,
              path: "/trainingReview",
              children: [
                {
                  text: "配置实训信息",
                  path: "/configureInfos",
                },
              ],
            });
          }
        });
      }
      // 教师查看
      if (key == 5) {
        menuList.push({
          icon: "iconjh-tracking",
          text: "教师管理",
          index: 6,
          path: "/teacherManagement",
          children: [
            {
              text: "教师列表",
              path: "/teacherList",
              itemIndex: 0,
              children: [
                {
                  text: "修改教师",
                  path: "/upTeacher",
                },
              ],
            },
          ],
        });
      }
      // 教师管理
      if (key == 6) {
        menuList.map((item, index) => {
          if (item.text == "教师管理") {
            menuList[index].children.push({
              text: "新增教师",
              itemIndex: 1,
              path: "/addTeacher",
            });
          }
        });
      }

      // 学生查看
      if (key == 7) {
        menuList.push({
          // notRenderChild:true,
          // icon: 'icon-xueshengguanli',
          icon: "iconstudent-management",
          text: "学生管理",
          index: 1,
          path: "/studentManagement",
          children: [
            {
              text: "学生列表",
              itemIndex: 0,
              path: "/studentList",
              children: [
                {
                  text: "详情",
                  path: "/studentInfo",
                },
              ],
            },
            {
              text: "学生简历",
              itemIndex: 2,
              path: "/studentResume",
            },
          ],
        });
      }
      // 学生管理
      if (key == 8) {
        menuList.map((item, index) => {
          if (item.text == "学生管理") {
            menuList[index].children.push(
              {
                text: "学生审核",
                itemIndex: 1,
                path: "/studentProcess",
                children: [
                  {
                    text: "详情",
                    path: "/studentInfo",
                  },
                  {
                    text: "学生信息审核",
                    path: "/process",
                  },
                ],
              },
              {
                text: "新增学生",
                itemIndex: 3,
                path: "/addStudent",
              },
              {
                text: "学生导入",
                itemIndex: 4,
                path: "/importStudent",
              },
              {
                text: "就业导入",
                itemIndex: 5,
                path: "/importEmployment",
              }
            );
          }
        });
      }
      // 班级查看
      if (key == 9) {
        menuList.push({
          icon: "iconclass-tracking",
          text: "班级管理",
          index: 5,
          path: "/classManagement",
          children: [
            {
              text: "班级列表",
              itemIndex: 0,
              path: "/classList",
              children: [
                {
                  text: "班级详情",
                  path: "/classDetails",
                },
                {
                  text: "修改班级",
                  path: "/upClass",
                },
              ],
            },
          ],
        });
      }
      // 班级管理
      if (key == 10) {
        menuList.map((item, index) => {
          if (item.text == "班级管理") {
            menuList[index].children.push({
              text: "新增班级",
              itemIndex: 1,
              path: "/addClass",
            });
          }
        });
      }
      // 实操管理
      if (key == 16) {
        menuList.push({
          icon: "iconclass-practice",
          text: "实操管理",
          index: 3,
          path: "/practicalManagement",
          children: [
            {
              text: "亚马逊实操",
              itemIndex: 0,
              path: "/practiceList",
              children: [
                {
                  text: "实操详情",
                  path: "/practiceDetails",
                },
              ],
            },
          ],
        });
      }
    }
  }

  menuList.sort((a, b) => {
    return a.index - b.index;
  });
  menuList = menuList.map((item) => {
    if (!item.children) return item;
    item.children.sort((a, b) => {
      return a.itemIndex - b.itemIndex;
    });
    return item;
  });
  // if (menuList[2]) {
  //     menuList[2].children.sort((a, b) => {
  //         return a.itemIndex - b.itemIndex
  //     })
  // }
  // console.log(menuList);
  return menuList;
}

export default menufunction;
