import React from "react";
import ScreenTitle from '../ScreenTitle';
import Charts from './EChart';
import { Select } from 'antd';

const { Option } = Select;

class UsersNum extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            svalue: ''
        };
    }
    onChange = (e) => {
        this.setState({
            svalue: e
        })
        this.props.getStoreSalesData(e)
    }

    render() {
        const { schoolName, storeSalesData } = this.props
        const { svalue } = this.state
        let defaultValue = svalue ? svalue : schoolName.length > 0 && schoolName[0]
        return (
            <div className="UsersNum ljScreen-item" id="user-sel">
                <ScreenTitle title="店铺销售数据" />
                <Select value={defaultValue} onChange={this.onChange} getPopupContainer={() => document.getElementById('user-sel')}  className="user-sel" dropdownClassName="user-sel">
                    {
                        schoolName.map(item => {
                            return <Option value={item} key={item}>{item}</Option>
                        })
                    }

                </Select>
                <Charts echartsInfo={storeSalesData} />
            </div>
        )
    }
}

export default UsersNum