import React from "react";
import AJAX from "@/api/api";
import "./index.css";
import "../borderCommon.css";
import { Progress } from "antd";
import EChartsData from './eCharts'
import { bigNumberTransform } from '@/utils/utils';
export default class realTime extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      echartsInfo: null,
      data: [],
      heights: this.props.heights
    };
  }
  componentWillMount() {
    this.getRealTimeDynamicInfo()
  }
  componentDidMount() {
  }
  componentWillReceiveProps = (newProps) => {
    this.setState({
      heights: newProps.heights
    })
  }
  getRealTimeDynamicInfo = () => {
    let { data } = this.state;
    let echartsInfo = {}
    AJAX.cloudChart.getRealTimeDynamicInfo().then((res) => {
      if (res.code == 1) {
        data = res.data
        echartsInfo.coursePeopleNum = data.coursePeopleNum
        echartsInfo.employedPeopleNum = data.employedPeopleNum
        echartsInfo.trainPeopleNum = data.trainPeopleNum
        echartsInfo.usePeopleNum = data.usePeopleNum
        this.setState({
          data: data,
          echartsInfo: echartsInfo
        })
      }
    })
  }
  render() {
    let { data, echartsInfo, heights } = this.state
    let blockHeight = heights - 80 - 75
    return (
      <div style={{ height: `${heights}px` }} className="realTimeBox borderShare">
        <div className="pos-abs"></div>
        <div className="pos-abs"></div>
        <div className="pos-abs"></div>
        <div className="pos-abs"></div>
        <div className="borderTitle">实时动态</div>
        <div>
          <EChartsData heights={blockHeight / 2} echartsInfo={echartsInfo} />
        </div>
        <div>
          <div className="realTitle">
            <span className="colorBlock"></span>
            <span>在线学习课程Top5</span>
          </div>
          <div className="studyInfo">
            <div>
              在线学习人数：
              <span className="reds">{data.totalStudyPeopleNum}人</span>
            </div>|
            <div>
              在线学习章节数：
              <span className="reds">{data.totalStudyCourseHourNum}节</span>
            </div>
          </div>
          <div style={{ height: `${blockHeight / 2}px` }} className="progressBox">
            {data.trainChartsVoList ? data.trainChartsVoList.map((item, index) => {
              return <div key={index} className="progressItem">
                <span>{item.trainName}</span>
                <Progress
                  strokeColor={{
                    from: '#0C52F2',
                    to: '#00EAFF',
                  }}
                  trailColor="#1f326d"
                  percent={Number(item.studyPeopleNum) / Number(data.totalStudyPeopleNum) * 100}
                  showInfo={false}
                  strokeWidth={8} />
                <span>{bigNumberTransform(item.studyPeopleNum)}人</span>
              </div>
            }) : null}

          </div>
        </div>
      </div>
    );
  }
}
