import React, { useEffect } from "react";
import * as echarts from "echarts";

export default ({ option, m_id, styleS }) => {
  useEffect(() => {
    if (!option) return;
    let mapChart = echarts.init(document.getElementById(m_id));
    mapChart.setOption(option);
  }, [option, m_id]);
  return <div id={m_id} style={styleS}></div>;
};
