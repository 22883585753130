import React, { useState, useCallback, useEffect, useRef } from "react";

export default function Empty({ info, text }) {
    const [txt, setTtxt] = useState('比赛未开始')
    useEffect(() => {
        if (info) {
            let t = ''
            const currentTime = new Date().getTime();
            const s = new Date(info.startTime).getTime()
            const e = new Date(info.endTime).getTime()
            if (currentTime < s) {
                t = '比赛未开始'
            }
            if (currentTime > e) {
                t = '比赛结束'
            }
            setTtxt(t)
        }
    }, [info])
    return (
        <div className="e_commerce_empty">
            <img src={require(`@/assets/images/cloudChart/Competition/empty.png`)} alt="" />
            <p>{text || txt}</p>
        </div>
    )
}