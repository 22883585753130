import React from "react";
import ScreenTitle from '../ScreenTitle'
import EChartsData from './eCharts'
import './index.css'

class EmploymentStatistics extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        let { skuCategoryDate } = this.props;
        let onSale = skuCategoryDate ? skuCategoryDate.onSale : 0;
        let list = skuCategoryDate ? skuCategoryDate.list : [];
        return (
            <div className="EmploymentStatistics ljScreen-item">
                <div className="df">
                    <ScreenTitle title="SKU品类分布" />
                    <div className="onSale">
                        <span>在售SKU：</span>
                        <span>{onSale}个</span>
                    </div>
                </div>
                <EChartsData list={list} />

            </div>
        )
    }
}

export default EmploymentStatistics