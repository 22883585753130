import React, {
  useEffect,
  useRef,
  useMemo,
  useState,
  useCallback,
} from "react";
import "./index.css";
import * as echarts from 'echarts';
import { set } from "nprogress";
// import DisableGraph from "./../DisableGraph";

const initXAxisData = () => [];
const initOptions = () => ({
  color: ["#0C52F2", "#0FBB48", "#EC6C49", "#04D2F7", "#E5C222", "#28BBAC", "#ED7E7A"],
  tooltip: {
    trigger: "item",
    formatter: "{b}",
  },
});
export default ({ echartsInfo,heights = 220 }) => {
  let isheights = (Number(document.body.clientHeight) >= 1080) ? true : false
  let container = useRef(null);
  let echart = useRef(null);
  let colors = useMemo(() => ["#0C52F2", "#0FBB48", "#EC6C49", "#04D2F7", "#E5C222", "#28BBAC", "#ED7E7A"], []);
  let [xAxisData, setXAxisData] = useState(initXAxisData);
  let [options, setOptions] = useState(initOptions);
  let [echartsheights, setEchartsheights] = useState(heights);

  const handleReset = useCallback(() => {
    setOptions(initOptions());
    setXAxisData(initXAxisData());
  }, []);

  useEffect(() => {
    setEchartsheights(isheights ? heights * 0.52 : heights * 0.45)
    if (echartsInfo) {
      let data = [], XAxisDataArr = []
      for (let item of echartsInfo) {
        data.push({ value: item.peopleNum == 0 ? 1 : item.peopleNum, name: `${item.processName}：${item.peopleNum}人` })
        XAxisDataArr.push(item.processName)
      }
      setXAxisData(XAxisDataArr);
      setOptions((options) => {
        return {
          series: [
            {
              name: '面积模式',
              type: 'pie',
              // height: heights * 0.5,
              radius: [20, isheights ? 90 : Number(document.body.clientHeight) >= 768 ? 70 : 60],
              center: [Number(document.body.clientWidth) > 375 ? '40%' : '50%', isheights ? '45%' : '60%'],
              roseType: 'radius',
              label: {
                show: false
              },
              top: isheights ? 50 : -25,
              data: data
            }
          ]
        };
      });
    } else {
      handleReset();
    }
  }, [echartsInfo, heights, handleReset]);

  useEffect(() => {
    if (!echart.current) echart.current = echarts.init(container.current);
    echart.current.resize({ height: Number(document.body.clientWidth) > 375 ? echartsheights : 150 })
    echart.current.setOption(options);
  }, [options, echartsheights]);

  return (
    <div className="analysisECharts">
      <div style={{ height: `${heights}px` }} className='blueWhaleEChart blueWhaleEChart1'>
        <div
          ref={container}
          className="container container1"
          style={{ display: echartsInfo ? "block" : "none" }}
          style={{ height: `${echartsheights}px` }}

        ></div>
        <div className="list">
          {colors.map((item, index) => {
            if (index < xAxisData.length) {
              return (
                <p key={index}>
                  <span
                    style={{ backgroundColor: colors[index] }}
                  ></span>
                  <span className="text-ellipsis-1">{`${xAxisData[index]}`}</span>
                </p>
              );
            }
          })}
        </div>
      </div>
    </div>
  );
};
