import React from "react";
import "./index.css";
import "../borderCommon.css";
import Slider from "react-slick";
import AJAX from "@/api/api";
import { Tooltip } from "antd";
import { sum } from "lodash";

export default class courseNews extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      classroomNewList: [],
      heights: this.props.heights,
      newsInfoId: null,
      pageNum: 1,
      szlsa:
        window.location.href.includes("szlsa.513.com") ||
        window.location.href.includes("sctu.513online.top"),
    };
  }

  componentDidMount() {
    this.getClassroomNews();
    setInterval(() => {
      this.getClassroomNews();
    }, 13000);
  }
  componentWillReceiveProps = (newProps) => {
    this.setState({
      heights: newProps.heights,
    });
  };
  getClassroomNews = () => {
    AJAX.cloudChart
      .getClassroomNews({ newsInfoId: this.state.newsInfoId })
      .then((res) => {
        if (res.code == 1 && res.data) {
          let sums = 1,
            arr = [];
          for (let items of res.data) {
            if (sums > 4) {
              sums = 1;
              arr.push({
                classNames: `combination${sums}`,
                texts: items.newsInfo,
              });
              sums = sums + 1;
            } else {
              arr.push({
                classNames: `combination${sums}`,
                texts: items.newsInfo,
              });
              sums = sums + 1;
            }
          }
          this.setState({
            classroomNewList: this.state.classroomNewList.concat(arr),
            newsInfoId: res.extend,
          });
        }
      });
  };
  render() {
    let { classroomNewList, heights, szlsa } = this.state;
    const settings = {
      dots: false,
      infinite: false,
      arrows: false,
      slidesToShow:
        heights >= 420
          ? 4
          : 3,
      slidesToScroll: 1,
      vertical: true,
      verticalSwiping: false,
      autoplay: true,
      speed: 2000,
      autoplaySpeed: 1000,
      beforeChange: (currentSlide, nextSlide) => {
        // console.log("before change", currentSlide, nextSlide);
      },
      afterChange: function (currentSlide) {
        // console.log("after change", currentSlide);
      },
    };
    return (
      <div
        style={{ height: `${heights}px` }}
        className="courseNewsBox borderShare"
      >
        <div className="pos-abs"></div>
        <div className="pos-abs"></div>
        <div className="pos-abs"></div>
        <div className="pos-abs"></div>
        <div className="borderTitle">课堂动态</div>
        <div
          style={{ height: `${heights / 2 - 10}px` }}
          className="curriculumImg"
        >
          <div className="leftTopClass">
            <img src={require("@/assets/images/cloudChart/jingxue.png")} />
            <span>{szlsa ? "学习" : "鲸学"}</span>
          </div>
          <div className="rightTopClass">
            <img src={require("@/assets/images/cloudChart/jinglian.png")} />
            <span>{szlsa ? "练习" : "鲸练"}</span>
          </div>
          <div className="leftBottomClass">
            <img src={require("@/assets/images/cloudChart/jingping.png")} />
            <span>{szlsa ? "供应链" : "鲸品"}</span>
          </div>
          <div className="rightBottomClass">
            <img src={require("@/assets/images/cloudChart/jingpin.png")} />
            <span>{szlsa ? "就业" : "鲸聘"}</span>
          </div>
        </div>
        <Slider {...settings} style={{ height: `${heights / 2 - 30}px` }}>
          {classroomNewList
            ? classroomNewList.map((item, index) => {
                return (
                  <div key={index}>
                    <Tooltip color="#FFF" title={item.texts}>
                      <div className={`rollPublic ${item.classNames}`}>
                        {item.texts}
                      </div>
                    </Tooltip>
                    <div style={{ height: "8px" }}></div>
                  </div>
                );
              })
            : null}
        </Slider>
      </div>
    );
  }
}
