import React from "react";
import CompetitionBox from "../CompetitionBox";

class Issue extends React.Component {
  render() {
    let { data } = this.props;
    return (
      <CompetitionBox className="issue" title="学校出单榜">
        <div className="Entrepreneurship_Group_list">
          {data &&
            data.map((item, index) => {
              return (
                <div key={index} className="Entrepreneurship_Group_item">
                  <div className="name">
                    <img
                      className="logo"
                      src={
                        item.transparentIco ||
                        require(`@/assets/images/teaching/defaultImg.png`)
                      }
                    ></img>
                    {item.schoolName}
                  </div>
                  <div className="value">
                    {item.issueNum} 单
                    <div className="jz" style={{ color: "#F90D4D" }}>
                      {index < 3 ? (
                        <img
                          src={require(`@/assets/images/cloudChart/Competition/list_${
                            index + 1
                          }.png`)}
                        ></img>
                      ) : (
                        index + 1
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </CompetitionBox>
    );
  }
}

export default Issue;
