import React from "react";
import CompetitionBox from "../CompetitionBox";

class Issue extends React.Component {
  render() {
    let { data } = this.props;
    return (
      <CompetitionBox className="issue" title="创业出单榜">
        <div className="Entrepreneurship_Group_list">
          {data &&
            data.map((item, index) => {
              return (
                <div key={index} className="Entrepreneurship_Group_item">
                  <div className="name">
                    <div className="logo">
                      <img
                        src={
                          item.headPortrait ||
                          require(`@/assets/images/teaching/defaultImg_2.jpg`)
                        }
                      ></img>
                    </div>
                    {item.storeName}
                  </div>
                  <div className="value">
                    {item.issueNum} 单
                    <div className="jz" style={{ color: "#02EEFF" }}>
                      {index < 3 ? (
                        <img
                          src={
                            require(`@/assets/images/cloudChart/bi/list_${index + 1}.png`)
                          }
                        ></img>
                      ) : (
                        index + 1
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </CompetitionBox>
    );
  }
}

export default Issue;
