import { storage } from "@/utils/dom";
import Ajax from "./request";

function connect(params) {
  let url = `?`;
  for (let key in params) {
    url = `${url}${key}=${params[key]}&`;
  }
  return url;
}

export default {
  login: {
    signIn(params) {
      // 登录
      return Ajax.ajaxs("/user/login", params);
    },
    simulatedUserLogin(params) {
      // 登录
      return Ajax.ajaxs("/user/simulatedUser", params, "GET");
    },
    signUp(params) {
      // 注册
      return Ajax.ajaxs("/user/register", params);
    },
    getBackPassword(params) {
      // 找回密码
      return Ajax.ajaxs("/user/reset/password", params);
    },
    getCodeImgUrl() {
      // 图片验证码
      return Ajax.ajaxs("/verifyCode", {}, "GET");
    },
    getPhoneCode(params) {
      //短信验证码
      return Ajax.ajaxs("/sendMobileCode", params);
    },
    logOut() {
      //退出登录
      return Ajax.ajaxs("/user/logout", {});
    },
    exitSimulation() {
      //退出模拟
      return Ajax.ajaxs("/user/exitSimulation", {}, "GET");
    },
    getUserInfo(key = "") {
      //获取用户信息
      return Ajax.ajaxs(`/user/getUserInfo?sessionKey=${key}`, {}, "GET").then(
        (res) => {
          if (res.code === 1) {
            document.title = res.data.title || res.data.schoolName;
            storage.stringify().set("teacherUserInfo", res.data);
          }
          return res;
        }
      );
    },
  },
  center: {
    getTeacherTask() {
      //教师中心日历任务
      return Ajax.ajaxs("/center/getTeacherTask", {}, "GET");
    },
    getTeacherTrainClass() {
      //获取老师下的实训班级
      return Ajax.ajaxs("/center/queryClass", {}, "GET");
    },
    getTrainByClassId(classId) {
      //根据学校班级Id查询对应实训
      return Ajax.ajaxs(`/center/queryTrain?classId=${classId}`, {}, "GET");
    },
    getTeachingStatistics({ classId, trainId }) {
      //获取学校班级的某一实训的教学统计
      return Ajax.ajaxs(
        `/center/getTeachingStatistics?classId=${classId}&trainId=${trainId}`,
        {},
        "GET"
      );
    },
    getConsolAllInfo() {
      return Ajax.ajaxs(`/consol/getConsolAllInfo`, {}, "GET");
    },
  },
  manager: {
    selectCityInfo(params) {
      //获取城市接口
      return Ajax.ajaxs("/tag/selectCityInfo", params, "GET");
    },
    addStudent(params) {
      //添加学生
      return Ajax.ajaxs("student/add", params);
    },
    checkPhoneRepeat(params) {
      //校验手机号是否被注册
      return Ajax.ajaxs("student/checkPhoneRepeat", params, "GET");
    },
    getStudentList(params) {
      //学生列表
      return Ajax.ajaxs("/student/list", params);
    },
    getStudentListByClassId(params) {
      //根据班级查找学生
      return Ajax.ajaxs("/class/getSchoolClassAllStudentInfoList", params);
    },
    queryStudentResumeDeliveryViewedList(params) {
      //根据班级查找学生
      return Ajax.ajaxs(
        "/studentResume/queryStudentResumeDeliveryViewedList",
        params,
        "GET"
      );
    },
    getStudentDetail(userId) {
      //单个学生信息
      return Ajax.ajaxs(`/student/detail/${userId}`, {}, "GET");
    },
    reviseStudentInfo(params) {
      //修改学生信息
      return Ajax.ajaxs(`/student/update`, params);
    },
    queryStudentResumeList(params) {
      //查询学生简历列表
      return Ajax.ajaxs(`/studentResume/queryStudentResumeList`, params, "GET");
    },
    getResumeShareKey(params) {
      //查看简历获取key
      return Ajax.ajaxs(
        `/employment/resume/share/getResumeShareKey`,
        params,
        "GET"
      );
    },
    updateResumeStatus(params) {
      //修改学生简历状态
      return Ajax.ajaxs(
        `/studentResume/updateResumeStatus?resumeId=${params.resumeId}&resumeStatus=${params.resumeStatus}`,
        params,
        "PUT"
      );
    },
    getStudentResumeInfo(params) {
      //获取学生简历信息
      return Ajax.ajaxs(`/studentResume/getStudentResumeInfo`, params, "GET");
    },
    getAllSchool() {
      //学校列表
      return Ajax.ajaxs("/school/list/all", {}, "GET");
    },
    getSchoolAllFaculty(params) {
      //学校所有系
      return Ajax.ajaxs("/school/faculty/list/teacher", params);
    },
    getSchoolAllMajor(params) {
      //学校所有专业
      return Ajax.ajaxs("/school/major/list", params);
    },
    getSchoolAllClass(params) {
      //学校所有班级
      return Ajax.ajaxs("/school/class/list", params);
    },
    getCurTeacherFaculty(params) {
      //当前老师下所有系
      return Ajax.ajaxs("/school/faculty/list/teacher/class", params);
    },
    // 新增院系
    addFaculty(params) {
      return Ajax.ajaxs("/school/addFaculty", params, "POST");
    },
    // 新增专业
    addMajor(params) {
      return Ajax.ajaxs("/school/addMajor", params, "POST");
    },
    getCurTeacherMajor(params) {
      //当前老师下所有专业
      return Ajax.ajaxs("/school/major/list/teacher", params);
    },
    getCurTeacherClass(params) {
      //当前老师下所有班级
      return Ajax.ajaxs("/school/class/list/teacher", params);
    },
    queryStudentAuditList(params) {
      //查询学生审核列表
      return Ajax.ajaxs("/audit/queryStudentAuditList", params, "GET");
    },
    getStudentAuditInfo(params) {
      //查询学生审核详情信息
      return Ajax.ajaxs("/audit/getStudentAuditInfo", params, "GET");
    },
    updateStudentAuditInfo(params) {
      //更新学生审核信息
      return Ajax.ajaxs("/audit/updateStudentAuditInfo", params, "PUT");
    },
    batchUpdateStudentAuditInfo(params) {
      //批量更新学生审核信息
      return Ajax.ajaxs("/audit/batchUpdateStudentAuditInfo", params, "PUT");
    },
    getBehaviorLogByUserId(params) {
      //查询学生日志
      return Ajax.ajaxs("/user/getBehaviorLogByUserId", params, "GET");
    },
  },
  teaching: {
    getTrainClassInfo(params) {
      // 获取实训班级信息
      return Ajax.ajaxs("/statistics/getTrainClassInfo", params, "GET");
    },
    getRecommendedPracticeList(params) {
      // 根据类别id获取对应的推荐实训
      return Ajax.ajaxs("/train/recommends", params);
    },
    getApplyTrainList(params) {
      // 申请实训列表
      return Ajax.ajaxs("/train/queryTrainList", params, "GET");
    },
    getMyTrainingApplication(params) {
      // 我的实训申请
      return Ajax.ajaxs("/train/apply/studentList", params, "GET");
    },
    applyNow(params) {
      // 实训申请
      return Ajax.ajaxs("/train/manager/apply", params);
    },
    getStudentTrainScoreList(params) {
      // 获取学生成绩排名列表
      return Ajax.ajaxs("/statistics/getStudentTrainScoreList", params, "GET");
    },
    getStoreTemplateOption(params) {
      // 获取店铺模板选项
      return Ajax.ajaxs("/train/getStoreTemplateOption", params, "GET");
    },
    getPracticeAnalysisChart(params) {
      // 获取实训班级实操统计分析图
      return Ajax.ajaxs("/statistics/getPracticeAnalysisChart", params, "GET");
    },
    getPracticeTaskDetails(params) {
      // 获取实训班级实操任务详细信息
      return Ajax.ajaxs("/statistics/getPracticeTaskDetails", params, "GET");
    },
    studentPracticeScoreList(params) {
      // 获取实训班级实操成绩列表
      return Ajax.ajaxs("/statistics/studentPracticeScoreList", params, "GET");
    },
    getTrainDetail({ id }) {
      // 实训详情
      // return Ajax.ajaxs(`/train/detail/${params.id}`, {},'GET');
      return Ajax.ajaxs(`/train/getTrainDetails?trainId=${id}`, {}, "GET");
    },
    getTrainList(params) {
      //获取实训列表
      return Ajax.ajaxs("/train/trainList", params, "GET");
    },
    getManagerList(params) {
      //获取学生作业列表
      let url = `/work/manager/answer/list?`;
      for (let key in params) {
        url = `${url}${key}=${params[key]}&`;
      }
      return Ajax.ajaxs(url, {}, "GET");
    },
    workMark(params) {
      //学生作业评分/批改
      return Ajax.ajaxs("/work/manager/answer/update", params);
    },

    examinationMark(params) {
      //学生考核评分/批改
      return Ajax.ajaxs("/examination/manager/answer/update", params);
    },
    whetherWorkAllDownload(params) {
      //学生作业是否有可用下载
      return Ajax.ajaxs("/work/manager/checkDownloadFlag", params, "GET");
    },
    workAllDownload(trainMergeId, trainId, workId) {
      //学生作业全部下载
      return Ajax.downloadALL(
        `/work/manager/batch/download/workAnswer?trainMergeId=${trainMergeId}&trainId=${trainId}&workId=${workId}`
      );
    },
    whetherExaminationAllDownload(params) {
      //学生考核是否有可用下载
      return Ajax.ajaxs(
        "/examination/manager/checkDownloadFlag",
        params,
        "GET"
      );
    },
    examinationAllDownload(trainMergeId, trainId, examinationId) {
      //学生考核全部下载
      return Ajax.downloadALL(
        `/examination/manager/batch/download/examinationAnswer?trainMergeId=${trainMergeId}&trainId=${trainId}&examinationId=${examinationId}`
      );
    },
    getExaminationManagerList(params) {
      //获取学生考核列表
      let url = `/examination/manager/answer/list?`;
      for (let key in params) {
        url = `${url}${key}=${params[key]}&`;
      }
      return Ajax.ajaxs(url, {}, "GET");
    },
    examinationkMark(params) {
      //学生考核评分/批改
      return Ajax.ajaxs("/examination/manager/answer/update", params);
    },
    getTrainTeacherApplyList(params) {
      //实训老师申请列表
      return Ajax.ajaxs("/train/apply/trainStudentList", params, "GET");
    },
    checkApplyTrainGetTeacherList(params) {
      //检查合并实训 返回老师信息
      return Ajax.ajaxs("/train/checkApplyTrainGetTeacher", params);
    },
    combinedTraining(params) {
      //合并实训
      return Ajax.ajaxs("/train/merge", params);
    },
    rejectAudit(params) {
      //拒绝审核
      return Ajax.ajaxs("/train/refuse", params, "PUT");
    },
    getSchedulelist(params) {
      //根据实训班级id查询排期
      return Ajax.ajaxs(`/train/trainChapterScheduling/${params}`, {}, "GET");
    },
    downLoadTest(params) {
      //下载测试接口
      return Ajax.ajaxs(
        `/work/manager/batch/download/workAnswer/test`,
        {},
        "GET"
      );
    },
    addTrainChapterScheduling(params) {
      //添加实训排期
      return Ajax.ajaxs(`/train/addTrainChapterScheduling`, params);
    },
    getTrainChapterInfo(trainId, trainClassId = "") {
      //实训章节
      return Ajax.ajaxs(
        `/trainChapter/getChapterInfo/${trainId}?trainClassId=${trainClassId}`,
        {},
        "GET"
      );
    },
    getTeacherTrainList(params) {
      //当前老师实训列表
      return Ajax.ajaxs(`/statistics/trainList`, params);
    },
    getTrainAssessmentList(params) {
      // 实训考核列表
      return Ajax.ajaxs(
        `/examination/manager/getTrainExaminationList/${params}`,
        {},
        "GET"
      );
    },
    getTrainAssessmentChartInfo(params) {
      // 实训考核图表信息
      return Ajax.ajaxs(
        `/examination/manager/getExaminationAnalysisChart`,
        params
      );
    },
    getTrainExaminationStudentsList(params) {
      // 实训考核 学生列表
      return Ajax.ajaxs(
        `/examination/manager/trainExaminationStudentsList`,
        params
      );
    },
    getStudentsList(params) {
      //实训作业 获取班级学生列表
      return Ajax.ajaxs(`/work/manager/getStudentsWorkList`, params);
    },
    getWorkAnalysisChart(params) {
      //实训作业图表
      return Ajax.ajaxs(`/work/manager/getWorkAnalysisChart`, params);
    },
    getTrainChapter({ trainId, studentUserId }) {
      //学生实训进度详情
      return Ajax.ajaxs(
        `/trainChapter/train/${trainId}/${studentUserId}`,
        {},
        "GET"
      );
    },
    getTrainOverallProgress(params) {
      //学生实训总进度进度
      return Ajax.ajaxs(`/statistics/studentScheduleList`, params);
    },
    videoLearnDetail(params) {
      //学生视频观看学习详情
      return Ajax.ajaxs(`/video/seeVideoList`, params);
    },
    getSignKey({ trainChapterId, trainClassId, trainId }) {
      //二维码签到
      return Ajax.ajaxs(
        `/qr/getQrTrainKey`,
        { trainChapterId, trainClassId, trainId },
        "GET"
      );
    },
    getBatchSignKey({ trainChapterIds, trainClassId, trainId }) {
      //二维码批量签到
      return Ajax.ajaxs(
        `/qr/getBatchQrTrainKey`,
        { trainChapterIds, trainClassId, trainId },
        "GET"
      );
    },
    updateTrainClassVideoTime(params) {
      //更新视频上传时间
      return Ajax.ajaxs(`/train/updateTrainClassVideo`, params);
    },
  },
  class: {
    getClassList(props) {
      //获取班级列表
      return Ajax.ajaxs(`/class/getSchoolClassList`, props);
    },
    deleteSchoolClass(props) {
      //删除班级
      return Ajax.ajaxs(
        `/class/deleteSchoolClass?classId=${props.classId}`,
        {},
        "DELETE"
      );
    },
    getClassDetails(classId = "") {
      //获取班级信息
      return Ajax.ajaxs(`/class/getSchoolClassInfo?classId=${classId}`, {});
    },
    getClassStudentList(props) {
      //获取单个班级学生列表
      return Ajax.ajaxs(`/class/getSchoolClassAllStudentInfoList`, props);
    },
    getSchoolClassOption(props) {
      //获取当前学校所有班级
      return Ajax.ajaxs(`/school/getSchoolClassOption`, props, "GET");
    },
    addClass(props) {
      //添加班级
      return Ajax.ajaxs(`/class/setSchoolClass`, props);
    },
    getTeacherPost(props) {
      //添加班级
      return Ajax.ajaxs(`/teacher/getTeacherPost`, props, "GET");
    },
    getTeacherAddClassInfo(classId = "") {
      //添加班级
      return Ajax.ajaxs(
        `/teacher/getTeacherAddClassInfo?classId=${classId}`,
        {}
      );
    },
    upSchoolClassInfo(props) {
      //修改班级
      return Ajax.ajaxs(`/class/upSchoolClassInfo`, props);
    },
    getTeacherListInfo(props) {
      //修改班级
      return Ajax.ajaxs(`/teacher/getTeacherListInfo`, props);
    },
    upForbidStatus(props) {
      // 教师列表账号管理
      return Ajax.ajaxs(`/user/upForbidStatus`, props);
    },
    studentUpForbidStatus(props) {
      //学生列表账号管理
      return Ajax.ajaxs(`/student/upForbidStatus`, props);
    },
    permissionList(props) {
      // 获取老师权限列表
      return Ajax.ajaxs(`/permission/permissionList`);
    },
    getSchoolAllClassInfo(props) {
      // 获取学校列表-院-专业-班级
      return Ajax.ajaxs(`/class/getSchoolAllClassInfo`, props);
    },
    getSchoolClassList(props) {
      // 获取学校列表-院-专业-班级
      return Ajax.ajaxs(`/class/getClassList`, props, "GET");
    },
    addTeacherInfo(props) {
      // 新增教师
      return Ajax.ajaxs(`/teacher/addTeacherInfo`, props);
    },
    upTeacherInfo(props) {
      // 修改教师
      return Ajax.ajaxs(`/teacher/upTeacherInfo`, props);
    },
    checkPhoneRepeat(props) {
      // 校验教师手机号是否被注册
      return Ajax.ajaxs(`/teacher/checkPhoneRepeat`, props, "GET");
    },
    getTeacherInfo(userId = "", teacherId = "") {
      // 获取老师信息
      return Ajax.ajaxs(
        `/teacher/getOneTeacherRoleJurisdictionInfo?userId=${userId}&teacherId=${teacherId}`
      );
    },
    test() {
      // 测试
      return Ajax.ajaxs(`/test/test?pwd=123456`, {}, "GET");
    },
  },
  courseManage: {
    getCourseKindList() {
      //获取课程分类
      return Ajax.ajaxs(`/course/getCourseKindList`, {}, "GET");
    },
    getCourseList(params) {
      //获取课程列表
      let url = `/course/getCourseList?`;
      for (let key in params) {
        url = `${url}${key}=${params[key]}&`;
      }
      return Ajax.ajaxs(url, {}, "GET");
    },
  },
  practice: {
    scoreCount(params) {
      //统计指定班级-模拟实操的指定模式流程分数段人数
      return Ajax.ajaxs(`/assess/scoreCount/${params}`, {}, "GET");
    },
    processScoreAndStudentInfo(params) {
      //统计指定班级-模拟实操的指定模式流程分数段人数
      return Ajax.ajaxs(`/assess/assessAllProcessScoreAndStudentInfo`, params);
    },
    exportAssessAllProcessScoreAndStudentInfo(params) {
      //导出-学生信息 及 指定模拟实操模式ALL流程分数
      return Ajax.ajaxs(
        `/assess/exportAssessAllProcessScoreAndStudentInfo/${params}`,
        {},
        "GET"
      );
    },
    assessProcessStudentScoreDetails(params) {
      // 获取模拟实操的指定流程分数详细
      return Ajax.ajaxs(`/assess/assessProcessStudentScoreDetails`, params);
    },
    queryPracticeCategory(params) {
      // 获取实操品类及流程
      return Ajax.ajaxs(`/practice/queryPracticeCategory`, params, "GET");
    },
  },
  cloudChart: {
    getClassroomNews(params) {
      // 获取课堂动态数据
      return Ajax.ajaxs("/cloudChart/getClassroomNews", params, "GET");
    },
    getDataScreening(params) {
      // 获取数据总览
      return Ajax.ajaxs("/cloudChart/getDataScreening", params, "GET");
    },
    getRealTimeDynamicInfo() {
      // 获取实时动态数据
      return Ajax.ajaxs("/cloudChart/getRealTimeDynamicInfo", {}, "GET");
    },
    getClassActivity() {
      // 班级活跃情况
      return Ajax.ajaxs("/cloudChart/getClassActivity", {}, "GET");
    },
    getPracticeOption() {
      // 实操平台选项
      return Ajax.ajaxs("/cloudChart/getPracticeOption", {}, "GET");
    },
    getPracticeAnalysis(params) {
      // 实操分析
      return Ajax.ajaxs("/cloudChart/getPracticeAnalysis", params, "GET");
    },
  },
  file: {
    upload(params) {
      // 单个上传文件
      return Ajax.uploadFile("/file/upload", params);
    },
    uploads(params, name = "files") {
      // 上传多个文件
      return Ajax.uploadFile("/student/add/batch", params, name);
    },
    download(url) {
      // 下载文件
      return Ajax.ajaxs("/file/download", { fileUrl: url });
    }, // 修改文件名并下载文件
    downloadAndChangeFileName(url, filename) {
      Ajax.changeFileName(url, filename);
    },
    createUploadVideo(params) {
      return Ajax.ajaxs(`/video/createUploadVideo`, params);
    },
    uploadEmploymentInfo(params) {
      return Ajax.uploadFile(`/employment/batchImport`, params, "file");
    },
    serverDownload({ fileUrl, fileName }) {
      return `${Ajax.baseURL}/file/server/download?fileName=${fileName}&fileUrl=${fileUrl}`;
    },
  },
  export: {
    exportWork(params) {
      //导出作业
      return `${Ajax.baseURL}work/manager/exportCorrectWorkList${connect(
        params
      )}`;
    },
    exportStudentList(params) {
      //导出学生列表
      return `${Ajax.baseURL}/student/exportStudentList${connect(params)}`;

      // return Ajax.ajaxs("/student/exportStudentList", params,"GET");
    },
    exportExam(params) {
      //导出考核
      return `${Ajax.baseURL
        }examination/manager/exportCorrectExaminationList${connect(params)}`;
    },
    exportTrainData(params) {
      //导出学生实训数据
      return `${Ajax.baseURL}statistics/exportStudentTrainData${connect(
        params
      )}`;
    },
    exportStudentPracticeData(params) {
      //导出学生实操数据
      return `${Ajax.baseURL}statistics/exportStudentPracticeData${connect(
        params
      )}`;
    },
  },
  blueWahle: {
    getAllCityData(params) {
      //所有省份启动数据
      return Ajax.ajaxs("/blueWhale/getAllCityData", params, "GET");
    },
    getStoreOrderProvinceRatioList(params) {
      //所有省份启动数据
      return Ajax.ajaxs("/entrepreneurship/getStoreOrderProvinceRatioList", params, "GET");
    },
    getEnterpriseData(params) {
      // 企业入驻数据
      return Ajax.ajaxs("/blueWhale/getEnterpriseData", params, "GET");
    },
    getSchoolDealDate(params) {
      //孵化基地交易数据
      return Ajax.ajaxs("/blueWhale/getSchoolDealDate", params, "GET");
    },
    getSchoolRunCheck(params) {
      //学校运行状态监控
      return Ajax.ajaxs("/blueWhale/getSchoolRunCheck", params, "GET");
    },
    getSkuCategoryDate(params) {
      //sku品类数据
      return Ajax.ajaxs("/blueWhale/getSkuCategoryDate", params, "GET");
    },
    getStoreSalesData(params) {
      //店铺销售数据
      return Ajax.ajaxs("/blueWhale/getStoreSalesData", params, "GET");
    },
    getStudentJobData(params) {
      //学生就业数据
      return Ajax.ajaxs("/blueWhale/getStudentJobData", params, "GET");
    },
    getSchoolName(params) {
      //店铺销售选择框
      return Ajax.ajaxs("/blueWhale/getSchoolName", params, "GET");
    },
  },
  entrepreneurship: {
    getCategorySaleList(params) {
      //获取品类销售数据
      return Ajax.ajaxs("/entrepreneurship/getCategorySaleList", params, "GET");
    },
    getCategorySaleSector(params) {
      //获取品类销售榜
      return Ajax.ajaxs(
        "/entrepreneurship/getCategorySaleSector",
        params,
        "GET"
      );
    },
    getSchoolTotal(params) {
      //获取学校总金额和sku总数
      return Ajax.ajaxs("/entrepreneurship/getSchoolTotal", params, "GET");
    },
    getStoreIssue(params) {
      //获取店铺出单榜数据
      return Ajax.ajaxs("/entrepreneurship/getStoreIssue", params, "GET");
    },
    getStoreOption(params) {
      //获取店铺选项
      return Ajax.ajaxs("/entrepreneurship/getStoreOption", params, "GET");
    },
    getStoreOrderStatusNum(params) {
      //获取店铺订单状态分布数
      return Ajax.ajaxs(
        "/entrepreneurship/getStoreOrderStatusNum",
        params,
        "GET"
      );
    },
    getStoreSale(params) {
      //店铺销售总榜
      return Ajax.ajaxs("/entrepreneurship/getStoreSale", params, "GET");
    },
    getStoreSalesData(params) {
      //获取店铺销售数据
      return Ajax.ajaxs("/entrepreneurship/getStoreSalesData", params, "GET");
    },
    queryStoreRecord(params) {
      //查询店铺记录
      return Ajax.ajaxs("/entrepreneurship/queryStoreRecord", params, "GET");
    },
    getStoreOrderIssueList(params) {
      //查询店铺记录
      return Ajax.ajaxs("/entrepreneurship/getStoreOrderIssueList", params, "GET");
    },
    getPlatformOption(params) {
      //查询导航tab
      return Ajax.ajaxs("/entrepreneurship/getPlatformOption", params, "GET");
    },
  },
  competition: {
    getCategorySaleSector(params) {
      //获取品类销售榜
      return Ajax.ajaxs("/competition/getCategorySaleList", params, "GET");
    },
    getCompetitionSchoolList(params) {
      //获取竞赛学校列表
      return Ajax.ajaxs("/competition/getCompetitionSchoolList", params, "GET");
    },
    getCompetitionTimeSalesData(params) {
      //获取竞赛时间销售数据
      return Ajax.ajaxs("/competition/getCompetitionTimeSalesData", params, "GET");
    },
    getTeamCompetitionTimeSalesData(params) {
      //获取学校团队竞赛时间销售数据
      return Ajax.ajaxs("/competition/getTeamCompetitionTimeSalesData", params, "GET");
    },
    getCompetitionTotal(params) {
      //获取竞赛金额和sku总数
      return Ajax.ajaxs("/competition/getCompetitionTotal", params, "GET");
    },
    getCompetitionTeamTotal(params) {
      //获取竞赛团队金额和sku总数
      return Ajax.ajaxs("/competition/getCompetitionTeamTotal", params, "GET");
    },
    getCompetitonOptionList(params) {
      //获取竞赛选项列表
      return Ajax.ajaxs("/competition/getCompetitonOptionList", params, "GET");
    },
    getSchoolIssue(params) {
      //获取学校出单榜数据
      return Ajax.ajaxs("/competition/getSchoolIssue", params, "GET");
    },
    getSchoolSaleList(params) {
      //获取学校销售数据
      return Ajax.ajaxs("/competition/getSchoolSaleList", params, "GET");
    },
    getSchoolSaleSector(params) {
      //获取学校销售榜
      return Ajax.ajaxs("/competition/getSchoolSaleSector", params, "GET");
    },
    queryStoreRecord(params) {
      //查询店铺记录
      return Ajax.ajaxs("/competition/queryStoreRecord", params, "GET");
    },
    getTeamSaleRankList(params) {
      //获取销售排行榜
      return Ajax.ajaxs("/competition/getTeamSaleRankList", params, "GET");
    },
    getTeamSalesSoarList(params) {
      //获取销售飙升榜
      return Ajax.ajaxs("/competition/getTeamSalesSoarList", params, "GET");
    },
    getTeamIssumList(params) {
      //出单排行榜接口
      return Ajax.ajaxs("/competition/getTeamIssumList", params, "GET");
    },
    getCompetitionInfo(params) {
      //获取赛事详情
      return Ajax.ajaxs("/competition/getCompetitionInfo", params, "GET");
    },
  },
  cloudBi: {
    getCategorySaleList(params) {
      //获取品类销售数据
      return Ajax.ajaxs("/dashboard/getCategorySaleList", params, "GET");
    },
    getCountryList(params) {
      //获取国家列表
      return Ajax.ajaxs("/dashboard/getCountryList", params, "GET");
    },
    getCourseStudyTrends(params) {
      //获取课程学习动态
      return Ajax.ajaxs("/dashboard/getCourseStudyTrends", params, "GET");
    },
    getPlatformList(params) {
      //获取平台列表
      return Ajax.ajaxs("/dashboard/getPlatformList", params, "GET");
    },
    getPracticeUseTrends(params) {
      //获取实操使用动态
      return Ajax.ajaxs("/dashboard/getPracticeUseTrends", params, "GET");
    },
    getProvinceUseStatisticsList(params) {
      //获取省份使用统计列表
      return Ajax.ajaxs("/dashboard/getProvinceUseStatisticsList", params, "GET");
    },
    getSchoolCheckList(params) {
      //获取学校检测列表
      return Ajax.ajaxs("/dashboard/getSchoolCheckList", params, "GET");
    },
    getStorelssue(params) {
      //获取店铺出单榜数据
      return Ajax.ajaxs("/dashboard/getStoreIssue", params, "GET");
    },
    getStoreSale(params) {
      //店铺销售总榜
      return Ajax.ajaxs("/dashboard/getStoreSale", params, "GET");
    },
    getStoreSalesList(params) {
      //获取日销售数据
      return Ajax.ajaxs("/dashboard/getStoreSalesList", params, "GET");
    },
  }
};
