import React from "react";
import ScreenTitle from '../ScreenTitle'
import EChartsData from './eCharts'
import './index.css'

class CareerGrowth extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <div className="CareerGrowth ljScreen-item">
                <ScreenTitle title="职业成长" />
                <div className="salary">
                    <p>
                        <span>就业总数：</span>
                        <span>50000+</span>
                    </p>
                    <p>
                        <span>平均薪资：</span>
                        <span>9.5K/月</span>
                    </p>
                </div>
                <EChartsData />
            </div>
        )
    }
}

export default CareerGrowth