import React from "react";
import ShipBox from "../ShipBox";
import EChart from "../EChart";
import { entrepreneurshipRadius, isMobile } from "@/utils/utils";

class ShopSKU extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      option: null,
      isMobile: isMobile(),
    };
  }
  shouldComponentUpdate(next, newState) {
    if (next.data && !newState.option) {
      this.init();
      return false;
    }
    return true;
  }
  init = () => {
    let { data } = this.props;
    if (!data) return;
    let isheights = Number(document.body.clientHeight) >= 1024 ? true : false;
    let w1366 = Number(document.body.clientWidth) > 1366;
    let w1600 = Number(document.body.clientWidth) > 1600;
    let h700 = Number(document.body.clientHeight) > 700;
    let h800 = Number(document.body.clientHeight) > 800;
    let h900 = Number(document.body.clientHeight) > 900;
    let list = data.ratioList.map((item) => {
      return {
        name: item.categoryName,
        value: item.ratio,
      };
    });
    let option = {
      tooltip: {
        trigger: "item",
        formatter: "{b0}: {c0}%",
      },
      legend: {
        top: "33%",
        right: h800 && w1600 ? "5%" : "0",
        // height: h900 ? "45%" : h700 ? "60%" : "80%",
        orient: "vertical",
        selectedMode: true,
        selected: {},
        itemWidth: 8,
        itemHeight: 8,
        textStyle: {
          color: "#f2f2f2",
          fontSize: entrepreneurshipRadius(),
        },
        icon: "circle",
      },
      series: [
        {
          name: "",
          type: "pie",
          avoidLabelOverlap: false,
          center: [
            h900 ? (h700 ? "20%" : "20%") : "20%",
            h800 ? "55%" : h700 ? "65%" : "70%",
          ],
          label: {
            show: false,
            position: "center",
          },
          radius: [
            30,
            isheights && w1600
              ? 60
              : h700
              ? w1366
                ? 50
                : Number(document.body.clientWidth) < 400
                ? 40
                : 20
              : 20,
          ],
          data: list,
          color: [
            "#4276F1",
            "#31C764",
            "#0DD2D2",
            "#E69815",
            "#A41BF9",
            "#0AABD9",
            "#E44C93",
            "#E1593D",
            "#6A8392",
            "#AAE6E6",
          ],
        },
      ],
    };
    if (this.state.isMobile) {
      option.series[0].center = ["30%", "62%"];
      option.series[0].radius = [30, 60];
    }
    this.setState({
      option,
    });
  };

  render() {
    const { option } = this.state;
    return (
      <ShipBox title="SKU品类销售榜" className="ShopSKU Entrepreneurship_box">
        <div className="ShopAll_echart">
          <EChart
            option={option}
            styleS={{ width: "100%", height: "94%" }}
            m_id="ShopSKU"
          />
        </div>
      </ShipBox>
    );
  }
}

export default ShopSKU;
