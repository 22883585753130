import React from "react";
import CompetitionBox from "../CompetitionBox";
import { bigNumberTransform, isMobile } from "@/utils/utils";
import EChart from "../EChart";

class Category extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      option: null,
      isMobile: isMobile(),
    };
  }
  shouldComponentUpdate(next, newState) {
    if (!newState.option && next.data.length) {
      this.init();
      return false;
    }
    return true;
  }
  init = () => {
    let heights = document.body.clientHeight > 700,
      widths = document.body.clientWidth > 1366;
    let { data } = this.props;
    console.log(data);
    if (!data.length) return;
    let list = data;
    let xAxis = list.map((item) => item.categoryName);
    let datas = list.map((item) => item.salesVolume);
    let w1366 = Number(document.body.clientWidth) > 1366;
    let option = {
      tooltip: {
        trigger: "item",
        borderWidth: 0,
        formatter: (params, index) => {
          return `${params.name}：${bigNumberTransform(params.value)}`;
        },
      },
      yAxis: {
        type: "value",
        axisLine: {
          show: false,
          lineStyle: {
            color: "#E6E6E6",
            width: 0.3,
            fontSize: "0.75rem",
          },
        },
        splitLine: {
          lineStyle: {
            width: 0.3,
            color: "#fff",
          },
        },
        axisTick: {
          show: false,
        },
        nameTextStyle: {
          // 名称样式
          fontSize: 12,
          color: "#E6E6E6",
          padding: [28, 0, 0, 0],
        },
        axisLabel: {
          textStyle: {
            color: "#E6E6E6",
            fontSize: w1366 ? 12 : document.body.clientWidth > 768 ? 10 : 8,
          },
          formatter: (params, index) => {
            return bigNumberTransform(params);
          },
        },
      },
      xAxis: {
        type: "category",
        data: xAxis,
        axisLine: {
          lineStyle: {
            color: "#E6E6E6",
            width: 0.3,
            fontSize: "0.75rem",
          },
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          rotate: document.body.clientWidth > 768 ? 0 : 30,
          textStyle: {
            color: "#E6E6E6",
            fontSize: w1366 ? 12 : document.body.clientWidth > 768 ? 10 : 8,
          },
        },
      },
      grid: {
        left: 10,
        right: 15,
        top: 60,
        bottom: 0,
        containLabel: true,
      },
      series: [
        {
          barWidth: widths ? "16px" : "8px",
          data: datas,
          type: "bar",
          color: "#EB2359",
        },
      ],
    };
    if (this.state.isMobile) {
      option.series[0].barWidth = "16px";
      option.xAxis.axisLabel.textStyle.fontSize = 8;
      option.yAxis.axisLabel.textStyle.fontSize = 10;
    }
    this.setState({
      option,
    });
  };
  render() {
    const { option } = this.state;
    return (
      <CompetitionBox className="category" title="销售品类">
        <div className="Competition_echart">
          <EChart
            option={option}
            styleS={{ width: "100%", height: "94%" }}
            m_id="category"
          />
        </div>
      </CompetitionBox>
    );
  }
}

export default Category;
