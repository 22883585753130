import React from "react";
import { Select } from "antd";

function CompetitionBox({ title, className, selectId, children }) {

  return (
    <div className={["CompetitionBox", className].join(" ")} id={selectId}>
      {title ?
        <h4>
          <span>{title}</span>
        </h4> : null}
      {children}
    </div>
  )

}

export default CompetitionBox;
