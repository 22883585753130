import React from "react";
import CompetitionBox from "../CompetitionBox";
import Slider from "react-slick";
import { Tooltip } from "antd";

class SalesLog extends React.Component {
  render() {
    let { data } = this.props;
    let w1366 = Number(document.body.clientWidth) > 1366;
    const settings = {
      dots: false,
      infinite: true, //循环
      arrows: false,
      slidesToShow: 3,
      slidesToScroll: 1,
      vertical: true,
      verticalSwiping: false,
      autoplay: true,
      speed: 2000,
      autoplaySpeed: 1000,
    };
    return (
      <CompetitionBox className="salesLog">
        <Slider {...settings}>
          {data &&
            data.map((item, index) => {
              return (
                <div key={index} className="College-table-th">
                  <div className="College-table-td" style={{ width: "35%" }}>
                    {item.salesTime}
                  </div>
                  <div
                    className="College-table-td"
                    style={{ width: "25%", paddingRight: 3,textAlign: "center", }}
                  >
                    <Tooltip
                      overlayStyle={{ width: "auto" }}
                      title={item.storeName}
                    >
                      {item.storeName}
                    </Tooltip>
                  </div>
                  <div
                    className="College-table-td"
                    style={{
                      width: "45%",
                      textAlign: "right",
                      marginLeft: w1366 ? "5%" : "0px",
                    }}
                  >
                    {item.content}
                  </div>
                </div>
              );
            })}
        </Slider>
      </CompetitionBox>
    );
  }
}

export default SalesLog;
