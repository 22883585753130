import React from "react";
import { Select } from "antd";

class ShipBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      title,
      isSelect,
      className,
      value,
      shiponChange,
      options,
      subtitle,
      selectId,
    } = this.props;

    return (
      <div className={["ShipBox", className].join(" ")} id={selectId}>
        <div className="ShipTitle">
          <h4>{title}</h4>
          {isSelect ? (
            <Select
              dropdownClassName="ShipTitle-popup"
              className="ShipTitle-sel"
              getPopupContainer={() => document.getElementById(selectId)}
              value={value}
              onChange={shiponChange}
              options={options}
              maxTagCount={6}
              maxTagTextLength={6}
              listHeight={180}
            />
          ) : null}
          {subtitle ? <div className="ShipBox_subtitle">{subtitle}</div> : null}
        </div>
        {this.props.children}
        <div>
          <div className="ShipBox_tr"></div>
          <div className="ShipBox_tl"></div>
          <div className="ShipBox_br"></div>
          <div className="ShipBox_bl"></div>
        </div>
      </div>
    );
  }
}

export default ShipBox;
