import React from "react";
import "./index.less";
import AJAX from "@/api/api";
import SalesVolume from "./modules/SalesVolume";
import DaySalesData from "./modules/DaySalesData";
import CompetitionInfo from "./modules/info";
import Top10 from './modules/top10'
import SchooleList from './modules/schooleList'
import SchoolSwiper from "./modules/SchoolSwiper";
import { isMobile, refreshInterval } from "@/utils/utils";
import getUrlData from "@/utils/getUrlData";

class Competition extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile: isMobile(),
            competitionId: getUrlData("id"),
            titleName: getUrlData("name"),
            optionList: [],
            teamSaleRankList: [],
            schoolList: [],
            timeSalesData: null,
            salesSoarList: [],
            issumList: [],
            schoolSaleList: [],
            schoolSaleSector: [],
            storeRecord: null,
            competitionTotal: null,
            colors: [
                "#F90D4D",
                "#E02AE2",
                "#FE4721",
                "#4F31EB",
                "#0090F1",
                "#DCA23F",
                "#E86448",
                "#44B5D6",
                "#0A8D9D",
                "#3822FC",
                "#3BB176",
                "#B2BC1F",
                "#EB2359",
            ],
            time: new Date().toTimeString().slice(0, 8),
            info: null,
        };
        this.timer = null;
    }
    componentDidMount() {
        let query = {}
        if (getUrlData("id")) {
            query = { competitionId: getUrlData("id") }
        } else if (getUrlData("cp_mask")) {
            query = { cp_mask: getUrlData("cp_mask") }
        }
        AJAX.competition.getCompetitionInfo(query).then(res => {
            if (res.code == 1) {
                this.setState({
                    competitionId: res.data.competitionId,
                    titleName: res.data.competitionName,
                    info: res.data
                })
                this.getAllData(res.data.competitionId);
            }
        })
        // this.getAllData(getUrlData("id"));
        this.tick();
        refreshInterval(30)
    }
    getAllData(id) {
        this.getCompetitionSchoolList(id)
        this.getCompetitionTimeSalesData(id)
        this.getTeamSaleRankList(id)
        this.getTeamSalesSoarList(id)
        this.getTeamIssumList(id)
        this.getCompetitionTotal(id)
    }
    getCompetitionSchoolList(id) {
        AJAX.competition
            .getCompetitionSchoolList({ competitionId: id })
            .then((res) => {
                if (res.code == 1) {
                    this.setState({
                        schoolList: res.data,
                    });
                }
            });
    }
    getCompetitionTimeSalesData(id) {
        AJAX.competition
            .getTeamCompetitionTimeSalesData({ competitionId: id })
            .then((res) => {
                if (res.code == 1) {
                    this.setState({
                        timeSalesData: res.data,
                    });
                }
            });
    }
    getTeamSaleRankList(id) {
        AJAX.competition
            .getTeamSaleRankList({ competitionId: id })
            .then((res) => {
                if (res.code == 1) {
                    this.setState({
                        teamSaleRankList: res.data,
                        schoolSaleList: res.extend || []
                    });
                }
            });
    }
    getTeamSalesSoarList = (id, date = '') => {
        AJAX.competition
            .getTeamSalesSoarList({ competitionId: id, compareDate: date })
            .then((res) => {
                if (res.code == 1) {
                    this.setState({
                        salesSoarList: res.data,
                    });
                }
            });
    }
    getTeamIssumList = (id, date = '') => {
        AJAX.competition
            .getTeamIssumList({ competitionId: id })
            .then((res) => {
                if (res.code == 1) {
                    this.setState({
                        issumList: res.data,
                    });
                }
            });
    }
    getCompetitionTotal(id) {
        AJAX.competition.getCompetitionTeamTotal({ competitionId: id }).then((res) => {
            if (res.code == 1) {
                this.setState({
                    competitionTotal: res.data,
                });
            }
        });
    }
    componentWillUnmount() {
        // 第四步：组件将要被卸载时注意定时器必须清除
        clearTimeout(this.timer);
    }

    tick = () => {
        // 第二步：设置时间间隔，更新时间
        this.timer = setInterval(() => {
            // 但是这样设计后，页面上的时间并不会实时修改，只会在每次刷新后修改
            let times = new Date().toLocaleString('chinese', { hour12: false })
            this.setState({
                // 所以我们要借助生命周期函数：
                time: `${times.split(" ")[0].replace(/\//g, '-')} ${times.split(" ")[1]}`,
            });
        }, 1000);
    };
    render() {
        let {
            titleName,
            schoolList,
            timeSalesData,
            schoolSaleList,
            teamSaleRankList,
            salesSoarList,
            issumList,
            colors,
            time,
            competitionTotal,
            competitionId,
            info,
        } = this.state;
        return (
            <div className="e_commerce">
                <div className="e_commerce_top">
                    <h1>
                        <img className="logo" src={info && info.competitionLogo}></img>
                        <span>{titleName}</span>
                        <span className="times">{time}</span>
                    </h1>
                    <SchoolSwiper data={schoolList}></SchoolSwiper>
                </div>
                <div className="e_commerce_body">
                    <div className="e_left_27">
                        <SchooleList data={teamSaleRankList} info={info} />
                    </div>
                    <div className="e_commerce_body_center">
                        <div className="e_top_60">
                            <div className="e_left_40">
                                <Top10 teamSaleRankList={teamSaleRankList} issumList={issumList} competitionTotal={competitionTotal} info={info} />
                            </div>
                            <div className="e_right_27">
                                <CompetitionInfo data={timeSalesData} info={info} />
                                <SalesVolume info={info} data={salesSoarList} competitionId={competitionId} getTeamSalesSoarList={this.getTeamSalesSoarList} />
                            </div>
                        </div>
                        <div className="e_bottom_40">
                            <DaySalesData data={schoolSaleList} colors={colors} info={info} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Competition;
