import React from "react";
import CompetitionBox from "../CompetitionBox";
import { Tooltip, Tag, Space } from 'antd';
import Slider from "react-slick";

class College extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    columns = () => {
        return [
            {
                title: '时间',
                dataIndex: 'checkDate',
                with: "35%"
            },
            {
                title: '学校/基地',
                dataIndex: 'schoolName',
                with: "53%"
            },
            {
                title: '状态',
                dataIndex: 'status',
                with: "12%",
                render: (text) => {
                    return <span style={{ color: "#01F1F8" }}> 正常</span>
                }
            }
        ]
    }

    render() {
        const { type, data, studentExtend } = this.props
        const settings = {
            dots: false,
            infinite: true, //循环
            arrows: false,
            slidesToShow: 3,
            slidesToScroll: 1,
            vertical: true,
            verticalSwiping: false,
            autoplay: true,
            speed: 2000,
            autoplaySpeed: 1000,
            beforeChange: (currentSlide, nextSlide) => {
                // console.log("before change", currentSlide, nextSlide);
            },
            afterChange: function (currentSlide) {
                // console.log("after change", currentSlide);
            }
        }
        // console.log(this.data)
        return (
            <CompetitionBox title="学校运行状态监测" className="College">
                {
                    type ? <p className='num'>平均就业率：<span>{studentExtend || 0}%</span></p> : null
                }
                <div className="College-table">
                    <div className="College-table-th" style={{ marginBottom: 8 }}>
                        {
                            this.columns().map((item, index) => {
                                return <div className="th"
                                    key={item.title}
                                    style={{ width: item.with, textAlign: index >= this.columns().length - 1 ? "right" : 'left' }}
                                >{item.title}</div>
                            })
                        }
                    </div>
                    <Slider {...settings}>
                        {
                            data && data.map((item, index) => {
                                return <div key={item.schoolName} className="College-table-th">
                                    <div className="College-table-td" style={{ width: "35%" }}>{item.checkTime}</div>
                                    <div className="College-table-td" style={{ width: "53%", paddingRight: 3 }}>
                                        <Tooltip title={item.schoolName} >{item.schoolName}</Tooltip>
                                    </div>
                                    <div className="College-table-td" style={{ width: "12%", color: "#01F1F8", textAlign: "right" }}>
                                        {item.checkStatus ? '正常' : '异常'}
                                    </div>
                                </div>
                            })
                        }
                    </Slider>
                </div>
            </CompetitionBox>
        )
    }
}

export default College